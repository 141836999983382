import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: string;
  DateTime: string;
  Date: string;
};

export type AccountCountryType = {
   __typename?: 'AccountCountryType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  isoCode?: Maybe<AccountsAccountCountryIsoCodeChoices>;
  accountSet: Array<AccountType>;
  accountregionSet: Array<AccountRegionType>;
  productSet: Array<ProductType>;
  quotedproductSet: Array<QuotedProductType>;
};

export type AccountListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['ID']>>>;
  region?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AccountListType = {
   __typename?: 'AccountListType';
  items: Array<AccountType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type AccountRanking = {
   __typename?: 'AccountRanking';
  account?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type AccountRegionType = {
   __typename?: 'AccountRegionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  country?: Maybe<AccountCountryType>;
  accountSet: Array<AccountType>;
};

export enum AccountsAccountCountryIsoCodeChoices {
  Ar = 'AR',
  Br = 'BR',
  Bo = 'BO',
  Cl = 'CL',
  Co = 'CO',
  Pe = 'PE',
  Uy = 'UY',
  Py = 'PY',
  Cr = 'CR',
  Cu = 'CU',
  Do = 'DO',
  Ec = 'EC',
  Sv = 'SV',
  Gf = 'GF',
  Gp = 'GP',
  Gt = 'GT',
  Ht = 'HT',
  Mq = 'MQ',
  Mx = 'MX',
  Ni = 'NI',
  Pa = 'PA',
  Pr = 'PR',
  Ve = 'VE'
}

export type AccountType = {
   __typename?: 'AccountType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  taxId: Scalars['String'];
  type: AccountTypeType;
  country?: Maybe<AccountCountryType>;
  region?: Maybe<AccountRegionType>;
  externalId: Scalars['String'];
  owner?: Maybe<UserType>;
  contacts: Array<ContactType>;
  opportunities: Array<Maybe<OpportunityType>>;
  quotes: Array<Maybe<QuoteType>>;
};

export type AccountTypeType = {
   __typename?: 'AccountTypeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  accountSet: Array<AccountType>;
};

export type ActiveIngredientType = {
   __typename?: 'ActiveIngredientType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  formulations: Array<FormulationType>;
};

export type AdministrationProductFormInput = {
  name: Scalars['String'];
  number: Scalars['String'];
  unit: Scalars['ID'];
  quantity: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  quantityAdmin: Scalars['Float'];
};

export type AdministrationProductType = {
   __typename?: 'AdministrationProductType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  unit: UnitType;
  quantity: Scalars['Int'];
  number: Scalars['String'];
  quantityAdmin: Scalars['Float'];
  saleAdministration: SaleAdministrationType;
};

export type AmountDatum = {
   __typename?: 'AmountDatum';
  label: Scalars['String'];
  count: Scalars['Int'];
  amount: Scalars['Float'];
  percentage?: Maybe<Scalars['Float']>;
};

export type AnalyticsFilters = {
  dfrom: Scalars['String'];
  dto: Scalars['String'];
  region: Scalars['String'];
  user: Scalars['String'];
  account: Scalars['String'];
};

export type ContactListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['ID']>>>;
  account?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ContactListType = {
   __typename?: 'ContactListType';
  items: Array<ContactType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ContactType = {
   __typename?: 'ContactType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  mobilePhone: Scalars['String'];
  type: ContactTypeType;
  externalId: Scalars['String'];
  account: AccountType;
  intermediary: Scalars['Boolean'];
  opportunities: Array<OpportunityType>;
  quotes: Array<QuoteType>;
  intermediaryQuotes: Array<QuoteType>;
  displayName: Scalars['String'];
};

export type ContactTypeType = {
   __typename?: 'ContactTypeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  contactSet: Array<ContactType>;
};

export type CreateAccountMutationInput = {
  name: Scalars['String'];
  taxId: Scalars['String'];
  type: Scalars['ID'];
  country: Scalars['ID'];
  region: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAccountMutationPayload = {
   __typename?: 'CreateAccountMutationPayload';
  account?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactMutationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  type: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  account: Scalars['ID'];
  intermediary?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactMutationPayload = {
   __typename?: 'CreateContactMutationPayload';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCreditNoteReviewMutationInput = {
  creditNotes: Scalars['ID'];
  type: Scalars['String'];
  status: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCreditNoteReviewMutationPayload = {
   __typename?: 'CreateCreditNoteReviewMutationPayload';
  creditNoteReview?: Maybe<CreditNoteReviewType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCreditNotesMutationInput = {
  sale: Scalars['ID'];
  orderType: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  creditProducts: Array<CreditProductFormInput>;
};

export type CreateCreditNotesMutationPayload = {
   __typename?: 'CreateCreditNotesMutationPayload';
  creditNote?: Maybe<CreditNoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateNotificationMutationInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type CreateNotificationMutationPayload = {
   __typename?: 'CreateNotificationMutationPayload';
  notification?: Maybe<NotificationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateOpportunityMutationInput = {
  date: Scalars['Date'];
  channel: Scalars['ID'];
  reason: Scalars['ID'];
  situation: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  notesTracking?: Maybe<Scalars['String']>;
  productsLegacy?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
  promotions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  marketingActions?: Maybe<Scalars['String']>;
  contact: Scalars['ID'];
  read?: Maybe<Scalars['DateTime']>;
  readBy?: Maybe<Scalars['ID']>;
  marketSurveys?: Maybe<Array<MarketSurveyFormInput>>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOpportunityMutationPayload = {
   __typename?: 'CreateOpportunityMutationPayload';
  opportunity?: Maybe<OpportunityType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteCommentMutationInput = {
  message?: Maybe<Scalars['String']>;
  quote: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteCommentMutationPayload = {
   __typename?: 'CreateQuoteCommentMutationPayload';
  quoteComment?: Maybe<QuoteCommentType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteMutationInput = {
  contact: Scalars['ID'];
  intermediary?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['ID']>;
  successChance?: Maybe<Scalars['ID']>;
  products: Array<QuotedProductFormInput>;
  paymentMethods: Array<QuotedPaymentMethodFormInput>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteMutationPayload = {
   __typename?: 'CreateQuoteMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuotePdfMutationInput = {
  quote: Scalars['ID'];
  type: Scalars['String'];
  recipients?: Maybe<Array<QuotePdfRecipientFormInput>>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuotePdfMutationPayload = {
   __typename?: 'CreateQuotePDFMutationPayload';
  quotePDF?: Maybe<QuotePdfType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSaleAdministrationMutationInput = {
  sale: Scalars['ID'];
  type: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  productsAdmin: Array<AdministrationProductFormInput>;
  percentage: Scalars['Float'];
};

export type CreateSaleAdministrationMutationPayload = {
   __typename?: 'CreateSaleAdministrationMutationPayload';
  saleAdministration?: Maybe<SaleAdministrationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type CreateSaleMutationInput = {
  quote: Scalars['ID'];
  paymentMethod: Scalars['ID'];
  orderType?: Maybe<Scalars['ID']>;
  products: Array<SaleProductFormInput>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSaleMutationPayload = {
   __typename?: 'CreateSaleMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalePdfMutationInput = {
  sale: Scalars['ID'];
  recipients?: Maybe<Array<SalePdfRecipientFormInput>>;
  type: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalePdfMutationPayload = {
   __typename?: 'CreateSalePDFMutationPayload';
  salePDF?: Maybe<SalePdfType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalesTargetMutationInput = {
  seller?: Maybe<Scalars['ID']>;
  amount: Scalars['Float'];
  season: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalesTargetMutationPayload = {
   __typename?: 'CreateSalesTargetMutationPayload';
  salesTarget?: Maybe<SalesTargetType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserPreferencesMutationInput = {
  id?: Maybe<Scalars['UUID']>;
  user?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  frequency: Scalars['String'];
};

export type CreateUserPreferencesMutationPayload = {
   __typename?: 'CreateUserPreferencesMutationPayload';
  userPreferences?: Maybe<UserPreferencesType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export enum CreditNoteReviewStatusChoices {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type CreditNoteReviewType = {
   __typename?: 'CreditNoteReviewType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  creditNotes: CreditNoteType;
  type: CreditNoteReviewTypeChoices;
  status: CreditNoteReviewStatusChoices;
  notes: Scalars['String'];
  typeDisplay: Scalars['String'];
  statusDisplay: Scalars['String'];
};

export enum CreditNoteReviewTypeChoices {
  Commercial = 'COMMERCIAL',
  Dircommercial = 'DIRCOMMERCIAL',
  Committee = 'COMMITTEE'
}

export type CreditNoteType = {
   __typename?: 'CreditNoteType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  sale: SaleType;
  status: SalesSaleStatusChoices;
  notes: Scalars['String'];
  statusDisplay: Scalars['String'];
  creditProducts: Array<CreditProductType>;
  reviews: Array<CreditNoteReviewType>;
  orderType?: Maybe<QuoteOrderTypeType>;
};

export type CreditProductFormInput = {
  name: Scalars['String'];
  quantity: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
};

export type CreditProductType = {
   __typename?: 'CreditProductType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  quantity: Scalars['Int'];
};


export type DateSeriesDatum = {
   __typename?: 'DateSeriesDatum';
  date: Scalars['Date'];
  count: Scalars['Int'];
  amount?: Maybe<Scalars['Float']>;
};


export type Datum = {
   __typename?: 'Datum';
  label: Scalars['String'];
  count: Scalars['Int'];
  percentage: Scalars['Float'];
};

export type DeleteAccountMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAccountMutationPayload = {
   __typename?: 'DeleteAccountMutationPayload';
  account?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteContactMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteContactMutationPayload = {
   __typename?: 'DeleteContactMutationPayload';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteCreditNoteMutationInput = {
  id?: Maybe<Scalars['ID']>;
};

export type DeleteCreditNoteMutationPayload = {
   __typename?: 'DeleteCreditNoteMutationPayload';
  creditNote?: Maybe<CreditNoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type DeleteOpportunityMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteOpportunityMutationPayload = {
   __typename?: 'DeleteOpportunityMutationPayload';
  opportunity?: Maybe<OpportunityType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteCommentMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteCommentMutationPayload = {
   __typename?: 'DeleteQuoteCommentMutationPayload';
  quoteComment?: Maybe<QuoteCommentType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteMutationPayload = {
   __typename?: 'DeleteQuoteMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSaleAdministrationMutationInput = {
  id?: Maybe<Scalars['ID']>;
};

export type DeleteSaleAdministrationMutationPayload = {
   __typename?: 'DeleteSaleAdministrationMutationPayload';
  saleAdministration?: Maybe<SaleAdministrationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type DeleteSaleMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSaleMutationPayload = {
   __typename?: 'DeleteSaleMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSalesTargetMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSalesTargetMutationPayload = {
   __typename?: 'DeleteSalesTargetMutationPayload';
  salesTarget?: Maybe<SalesTargetType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ErrorType = {
   __typename?: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type FormulationType = {
   __typename?: 'FormulationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  activeIngredient: ActiveIngredientType;
  marketsurveySet: Array<MarketSurveyType>;
};

export type LaboratoryType = {
   __typename?: 'LaboratoryType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  marketsurveySet: Array<MarketSurveyType>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LoginPayload = {
   __typename?: 'LoginPayload';
  email: Scalars['String'];
  password: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  user?: Maybe<UserType>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Logout = {
   __typename?: 'Logout';
  success?: Maybe<Scalars['Boolean']>;
};

export type MarketSurveyAveragesDatum = {
   __typename?: 'MarketSurveyAveragesDatum';
  activeIngredient: ActiveIngredientType;
  averagePrice: Scalars['Float'];
  averagePaymentDeadline: Scalars['Int'];
  count: Scalars['Int'];
};

export type MarketSurveyFormInput = {
  formulation: Scalars['ID'];
  laboratory: Scalars['ID'];
  price: Scalars['Float'];
  paymentMethod: Scalars['String'];
  paymentDeadline: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
};

export type MarketSurveyType = {
   __typename?: 'MarketSurveyType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  opportunity: OpportunityType;
  formulation: FormulationType;
  laboratory: LaboratoryType;
  price: Scalars['Float'];
  paymentMethod: Scalars['String'];
  paymentDeadline: Scalars['Int'];
};

export type Mutation = {
   __typename?: 'Mutation';
  login?: Maybe<LoginPayload>;
  logout?: Maybe<Logout>;
  updateUser?: Maybe<UpdateUserMutationPayload>;
  updatePassword?: Maybe<UpdatePasswordMutationPayload>;
  resetPassword?: Maybe<ResetPasswordMutationPayload>;
  setPassword?: Maybe<SetPasswordMutationPayload>;
  createSale?: Maybe<CreateSaleMutationPayload>;
  updateSale?: Maybe<UpdateSaleMutationPayload>;
  deleteSale?: Maybe<DeleteSaleMutationPayload>;
  startSaleReview?: Maybe<StartSaleReviewMutationPayload>;
  reviewSale?: Maybe<SaleReviewMutationPayload>;
  createSalesTarget?: Maybe<CreateSalesTargetMutationPayload>;
  updateSalesTarget?: Maybe<UpdateSalesTargetMutationPayload>;
  deleteSalesTarget?: Maybe<DeleteSalesTargetMutationPayload>;
  createSaleAdministration?: Maybe<CreateSaleAdministrationMutationPayload>;
  updateSaleAdministration?: Maybe<UpdateSaleAdministrationMutationPayload>;
  deleteSaleAdministration?: Maybe<DeleteSaleAdministrationMutationPayload>;
  createCreditNote?: Maybe<CreateCreditNotesMutationPayload>;
  updateCreditNote?: Maybe<UpdateCreditNoteMutationPayload>;
  deleteCreditNote?: Maybe<DeleteCreditNoteMutationPayload>;
  createCreditNoteReview?: Maybe<CreateCreditNoteReviewMutationPayload>;
  createSalePDF?: Maybe<CreateSalePdfMutationPayload>;
  createQuote?: Maybe<CreateQuoteMutationPayload>;
  updateQuote?: Maybe<UpdateQuoteMutationPayload>;
  deleteQuote?: Maybe<DeleteQuoteMutationPayload>;
  createQuotePDF?: Maybe<CreateQuotePdfMutationPayload>;
  createQuoteComment?: Maybe<CreateQuoteCommentMutationPayload>;
  updateQuoteComment?: Maybe<UpdateQuoteCommentMutationPayload>;
  deleteQuoteComment?: Maybe<DeleteQuoteCommentMutationPayload>;
  createOpportunity?: Maybe<CreateOpportunityMutationPayload>;
  updateOpportunity?: Maybe<UpdateOpportunityMutationPayload>;
  deleteOpportunity?: Maybe<DeleteOpportunityMutationPayload>;
  setOpportunityRead?: Maybe<SetOpportunityReadMutationPayload>;
  createContact?: Maybe<CreateContactMutationPayload>;
  updateContact?: Maybe<UpdateContactMutationPayload>;
  deleteContact?: Maybe<DeleteContactMutationPayload>;
  createAccount?: Maybe<CreateAccountMutationPayload>;
  updateAccount?: Maybe<UpdateAccountMutationPayload>;
  deleteAccount?: Maybe<DeleteAccountMutationPayload>;
  createNotification?: Maybe<CreateNotificationMutationPayload>;
  updateNotificationUser?: Maybe<UpdateNotificationUserMutationPayload>;
  updateNotificationAll?: Maybe<UpdateNotificationAllMutationPayload>;
  createUserPreferences?: Maybe<CreateUserPreferencesMutationPayload>;
  updateUserPreferences?: Maybe<UpdateUserPreferencesMutationPayload>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserMutationInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordMutationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordMutationInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordMutationInput;
};


export type MutationCreateSaleArgs = {
  input: CreateSaleMutationInput;
};


export type MutationUpdateSaleArgs = {
  input: UpdateSaleMutationInput;
};


export type MutationDeleteSaleArgs = {
  input: DeleteSaleMutationInput;
};


export type MutationStartSaleReviewArgs = {
  input: StartSaleReviewMutationInput;
};


export type MutationReviewSaleArgs = {
  input: SaleReviewMutationInput;
};


export type MutationCreateSalesTargetArgs = {
  input: CreateSalesTargetMutationInput;
};


export type MutationUpdateSalesTargetArgs = {
  input: UpdateSalesTargetMutationInput;
};


export type MutationDeleteSalesTargetArgs = {
  input: DeleteSalesTargetMutationInput;
};


export type MutationCreateSaleAdministrationArgs = {
  input: CreateSaleAdministrationMutationInput;
};


export type MutationUpdateSaleAdministrationArgs = {
  input: UpdateSaleAdministrationMutationInput;
};


export type MutationDeleteSaleAdministrationArgs = {
  input: DeleteSaleAdministrationMutationInput;
};


export type MutationCreateCreditNoteArgs = {
  input: CreateCreditNotesMutationInput;
};


export type MutationUpdateCreditNoteArgs = {
  input: UpdateCreditNoteMutationInput;
};


export type MutationDeleteCreditNoteArgs = {
  input: DeleteCreditNoteMutationInput;
};


export type MutationCreateCreditNoteReviewArgs = {
  input: CreateCreditNoteReviewMutationInput;
};


export type MutationCreateSalePdfArgs = {
  input: CreateSalePdfMutationInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteMutationInput;
};


export type MutationUpdateQuoteArgs = {
  input: UpdateQuoteMutationInput;
};


export type MutationDeleteQuoteArgs = {
  input: DeleteQuoteMutationInput;
};


export type MutationCreateQuotePdfArgs = {
  input: CreateQuotePdfMutationInput;
};


export type MutationCreateQuoteCommentArgs = {
  input: CreateQuoteCommentMutationInput;
};


export type MutationUpdateQuoteCommentArgs = {
  input: UpdateQuoteCommentMutationInput;
};


export type MutationDeleteQuoteCommentArgs = {
  input: DeleteQuoteCommentMutationInput;
};


export type MutationCreateOpportunityArgs = {
  input: CreateOpportunityMutationInput;
};


export type MutationUpdateOpportunityArgs = {
  input: UpdateOpportunityMutationInput;
};


export type MutationDeleteOpportunityArgs = {
  input: DeleteOpportunityMutationInput;
};


export type MutationSetOpportunityReadArgs = {
  input: SetOpportunityReadMutationInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactMutationInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactMutationInput;
};


export type MutationDeleteContactArgs = {
  input: DeleteContactMutationInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountMutationInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountMutationInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountMutationInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationMutationInput;
};


export type MutationUpdateNotificationUserArgs = {
  input: UpdateNotificationUserMutationInput;
};


export type MutationUpdateNotificationAllArgs = {
  input: UpdateNotificationAllMutationInput;
};


export type MutationCreateUserPreferencesArgs = {
  input: CreateUserPreferencesMutationInput;
};


export type MutationUpdateUserPreferencesArgs = {
  input: UpdateUserPreferencesMutationInput;
};

export type NotificationType = {
   __typename?: 'NotificationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  text: Scalars['String'];
  title: Scalars['String'];
  sale?: Maybe<SaleType>;
};

export type NotificationUserType = {
   __typename?: 'NotificationUserType';
  id: Scalars['UUID'];
  seen: Scalars['Boolean'];
  userNotified?: Maybe<UserType>;
  notificationId?: Maybe<NotificationType>;
};

export type OpportunitiesAnalyticsType = {
   __typename?: 'OpportunitiesAnalyticsType';
  count: Scalars['Int'];
  byChannel: Array<Datum>;
  byReason: Array<Datum>;
  bySituation: Array<Datum>;
  byDate: Array<DateSeriesDatum>;
  productRanking: Array<ProductRankingDatum>;
  marketSurveyAverages: Array<MarketSurveyAveragesDatum>;
};

export type OpportunityChannelType = {
   __typename?: 'OpportunityChannelType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  opportunitySet: Array<OpportunityType>;
};

export type OpportunityListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
  channel?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reason?: Maybe<Array<Maybe<Scalars['ID']>>>;
  situation?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type OpportunityListType = {
   __typename?: 'OpportunityListType';
  items: Array<OpportunityType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type OpportunityReasonType = {
   __typename?: 'OpportunityReasonType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  opportunitySet: Array<OpportunityType>;
};

export type OpportunitySituationType = {
   __typename?: 'OpportunitySituationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  opportunitySet: Array<OpportunityType>;
};

export type OpportunityType = {
   __typename?: 'OpportunityType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  date: Scalars['Date'];
  channel: OpportunityChannelType;
  reason: OpportunityReasonType;
  situation: OpportunitySituationType;
  notes: Scalars['String'];
  notesTracking: Scalars['String'];
  productsLegacy: Scalars['String'];
  products: Array<ProductType>;
  promotions: Array<PromotionType>;
  marketingActions: Scalars['String'];
  contact: ContactType;
  read?: Maybe<Scalars['DateTime']>;
  readBy?: Maybe<UserType>;
  marketSurveys: Array<MarketSurveyType>;
  displayName: Scalars['String'];
};

export type OrganizationType = {
   __typename?: 'OrganizationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
};

export type PaymentMethodType = {
   __typename?: 'PaymentMethodType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  multiplier: Scalars['Float'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
};

export type PeriodDatum = {
   __typename?: 'PeriodDatum';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  amountSelled: Scalars['Float'];
  amountQuoted: Scalars['Float'];
  salesGap: Scalars['Int'];
  quotesGap: Scalars['Int'];
  monthlySalesTarget: Scalars['Int'];
};

export type ProductCurrentOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
};

export type ProductCurrentType = {
   __typename?: 'ProductCurrentType';
  items: Array<ProductType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ProductFiveRanking = {
   __typename?: 'ProductFiveRanking';
  product?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type ProductListType = {
   __typename?: 'ProductListType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  productSet: Array<ProductType>;
  quotedproductSet: Array<QuotedProductType>;
  saleproductSet?: Maybe<Array<SaleProductType>>;
};

export type ProductRankingDatum = {
   __typename?: 'ProductRankingDatum';
  product: ProductType;
  count: Scalars['Float'];
  ranking: Scalars['Int'];
  liters: Scalars['Float'];
};

export type ProductType = {
   __typename?: 'ProductType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  externalId: Scalars['String'];
  composition: Scalars['String'];
  presentation: Scalars['String'];
  list: ProductListType;
  unit: UnitType;
  price: Scalars['Float'];
  yieldField: Scalars['Float'];
  country?: Maybe<AccountCountryType>;
  published: Scalars['Boolean'];
  opportunitySet: Array<OpportunityType>;
};

export type PromotionType = {
   __typename?: 'PromotionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  opportunitySet: Array<OpportunityType>;
};

export type Query = {
   __typename?: 'Query';
  weekSummary: WeekSummaryType;
  user?: Maybe<UserType>;
  users: Array<UserType>;
  childUsers: Array<UserType>;
  organizations: Array<OrganizationType>;
  childOrganizations: Array<OrganizationType>;
  maxCommissionMultiplier: Scalars['Float'];
  quotePdfDisclaimer: Scalars['String'];
  activeIngredients: Array<Maybe<ActiveIngredientType>>;
  formulations: Array<Maybe<FormulationType>>;
  laboratories: Array<Maybe<LaboratoryType>>;
  season: SeasonType;
  seasons: Array<Maybe<SeasonType>>;
  salesTargetsPeriods?: Maybe<SalesTargetsPeriodsType>;
  sale: SaleType;
  sales: SaleListType;
  salesUser: Array<Maybe<SaleUserListType>>;
  salesTargetUser: SalesTargetType;
  salesTargets: SalesTargetListType;
  salesApproved: Array<Maybe<SaleApprovedListType>>;
  salesApprovedAll: Array<Maybe<SaleApprovedListType>>;
  salesCreditsAll: Array<Maybe<SaleCreditsListType>>;
  reasonApproval: Array<Maybe<ReasonType>>;
  quote: QuoteType;
  quotesUser: Array<Maybe<QuoteUserListType>>;
  quotes: QuoteListType;
  totalSalesPeriod: TotalSales;
  quoteOrderTypes: Array<Maybe<QuoteOrderTypeType>>;
  paymentMethods: Array<Maybe<PaymentMethodType>>;
  successChances?: Maybe<Array<Maybe<SuccessChanceType>>>;
  products: Array<Maybe<ProductType>>;
  productsCurrent: ProductCurrentType;
  product: ProductType;
  productByExternalId: ProductType;
  productLists: Array<Maybe<ProductListType>>;
  someProductsByExternalId: Array<Maybe<ProductType>>;
  productsSales: Array<Maybe<ProductFiveRanking>>;
  units: Array<Maybe<UnitType>>;
  promotions: Array<Maybe<PromotionType>>;
  productsByCountry: Array<Maybe<ProductType>>;
  productListsByCountry: Array<Maybe<ProductType>>;
  opportunity: OpportunityType;
  opportunities: OpportunityListType;
  opportunityChannels: Array<Maybe<OpportunityChannelType>>;
  opportunityReasons: Array<Maybe<OpportunityReasonType>>;
  opportunitySituations: Array<Maybe<OpportunitySituationType>>;
  notifications: Array<Maybe<NotificationType>>;
  notificationsUser?: Maybe<Array<Maybe<NotificationUserType>>>;
  contact: ContactType;
  contacts: ContactListType;
  contactTypes: Array<Maybe<ContactTypeType>>;
  intermediaries: Array<Maybe<ContactType>>;
  opportunitiesAnalytics?: Maybe<OpportunitiesAnalyticsType>;
  quotesAnalytics?: Maybe<QuotesAnalyticsType>;
  salesAnalytics?: Maybe<SalesAnalyticsType>;
  account: AccountType;
  accounts: AccountListType;
  amountByAccount: Array<Maybe<AccountRanking>>;
  accountTypes: Array<Maybe<AccountTypeType>>;
  accountRegions: Array<Maybe<AccountRegionType>>;
  accountCountries: Array<Maybe<AccountCountryType>>;
  accountCountry: AccountCountryType;
  accountRegionsByCountry: Array<Maybe<AccountRegionType>>;
};


export type QueryWeekSummaryArgs = {
  seller?: Maybe<Scalars['String']>;
};


export type QueryFormulationsArgs = {
  activeIngredientId?: Maybe<Scalars['UUID']>;
};


export type QuerySeasonArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QuerySalesTargetsPeriodsArgs = {
  filters: SalesTargetsPeriodsFilters;
};


export type QuerySaleArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QuerySalesArgs = {
  options?: Maybe<SaleListOptionsInputType>;
};


export type QuerySalesUserArgs = {
  user?: Maybe<Scalars['String']>;
};


export type QuerySalesTargetUserArgs = {
  user: Scalars['String'];
};


export type QuerySalesTargetsArgs = {
  options?: Maybe<SalesTargetListOptionsInputType>;
};


export type QuerySalesApprovedArgs = {
  options?: Maybe<SalesApprovedListOptionsInputType>;
};


export type QuerySalesApprovedAllArgs = {
  options?: Maybe<SalesApprovedListOptionsInputType>;
};


export type QuerySalesCreditsAllArgs = {
  options?: Maybe<SalesCreditsListOptionsInputType>;
};


export type QueryQuoteArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryQuotesUserArgs = {
  user?: Maybe<Scalars['String']>;
};


export type QueryQuotesArgs = {
  options?: Maybe<QuoteListOptionsInputType>;
};


export type QueryTotalSalesPeriodArgs = {
  user: Scalars['String'];
  period?: Maybe<Scalars['String']>;
};


export type QueryProductsCurrentArgs = {
  options?: Maybe<ProductCurrentOptionsInputType>;
};


export type QueryProductArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryProductByExternalIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QuerySomeProductsByExternalIdArgs = {
  exIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryProductsSalesArgs = {
  user?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
};


export type QueryProductsByCountryArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryProductListsByCountryArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryOpportunityArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryOpportunitiesArgs = {
  options?: Maybe<OpportunityListOptionsInputType>;
};


export type QueryContactArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryContactsArgs = {
  options?: Maybe<ContactListOptionsInputType>;
};


export type QueryOpportunitiesAnalyticsArgs = {
  filters: AnalyticsFilters;
};


export type QueryQuotesAnalyticsArgs = {
  filters: AnalyticsFilters;
};


export type QuerySalesAnalyticsArgs = {
  filters: AnalyticsFilters;
};


export type QueryAccountArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryAccountsArgs = {
  options?: Maybe<AccountListOptionsInputType>;
};


export type QueryAmountByAccountArgs = {
  user?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
};


export type QueryAccountCountryArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryAccountRegionsByCountryArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export type QuoteCommentType = {
   __typename?: 'QuoteCommentType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  message?: Maybe<Scalars['String']>;
  quote: QuoteType;
};

export type QuotedPaymentMethodFormInput = {
  name: Scalars['String'];
  multiplier: Scalars['Float'];
  order: Scalars['Int'];
  isCustom?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type QuotedPaymentMethodType = {
   __typename?: 'QuotedPaymentMethodType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  multiplier: Scalars['Float'];
  order: Scalars['Int'];
  isCustom: Scalars['Boolean'];
  quote: QuoteType;
  sale?: Maybe<SaleType>;
};

export type QuotedProductFormInput = {
  name: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  composition: Scalars['String'];
  presentation: Scalars['String'];
  list: Scalars['ID'];
  unit: Scalars['ID'];
  price: Scalars['Float'];
  yieldField: Scalars['Float'];
  country?: Maybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  multiplier: Scalars['Float'];
  intermediaryCommission: Scalars['Float'];
  deliveryPeriod?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
};

export type QuotedProductType = {
   __typename?: 'QuotedProductType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  externalId: Scalars['String'];
  composition: Scalars['String'];
  presentation: Scalars['String'];
  list: ProductListType;
  unit: UnitType;
  price: Scalars['Float'];
  yieldField: Scalars['Float'];
  country?: Maybe<AccountCountryType>;
  quantity: Scalars['Int'];
  multiplier: Scalars['Float'];
  quote: QuoteType;
  intermediaryCommission: Scalars['Float'];
  deliveryPeriod?: Maybe<Scalars['DateTime']>;
};

export type QuoteListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
};

export type QuoteListType = {
   __typename?: 'QuoteListType';
  items: Array<QuoteType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type QuoteOrderTypeType = {
   __typename?: 'QuoteOrderTypeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  quoteSet: Array<QuoteType>;
  saleSet: Array<SaleType>;
};

export type QuotePdfRecipientFormInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type QuotePdfRecipientType = {
   __typename?: 'QuotePDFRecipientType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  quotePdf: QuotePdfType;
  email: Scalars['String'];
  statusDisplay: Scalars['String'];
};

export type QuotePdfType = {
   __typename?: 'QuotePDFType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  file?: Maybe<Scalars['String']>;
  type: QuotesQuotePdfTypeChoices;
  quote: QuoteType;
  slug: Scalars['String'];
  recipients: Array<QuotePdfRecipientType>;
  typeDisplay?: Maybe<Scalars['String']>;
};

export type QuotesAnalyticsType = {
   __typename?: 'QuotesAnalyticsType';
  count: Scalars['Int'];
  amount: Scalars['Float'];
  liters: Scalars['Float'];
  byDate: Array<DateSeriesDatum>;
  byAccountType: Array<AmountDatum>;
  byPaymentMethod: Array<AmountDatum>;
  byClient: Array<AmountDatum>;
  byStatus: Array<AmountDatum>;
  productRanking: Array<ProductRankingDatum>;
  productList: Array<AmountDatum>;
};

export enum QuotesQuotePdfTypeChoices {
  Internal = 'INTERNAL',
  Client = 'CLIENT',
  Intermediary = 'INTERMEDIARY'
}

export type QuoteType = {
   __typename?: 'QuoteType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  contact: ContactType;
  intermediary?: Maybe<ContactType>;
  selectedPaymentMethod?: Maybe<QuotedPaymentMethodType>;
  orderType?: Maybe<QuoteOrderTypeType>;
  notes?: Maybe<Scalars['String']>;
  successChance?: Maybe<SuccessChanceType>;
  products: Array<QuotedProductType>;
  paymentMethods: Array<QuotedPaymentMethodType>;
  pdfs: Array<QuotePdfType>;
  comments: Array<QuoteCommentType>;
  sale?: Maybe<SaleType>;
  displayName: Scalars['String'];
};

export type QuoteUserListType = {
   __typename?: 'QuoteUserListType';
  items: Array<QuoteType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ReasonType = {
   __typename?: 'ReasonType';
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type ResetPasswordMutationInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordMutationPayload = {
   __typename?: 'ResetPasswordMutationPayload';
  email: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RoleType = {
   __typename?: 'RoleType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  userSet: Array<UserType>;
};

export type SaleAdministrationType = {
   __typename?: 'SaleAdministrationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  sale: SaleType;
  type: SalesAdministrationTypeChoices;
  typeDisplay: Scalars['String'];
  percentage: Scalars['Float'];
  productsAdmin: Array<AdministrationProductType>;
};

export type SaleApprovedListType = {
   __typename?: 'SaleApprovedListType';
  items: Array<SaleType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SaleCreditsListType = {
   __typename?: 'SaleCreditsListType';
  items: Array<SaleType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SaleListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SaleListType = {
   __typename?: 'SaleListType';
  items: Array<SaleType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SalePdfRecipientFormInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type SalePdfRecipientType = {
   __typename?: 'SalePDFRecipientType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  salePdf: SalePdfType;
  email: Scalars['String'];
  statusDisplay: Scalars['String'];
};

export type SalePdfType = {
   __typename?: 'SalePDFType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  sale: SaleType;
  file?: Maybe<Scalars['String']>;
  type: SalesSalePdfTypeChoices;
  slug: Scalars['String'];
  recipients: Array<SalePdfRecipientType>;
  typeDisplay?: Maybe<Scalars['String']>;
};

export type SaleProductFormInput = {
  name: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  composition: Scalars['String'];
  presentation: Scalars['String'];
  list: Scalars['ID'];
  unit: Scalars['ID'];
  price: Scalars['Float'];
  yieldField: Scalars['Float'];
  country?: Maybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  multiplier: Scalars['Float'];
  intermediaryCommission: Scalars['Float'];
  deliveryPeriod?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
};

export type SaleProductType = {
   __typename?: 'SaleProductType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  externalId: Scalars['String'];
  composition: Scalars['String'];
  presentation: Scalars['String'];
  list: ProductListType;
  unit: UnitType;
  price: Scalars['Float'];
  yieldField: Scalars['Float'];
  country?: Maybe<AccountCountryType>;
  quantity: Scalars['Int'];
  multiplier: Scalars['Float'];
  sale: SaleType;
  intermediaryCommission: Scalars['Float'];
  deliveryPeriod?: Maybe<Scalars['DateTime']>;
};

export type SaleReviewMutationInput = {
  sale: Scalars['ID'];
  reason: Scalars['ID'];
  type: Scalars['String'];
  status: Scalars['String'];
  previousType: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SaleReviewMutationPayload = {
   __typename?: 'SaleReviewMutationPayload';
  saleReview?: Maybe<SaleReviewType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SaleReviewType = {
   __typename?: 'SaleReviewType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  sale: SaleType;
  type: SalesSaleReviewTypeChoices;
  status: SalesSaleReviewStatusChoices;
  previousType: SalesSaleReviewTypeChoices;
  reason: ReasonType;
  notes: Scalars['String'];
  typeDisplay: Scalars['String'];
  statusDisplay: Scalars['String'];
  previousTypeDisplay: Scalars['String'];
};

export enum SalesAdministrationTypeChoices {
  Delivered = 'DELIVERED',
  Invoiced = 'INVOICED',
  Ncredits = 'NCREDITS'
}

export type SalesAnalyticsType = {
   __typename?: 'SalesAnalyticsType';
  count: Scalars['Int'];
  amount: Scalars['Float'];
  liters: Scalars['Float'];
  byDate: Array<DateSeriesDatum>;
  productRanking: Array<ProductRankingDatum>;
  byAccountType: Array<AmountDatum>;
  byClient: Array<AmountDatum>;
  byPaymentMethod: Array<AmountDatum>;
  productList: Array<AmountDatum>;
};

export type SalesApprovedListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SalesCreditsListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum SalesSalePdfTypeChoices {
  Internal = 'INTERNAL',
  Client = 'CLIENT',
  Intermediary = 'INTERMEDIARY'
}

export enum SalesSaleReviewStatusChoices {
  Approved = 'APPROVED',
  ParcialApproved = 'PARCIAL_APPROVED',
  ReturnAnalysis = 'RETURN_ANALYSIS',
  Rejected = 'REJECTED',
  Analysis = 'ANALYSIS'
}

export enum SalesSaleReviewTypeChoices {
  Commercial = 'COMMERCIAL',
  Credits = 'CREDITS',
  Gtecredits = 'GTECREDITS',
  Gteadmin = 'GTEADMIN',
  Dircommercial = 'DIRCOMMERCIAL',
  Dirmanaging = 'DIRMANAGING',
  Committee = 'COMMITTEE'
}

export enum SalesSaleStatusChoices {
  Draft = 'DRAFT',
  CommercialReview = 'COMMERCIAL_REVIEW',
  DircommercialReview = 'DIRCOMMERCIAL_REVIEW',
  CommitteeReview = 'COMMITTEE_REVIEW',
  CreditsReview = 'CREDITS_REVIEW',
  ParcialApproved = 'PARCIAL_APPROVED',
  Approved = 'APPROVED',
  Delivered = 'DELIVERED'
}

export type SalesTargetListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  dfrom?: Maybe<Scalars['String']>;
  dto?: Maybe<Scalars['String']>;
};

export type SalesTargetListType = {
   __typename?: 'SalesTargetListType';
  items: Array<SalesTargetType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SalesTargetsPeriodsFilters = {
  season?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
};

export type SalesTargetsPeriodsType = {
   __typename?: 'SalesTargetsPeriodsType';
  byPeriod: Array<PeriodDatum>;
};

export type SalesTargetType = {
   __typename?: 'SalesTargetType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  seller?: Maybe<UserType>;
  amount: Scalars['Float'];
  saleTotal: Scalars['Float'];
  season: SeasonType;
};

export type SaleType = {
   __typename?: 'SaleType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  quote: QuoteType;
  paymentMethod: QuotedPaymentMethodType;
  orderType?: Maybe<QuoteOrderTypeType>;
  status: SalesSaleStatusChoices;
  reviewStart?: Maybe<Scalars['DateTime']>;
  pdfs: Array<SalePdfType>;
  reviews: Array<SaleReviewType>;
  products?: Maybe<Array<SaleProductType>>;
  administration: Array<SaleAdministrationType>;
  credits: Array<CreditNoteType>;
  displayName: Scalars['String'];
  statusDisplay: Scalars['String'];
  commercialCanReview: Scalars['Boolean'];
  dirCommercialCanReview: Scalars['Boolean'];
  creditsCanReview: Scalars['Boolean'];
  gteCreditsCanReview: Scalars['Boolean'];
  gteAdminCanReview: Scalars['Boolean'];
  dirManagingCanReview: Scalars['Boolean'];
  committeeCanReview: Scalars['Boolean'];
};

export type SaleUserListType = {
   __typename?: 'SaleUserListType';
  items: Array<SaleType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SeasonType = {
   __typename?: 'SeasonType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  salestargetSet: Array<SalesTargetType>;
  displayName: Scalars['String'];
};

export type SetOpportunityReadMutationInput = {
  read?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetOpportunityReadMutationPayload = {
   __typename?: 'SetOpportunityReadMutationPayload';
  opportunity?: Maybe<OpportunityType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetPasswordMutationInput = {
  user: Scalars['UUID'];
  token: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetPasswordMutationPayload = {
   __typename?: 'SetPasswordMutationPayload';
  user: Scalars['UUID'];
  token: Scalars['String'];
  password: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StartSaleReviewMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StartSaleReviewMutationPayload = {
   __typename?: 'StartSaleReviewMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SuccessChanceType = {
   __typename?: 'SuccessChanceType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  quoteSet: Array<QuoteType>;
};

export type SummaryClientType = {
   __typename?: 'SummaryClientType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  account: AccountType;
  trade?: Maybe<Scalars['Int']>;
  commission?: Maybe<Scalars['Int']>;
  echeqdif?: Maybe<Scalars['Int']>;
  colecction?: Maybe<Scalars['Int']>;
  rural?: Maybe<Scalars['Int']>;
  transfer?: Maybe<Scalars['Int']>;
  echeqcurrent?: Maybe<Scalars['Int']>;
  nc?: Maybe<Scalars['Int']>;
  cash?: Maybe<Scalars['Int']>;
  negative?: Maybe<Scalars['Int']>;
  grain?: Maybe<Scalars['Int']>;
};

export type TotalSales = {
   __typename?: 'TotalSales';
  total: Scalars['Float'];
};

export type UnitType = {
   __typename?: 'UnitType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  symbol: Scalars['String'];
  order: Scalars['Int'];
  productSet: Array<ProductType>;
  quotedproductSet: Array<QuotedProductType>;
};

export type UpdateAccountMutationInput = {
  name: Scalars['String'];
  taxId: Scalars['String'];
  type: Scalars['ID'];
  country: Scalars['ID'];
  region: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccountMutationPayload = {
   __typename?: 'UpdateAccountMutationPayload';
  account?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  type: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  account: Scalars['ID'];
  intermediary?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationPayload = {
   __typename?: 'UpdateContactMutationPayload';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCreditNoteMutationInput = {
  sale: Scalars['ID'];
  orderType: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  creditProducts: Array<CreditProductFormInput>;
};

export type UpdateCreditNoteMutationPayload = {
   __typename?: 'UpdateCreditNoteMutationPayload';
  creditNote?: Maybe<CreditNoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateNotificationAllMutationInput = {
  id: Scalars['UUID'];
  userNotified?: Maybe<Scalars['ID']>;
};

export type UpdateNotificationAllMutationPayload = {
   __typename?: 'UpdateNotificationAllMutationPayload';
  notificationUser?: Maybe<NotificationUserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateNotificationUserMutationInput = {
  id: Scalars['UUID'];
  seen: Scalars['Boolean'];
  userNotified?: Maybe<Scalars['ID']>;
  notificationId?: Maybe<Scalars['ID']>;
};

export type UpdateNotificationUserMutationPayload = {
   __typename?: 'UpdateNotificationUserMutationPayload';
  notificationUser?: Maybe<NotificationUserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateOpportunityMutationInput = {
  date: Scalars['Date'];
  channel: Scalars['ID'];
  reason: Scalars['ID'];
  situation: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  notesTracking?: Maybe<Scalars['String']>;
  productsLegacy?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
  promotions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  marketingActions?: Maybe<Scalars['String']>;
  contact: Scalars['ID'];
  read?: Maybe<Scalars['DateTime']>;
  readBy?: Maybe<Scalars['ID']>;
  marketSurveys?: Maybe<Array<MarketSurveyFormInput>>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOpportunityMutationPayload = {
   __typename?: 'UpdateOpportunityMutationPayload';
  opportunity?: Maybe<OpportunityType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePasswordMutationInput = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePasswordMutationPayload = {
   __typename?: 'UpdatePasswordMutationPayload';
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteCommentMutationInput = {
  message?: Maybe<Scalars['String']>;
  quote: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteCommentMutationPayload = {
   __typename?: 'UpdateQuoteCommentMutationPayload';
  quoteComment?: Maybe<QuoteCommentType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteMutationInput = {
  contact: Scalars['ID'];
  intermediary?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['ID']>;
  successChance?: Maybe<Scalars['ID']>;
  products: Array<QuotedProductFormInput>;
  paymentMethods: Array<QuotedPaymentMethodFormInput>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteMutationPayload = {
   __typename?: 'UpdateQuoteMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSaleAdministrationMutationInput = {
  id?: Maybe<Scalars['ID']>;
  sale: Scalars['ID'];
  type: Scalars['String'];
  productsAdmin: Array<AdministrationProductFormInput>;
  percentage: Scalars['Float'];
};

export type UpdateSaleAdministrationMutationPayload = {
   __typename?: 'UpdateSaleAdministrationMutationPayload';
  saleAdministration?: Maybe<SaleAdministrationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UpdateSaleMutationInput = {
  quote: Scalars['ID'];
  paymentMethod: Scalars['ID'];
  orderType?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  products: Array<SaleProductFormInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSaleMutationPayload = {
   __typename?: 'UpdateSaleMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSalesTargetMutationInput = {
  seller?: Maybe<Scalars['ID']>;
  amount: Scalars['Float'];
  season: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSalesTargetMutationPayload = {
   __typename?: 'UpdateSalesTargetMutationPayload';
  salesTarget?: Maybe<SalesTargetType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserMutationInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  onboarding?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserMutationPayload = {
   __typename?: 'UpdateUserMutationPayload';
  user?: Maybe<UserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserPreferencesMutationInput = {
  id?: Maybe<Scalars['UUID']>;
  user?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  frequency: Scalars['String'];
};

export type UpdateUserPreferencesMutationPayload = {
   __typename?: 'UpdateUserPreferencesMutationPayload';
  userPreferences?: Maybe<UserPreferencesType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type UserPermissions = {
   __typename?: 'UserPermissions';
  assignAccountOwner: Scalars['Boolean'];
  requestSaleReview: Scalars['Boolean'];
  createPaymentMethod: Scalars['Boolean'];
  submitCommercialSaleReview: Scalars['Boolean'];
  submitDirCommercialSaleReview: Scalars['Boolean'];
  submitCreditsSaleReview: Scalars['Boolean'];
  submitGCreditsSaleReview: Scalars['Boolean'];
  submitGAdminSaleReview: Scalars['Boolean'];
  submitDirManagingSaleReview: Scalars['Boolean'];
  submitCommitteeSaleReview: Scalars['Boolean'];
  receiveMarketingActionsSummary: Scalars['Boolean'];
  viewAnalytics: Scalars['Boolean'];
  markOpportunitiesAsRead: Scalars['Boolean'];
  createSalesTargets: Scalars['Boolean'];
  viewSalesTargetsForChildUsers: Scalars['Boolean'];
  adminProcess: Scalars['Boolean'];
};

export enum UserPreferencesFrequencyChoices {
  None = 'NONE',
  Realtime = 'REALTIME',
  Daily = 'DAILY'
}

export type UserPreferencesType = {
   __typename?: 'UserPreferencesType';
  id?: Maybe<Scalars['UUID']>;
  user?: Maybe<UserType>;
  frequency?: Maybe<UserPreferencesFrequencyChoices>;
  type?: Maybe<UserPreferencesTypeChoices>;
};

export enum UserPreferencesTypeChoices {
  Review = 'REVIEW',
  Approval = 'APPROVAL'
}

export type UserType = {
   __typename?: 'UserType';
  id: Scalars['UUID'];
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  isSuperuser: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isStaff: Scalars['Boolean'];
  onboarding: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  role?: Maybe<RoleType>;
  language: Scalars['String'];
  accountSet: Array<AccountType>;
  opportunitySet: Array<OpportunityType>;
  salestargetSet: Array<SalesTargetType>;
  displayName: Scalars['String'];
  permissions: UserPermissions;
  organization?: Maybe<OrganizationType>;
  preferences: Array<UserPreferencesType>;
};


export type WeekSummaryType = {
   __typename?: 'WeekSummaryType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  seller?: Maybe<UserType>;
  summaryClient: Array<SummaryClientType>;
};

export type OpportunitiesAnalyticsFragment = (
  { __typename?: 'OpportunitiesAnalyticsType' }
  & Pick<OpportunitiesAnalyticsType, 'count'>
  & { byChannel: Array<(
    { __typename?: 'Datum' }
    & Pick<Datum, 'label' | 'count' | 'percentage'>
  )>, byReason: Array<(
    { __typename?: 'Datum' }
    & Pick<Datum, 'label' | 'count' | 'percentage'>
  )>, bySituation: Array<(
    { __typename?: 'Datum' }
    & Pick<Datum, 'label' | 'count' | 'percentage'>
  )>, byDate: Array<(
    { __typename?: 'DateSeriesDatum' }
    & Pick<DateSeriesDatum, 'date' | 'count'>
  )>, productRanking: Array<(
    { __typename?: 'ProductRankingDatum' }
    & Pick<ProductRankingDatum, 'count' | 'ranking'>
    & { product: (
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'name'>
    ) }
  )>, marketSurveyAverages: Array<(
    { __typename?: 'MarketSurveyAveragesDatum' }
    & Pick<MarketSurveyAveragesDatum, 'count' | 'averagePrice' | 'averagePaymentDeadline'>
    & { activeIngredient: (
      { __typename?: 'ActiveIngredientType' }
      & Pick<ActiveIngredientType, 'id' | 'name'>
    ) }
  )> }
);

export type GetOpportunitiesAnalyticsQueryVariables = {
  filters: AnalyticsFilters;
};


export type GetOpportunitiesAnalyticsQuery = (
  { __typename?: 'Query' }
  & { opportunitiesAnalytics?: Maybe<(
    { __typename?: 'OpportunitiesAnalyticsType' }
    & OpportunitiesAnalyticsFragment
  )> }
);

export type QuotesAnalyticsFragment = (
  { __typename?: 'QuotesAnalyticsType' }
  & Pick<QuotesAnalyticsType, 'count' | 'amount' | 'liters'>
  & { byDate: Array<(
    { __typename?: 'DateSeriesDatum' }
    & Pick<DateSeriesDatum, 'date' | 'count' | 'amount'>
  )>, byAccountType: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )>, byClient: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )>, byPaymentMethod: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )>, productRanking: Array<(
    { __typename?: 'ProductRankingDatum' }
    & Pick<ProductRankingDatum, 'count' | 'ranking' | 'liters'>
    & { product: (
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'name'>
    ) }
  )>, productList: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )> }
);

export type GetQuotesAnalyticsQueryVariables = {
  filters: AnalyticsFilters;
};


export type GetQuotesAnalyticsQuery = (
  { __typename?: 'Query' }
  & { quotesAnalytics?: Maybe<(
    { __typename?: 'QuotesAnalyticsType' }
    & QuotesAnalyticsFragment
  )> }
);

export type SalesAnalyticsFragment = (
  { __typename?: 'SalesAnalyticsType' }
  & Pick<SalesAnalyticsType, 'count' | 'amount' | 'liters'>
  & { byDate: Array<(
    { __typename?: 'DateSeriesDatum' }
    & Pick<DateSeriesDatum, 'date' | 'count' | 'amount'>
  )>, byAccountType: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )>, byClient: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )>, productRanking: Array<(
    { __typename?: 'ProductRankingDatum' }
    & Pick<ProductRankingDatum, 'count' | 'ranking' | 'liters'>
    & { product: (
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'name'>
    ) }
  )>, byPaymentMethod: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )>, productList: Array<(
    { __typename?: 'AmountDatum' }
    & Pick<AmountDatum, 'label' | 'count' | 'percentage' | 'amount'>
  )> }
);

export type GetSalesAnalyticsQueryVariables = {
  filters: AnalyticsFilters;
};


export type GetSalesAnalyticsQuery = (
  { __typename?: 'Query' }
  & { salesAnalytics?: Maybe<(
    { __typename?: 'SalesAnalyticsType' }
    & SalesAnalyticsFragment
  )> }
);

export type UserFragment = (
  { __typename?: 'UserType' }
  & Pick<UserType, 'id' | 'email' | 'firstName' | 'lastName' | 'displayName' | 'isActive' | 'isStaff' | 'dateJoined' | 'onboarding' | 'lastLogin'>
  & { permissions: (
    { __typename?: 'UserPermissions' }
    & Pick<UserPermissions, 'assignAccountOwner' | 'requestSaleReview' | 'submitCommercialSaleReview' | 'submitDirCommercialSaleReview' | 'submitCreditsSaleReview' | 'submitGCreditsSaleReview' | 'submitGAdminSaleReview' | 'submitDirManagingSaleReview' | 'submitCommitteeSaleReview' | 'viewAnalytics' | 'markOpportunitiesAsRead' | 'createSalesTargets' | 'viewSalesTargetsForChildUsers' | 'adminProcess' | 'createPaymentMethod'>
  ), role?: Maybe<(
    { __typename?: 'RoleType' }
    & Pick<RoleType, 'name'>
  )>, preferences: Array<(
    { __typename?: 'UserPreferencesType' }
    & UserPreferencesFragment
  )> }
);

export type MinimalUserFragment = (
  { __typename?: 'UserType' }
  & Pick<UserType, 'id' | 'displayName'>
  & { role?: Maybe<(
    { __typename?: 'RoleType' }
    & Pick<RoleType, 'name'>
  )> }
);

export type MinimalAccountFragment = (
  { __typename?: 'AccountType' }
  & Pick<AccountType, 'id' | 'name' | 'taxId' | 'externalId'>
);

export type AccountRankingFragment = (
  { __typename?: 'AccountRanking' }
  & Pick<AccountRanking, 'account' | 'total'>
);

export type TotalSalesFragment = (
  { __typename?: 'TotalSales' }
  & Pick<TotalSales, 'total'>
);

export type AccountFragment = (
  { __typename?: 'AccountType' }
  & { owner?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, type: (
    { __typename?: 'AccountTypeType' }
    & AccountTypeOptionFragment
  ), region?: Maybe<(
    { __typename?: 'AccountRegionType' }
    & AccountRegionOptionFragment
  )>, country?: Maybe<(
    { __typename?: 'AccountCountryType' }
    & AccountCountryOptionFragment
  )> }
  & MinimalAccountFragment
);

export type AccountDetailFragment = (
  { __typename?: 'AccountType' }
  & Pick<AccountType, 'created' | 'updated'>
  & { contacts: Array<(
    { __typename?: 'ContactType' }
    & ContactFragment
  )>, opportunities: Array<Maybe<(
    { __typename?: 'OpportunityType' }
    & OpportunityFragment
  )>>, quotes: Array<Maybe<(
    { __typename?: 'QuoteType' }
    & QuoteFragment
  )>>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & AccountFragment
);

export type AccountOptionFragment = (
  { __typename?: 'AccountType' }
  & Pick<AccountType, 'id' | 'name'>
);

export type AccountTypeOptionFragment = (
  { __typename?: 'AccountTypeType' }
  & Pick<AccountTypeType, 'id' | 'name'>
);

export type AccountRegionOptionFragment = (
  { __typename?: 'AccountRegionType' }
  & Pick<AccountRegionType, 'id' | 'name'>
);

export type AccountCountryOptionFragment = (
  { __typename?: 'AccountCountryType' }
  & Pick<AccountCountryType, 'id' | 'name'>
);

export type MinimalContactFragment = (
  { __typename?: 'ContactType' }
  & Pick<ContactType, 'id' | 'email' | 'displayName'>
);

export type ContactFragment = (
  { __typename?: 'ContactType' }
  & Pick<ContactType, 'externalId' | 'firstName' | 'lastName' | 'phone' | 'mobilePhone' | 'intermediary'>
  & { type: (
    { __typename?: 'ContactTypeType' }
    & ContactTypeOptionFragment
  ), account: (
    { __typename?: 'AccountType' }
    & AccountOptionFragment
  ), opportunities: Array<(
    { __typename?: 'OpportunityType' }
    & OpportunityFragment
  )>, quotes: Array<(
    { __typename?: 'QuoteType' }
    & QuoteFragment
  )> }
  & MinimalContactFragment
);

export type ContactDetailFragment = (
  { __typename?: 'ContactType' }
  & Pick<ContactType, 'created' | 'updated'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & ContactFragment
);

export type ContactOptionFragment = (
  { __typename?: 'ContactType' }
  & Pick<ContactType, 'id' | 'email' | 'displayName'>
);

export type ContactTypeOptionFragment = (
  { __typename?: 'ContactTypeType' }
  & Pick<ContactTypeType, 'id' | 'name'>
);

export type OpportunityFragment = (
  { __typename?: 'OpportunityType' }
  & Pick<OpportunityType, 'id' | 'date' | 'displayName' | 'notes' | 'notesTracking' | 'productsLegacy' | 'marketingActions' | 'read'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, channel: (
    { __typename?: 'OpportunityChannelType' }
    & OpportunityChannelOptionFragment
  ), reason: (
    { __typename?: 'OpportunityReasonType' }
    & OpportunityReasonOptionFragment
  ), situation: (
    { __typename?: 'OpportunitySituationType' }
    & OpportunitySituationOptionFragment
  ), products: Array<(
    { __typename?: 'ProductType' }
    & ProductFragment
  )>, promotions: Array<(
    { __typename?: 'PromotionType' }
    & PromotionFragment
  )>, contact: (
    { __typename?: 'ContactType' }
    & { account: (
      { __typename?: 'AccountType' }
      & MinimalAccountFragment
    ) }
    & ContactOptionFragment
  ), marketSurveys: Array<(
    { __typename?: 'MarketSurveyType' }
    & MarketSurveyFragment
  )>, readBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type OpportunityDetailFragment = (
  { __typename?: 'OpportunityType' }
  & Pick<OpportunityType, 'productsLegacy' | 'created' | 'updated' | 'read'>
  & { products: Array<(
    { __typename?: 'ProductType' }
    & ProductFragment
  )>, promotions: Array<(
    { __typename?: 'PromotionType' }
    & PromotionFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, marketSurveys: Array<(
    { __typename?: 'MarketSurveyType' }
    & MarketSurveyFragment
  )>, readBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & OpportunityFragment
);

export type OpportunityChannelOptionFragment = (
  { __typename?: 'OpportunityChannelType' }
  & Pick<OpportunityChannelType, 'id' | 'name'>
);

export type OpportunityReasonOptionFragment = (
  { __typename?: 'OpportunityReasonType' }
  & Pick<OpportunityReasonType, 'id' | 'name'>
);

export type OpportunitySituationOptionFragment = (
  { __typename?: 'OpportunitySituationType' }
  & Pick<OpportunitySituationType, 'id' | 'name'>
);

export type PromotionFragment = (
  { __typename?: 'PromotionType' }
  & Pick<PromotionType, 'id' | 'name'>
);

export type MinimalProductListFragment = (
  { __typename?: 'ProductListType' }
  & Pick<ProductListType, 'id'>
);

export type UnitFragment = (
  { __typename?: 'UnitType' }
  & Pick<UnitType, 'id' | 'name' | 'symbol'>
);

export type ProductFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'id' | 'externalId' | 'name' | 'price' | 'composition' | 'presentation' | 'yieldField' | 'published'>
  & { list: (
    { __typename?: 'ProductListType' }
    & ProductListFragment
  ), unit: (
    { __typename?: 'UnitType' }
    & UnitFragment
  ) }
);

export type ProductListFragment = (
  { __typename?: 'ProductListType' }
  & Pick<ProductListType, 'id' | 'name' | 'order'>
);

export type ProductOptionFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'id' | 'name'>
);

export type ProductFiveRankingFragment = (
  { __typename?: 'ProductFiveRanking' }
  & Pick<ProductFiveRanking, 'product' | 'total'>
);

export type MinimalQuotedProductFragment = (
  { __typename?: 'QuotedProductType' }
  & Pick<QuotedProductType, 'id' | 'name' | 'composition' | 'presentation' | 'externalId' | 'price' | 'multiplier' | 'intermediaryCommission' | 'quantity' | 'yieldField' | 'deliveryPeriod' >
  & { unit: (
    { __typename?: 'UnitType' }
    & Pick<UnitType, 'id' | 'symbol'>
  ), list: (
    { __typename?: 'ProductListType' }
    & Pick<ProductListType, 'id' | 'name'>
  ) }
);

export type MinimalSaleProductFragment = (
  { __typename?: 'SaleProductType' }
  & Pick<SaleProductType, 'id' | 'name' | 'composition' | 'presentation' | 'externalId' | 'price' | 'multiplier' | 'intermediaryCommission' | 'quantity' | 'yieldField' | 'deliveryPeriod'>
  & { unit: (
    { __typename?: 'UnitType' }
    & Pick<UnitType, 'id'>
  ), list: (
    { __typename?: 'ProductListType' }
    & Pick<ProductListType, 'id'>
  ) }
);

export type QuotedProductFragment = (
  { __typename?: 'QuotedProductType' }
  & Pick<QuotedProductType, 'id' | 'externalId' | 'name' | 'price' | 'composition' | 'presentation' | 'quantity' | 'multiplier' | 'intermediaryCommission' | 'yieldField' | 'deliveryPeriod'>
  & { list: (
    { __typename?: 'ProductListType' }
    & ProductListFragment
  ), unit: (
    { __typename?: 'UnitType' }
    & UnitFragment
  ) }
);

export type SaleProductFragment = (
  { __typename?: 'SaleProductType' }
  & Pick<SaleProductType, 'id' | 'externalId' | 'name' | 'price' | 'composition' | 'presentation' | 'quantity' | 'multiplier' | 'intermediaryCommission' | 'yieldField' | 'deliveryPeriod'>
  & { list: (
    { __typename?: 'ProductListType' }
    & ProductListFragment
  ), unit: (
    { __typename?: 'UnitType' }
    & UnitFragment
  ) }
);

export type PaymentMethodFragment = (
  { __typename?: 'PaymentMethodType' }
  & Pick<PaymentMethodType, 'id' | 'name' | 'multiplier' | 'order' | 'published'>
);

export type PaymentMethodOptionFragment = (
  { __typename?: 'PaymentMethodType' }
  & Pick<PaymentMethodType, 'id' | 'name'>
);

export type MinimalQuotedPaymentMethodFragment = (
  { __typename?: 'QuotedPaymentMethodType' }
  & Pick<QuotedPaymentMethodType, 'id' | 'multiplier'>
);

export type QuotedPaymentMethodFragment = (
  { __typename?: 'QuotedPaymentMethodType' }
  & Pick<QuotedPaymentMethodType, 'id' | 'name' | 'multiplier' | 'order' | 'isCustom'>
);

export type QuotePdfFragment = (
  { __typename?: 'QuotePDFType' }
  & Pick<QuotePdfType, 'id' | 'file' | 'created' | 'slug' | 'typeDisplay' | 'type'>
  & { recipients: Array<(
    { __typename?: 'QuotePDFRecipientType' }
    & QuotePdfRecipientFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type QuotePdfRecipientFragment = (
  { __typename?: 'QuotePDFRecipientType' }
  & Pick<QuotePdfRecipientType, 'id' | 'email' | 'statusDisplay'>
);

export type QuoteOrderTypeFragment = (
  { __typename?: 'QuoteOrderTypeType' }
  & Pick<QuoteOrderTypeType, 'id' | 'name' | 'order'>
);

export type QuoteFragment = (
  { __typename?: 'QuoteType' }
  & Pick<QuoteType, 'id' | 'displayName' | 'created' | 'notes'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, products: Array<(
    { __typename?: 'QuotedProductType' }
    & MinimalQuotedProductFragment
  )>, paymentMethods: Array<(
    { __typename?: 'QuotedPaymentMethodType' }
    & QuotedPaymentMethodFragment
  )>, pdfs: Array<(
    { __typename?: 'QuotePDFType' }
    & QuotePdfFragment
  )>, contact: (
    { __typename?: 'ContactType' }
    & { account: (
      { __typename?: 'AccountType' }
      & MinimalAccountFragment
    ) }
    & MinimalContactFragment
  ), intermediary?: Maybe<(
    { __typename?: 'ContactType' }
    & { account: (
      { __typename?: 'AccountType' }
      & MinimalAccountFragment
    ) }
    & MinimalContactFragment
  )>, successChance?: Maybe<(
    { __typename?: 'SuccessChanceType' }
    & SuccessChanceFragment
  )> }
);

export type MinimalQuoteFragment = (
  { __typename?: 'QuoteType' }
  & Pick<QuoteType, 'id'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, intermediary?: Maybe<(
    { __typename?: 'ContactType' }
    & MinimalContactFragment
  )>, products: Array<(
    { __typename?: 'QuotedProductType' }
    & MinimalQuotedProductFragment
  )>, paymentMethods: Array<(
    { __typename?: 'QuotedPaymentMethodType' }
    & QuotedPaymentMethodFragment
  )> }
);

export type QuoteDetailFragment = (
  { __typename?: 'QuoteType' }
  & Pick<QuoteType, 'notes' | 'created' | 'updated'>
  & { successChance?: Maybe<(
    { __typename?: 'SuccessChanceType' }
    & SuccessChanceFragment
  )>, intermediary?: Maybe<(
    { __typename?: 'ContactType' }
    & MinimalContactFragment
  )>, orderType?: Maybe<(
    { __typename?: 'QuoteOrderTypeType' }
    & QuoteOrderTypeFragment
  )>, products: Array<(
    { __typename?: 'QuotedProductType' }
    & QuotedProductFragment
  )>, paymentMethods: Array<(
    { __typename?: 'QuotedPaymentMethodType' }
    & QuotedPaymentMethodFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, sale?: Maybe<(
    { __typename?: 'SaleType' }
    & SaleDetailFragment
  )>, comments: Array<(
    { __typename?: 'QuoteCommentType' }
    & Pick<QuoteCommentType, 'id' | 'message' | 'created'>
    & { createdBy?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'email' | 'firstName' | 'lastName'>
    )> }
  )> }
  & QuoteFragment
);

export type SaleFragment = (
  { __typename?: 'SaleType' }
  & Pick<SaleType, 'id' | 'displayName' | 'statusDisplay' | 'created'>
  & { paymentMethod: (
    { __typename?: 'QuotedPaymentMethodType' }
    & QuotedPaymentMethodFragment
  ), reviews: Array<(
    { __typename?: 'SaleReviewType' }
    & SaleReviewFragment
  )>, orderType?: Maybe<(
    { __typename?: 'QuoteOrderTypeType' }
    & QuoteOrderTypeFragment
  )>, quote: (
    { __typename?: 'QuoteType' }
    & { products: Array<(
      { __typename?: 'QuotedProductType' }
      & QuotedProductFragment
    )>, orderType?: Maybe<(
      { __typename?: 'QuoteOrderTypeType' }
      & QuoteOrderTypeFragment
    )>, contact: (
      { __typename?: 'ContactType' }
      & { account: (
        { __typename?: 'AccountType' }
        & MinimalAccountFragment
      ) }
      & MinimalContactFragment
    ) }
    & QuoteFragment
  ), products?: Maybe<Array<(
    { __typename?: 'SaleProductType' }
    & SaleProductFragment
  )>>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, administration: Array<(
    { __typename?: 'SaleAdministrationType' }
    & SaleAdministrationFragment
  )>, credits: Array<(
    { __typename?: 'CreditNoteType' }
    & { orderType?: Maybe<(
      { __typename?: 'QuoteOrderTypeType' }
      & QuoteOrderTypeFragment
    )> }
    & CreditNoteFragment
  )> }
);

export type MinimalSaleFragment = (
  { __typename?: 'SaleType' }
  & Pick<SaleType, 'id'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, paymentMethod: (
    { __typename?: 'QuotedPaymentMethodType' }
    & QuotedPaymentMethodFragment
  ), quote: (
    { __typename?: 'QuoteType' }
    & Pick<QuoteType, 'id'>
    & { products: Array<(
      { __typename?: 'QuotedProductType' }
      & Pick<QuotedProductType, 'id' | 'price' | 'quantity' | 'multiplier' | 'intermediaryCommission'>
    )> }
  ) }
);

export type NotificationFragment = (
  { __typename?: 'NotificationType' }
  & Pick<NotificationType, 'id' | 'title' | 'text' | 'created'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, sale?: Maybe<(
    { __typename?: 'SaleType' }
    & MinimalSaleFragment
  )> }
);

export type NotificationUserFragment = (
  { __typename?: 'NotificationUserType' }
  & Pick<NotificationUserType, 'id' | 'seen'>
  & { notificationId?: Maybe<(
    { __typename?: 'NotificationType' }
    & NotificationFragment
  )>, userNotified?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type UserPreferencesFragment = (
  { __typename?: 'UserPreferencesType' }
  & Pick<UserPreferencesType, 'id' | 'type' | 'frequency'>
);

export type SaleDetailFragment = (
  { __typename?: 'SaleType' }
  & Pick<SaleType, 'status' | 'updated' | 'reviewStart' | 'creditsCanReview' | 'commercialCanReview' | 'dirCommercialCanReview' | 'gteCreditsCanReview' | 'gteAdminCanReview' | 'dirManagingCanReview' | 'committeeCanReview'>
  & { updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, paymentMethod: (
    { __typename?: 'QuotedPaymentMethodType' }
    & QuotedPaymentMethodFragment
  ), quote: (
    { __typename?: 'QuoteType' }
    & { products: Array<(
      { __typename?: 'QuotedProductType' }
      & QuotedProductFragment
    )> }
    & QuoteFragment
  ), products?: Maybe<Array<(
    { __typename?: 'SaleProductType' }
    & SaleProductFragment
  )>>, administration: Array<(
    { __typename?: 'SaleAdministrationType' }
    & { productsAdmin: Array<(
      { __typename?: 'AdministrationProductType' }
      & AdministrationProductFragment
    )> }
    & SaleAdministrationFragment
  )> }
  & SaleFragment
);

export type SalePdfRecipientFragment = (
  { __typename?: 'SalePDFRecipientType' }
  & Pick<SalePdfRecipientType, 'id' | 'email' | 'statusDisplay'>
);

export type SalePdfFragment = (
  { __typename?: 'SalePDFType' }
  & Pick<SalePdfType, 'id' | 'file' | 'slug' | 'created' | 'typeDisplay' | 'type'>
  & { recipients: Array<(
    { __typename?: 'SalePDFRecipientType' }
    & SalePdfRecipientFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type SaleReviewFragment = (
  { __typename?: 'SaleReviewType' }
  & Pick<SaleReviewType, 'id' | 'statusDisplay' | 'previousTypeDisplay' | 'typeDisplay' | 'notes' | 'created'>
  & { reason: (
    { __typename?: 'ReasonType' }
    & ReasonApprovalFragment
  ), createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type SaleAdministrationFragment = (
  { __typename?: 'SaleAdministrationType' }
  & Pick<SaleAdministrationType, 'id' | 'created' | 'type' | 'typeDisplay' | 'percentage'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, productsAdmin: Array<(
    { __typename?: 'AdministrationProductType' }
    & AdministrationProductFragment
  )> }
);

export type AdministrationProductFragment = (
  { __typename?: 'AdministrationProductType' }
  & Pick<AdministrationProductType, 'id' | 'name' | 'created' | 'quantity' | 'quantityAdmin' | 'number'>
  & { unit: (
    { __typename?: 'UnitType' }
    & UnitFragment
  ) }
);

export type CreditNoteFragment = (
  { __typename?: 'CreditNoteType' }
  & Pick<CreditNoteType, 'id' | 'created' | 'notes' | 'status' | 'statusDisplay'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, orderType?: Maybe<(
    { __typename?: 'QuoteOrderTypeType' }
    & QuoteOrderTypeFragment
  )>, reviews: Array<(
    { __typename?: 'CreditNoteReviewType' }
    & CreditNoteReviewFragment
  )>, creditProducts: Array<(
    { __typename?: 'CreditProductType' }
    & CreditProductFragment
  )> }
);

export type CreditProductFragment = (
  { __typename?: 'CreditProductType' }
  & Pick<CreditProductType, 'id' | 'name' | 'quantity'>
);

export type CreditNoteReviewFragment = (
  { __typename?: 'CreditNoteReviewType' }
  & Pick<CreditNoteReviewType, 'id' | 'statusDisplay' | 'typeDisplay' | 'notes' | 'created'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type MarketSurveyFragment = (
  { __typename?: 'MarketSurveyType' }
  & Pick<MarketSurveyType, 'id' | 'price' | 'paymentMethod' | 'paymentDeadline'>
  & { formulation: (
    { __typename?: 'FormulationType' }
    & FormulationFragment
  ), laboratory: (
    { __typename?: 'LaboratoryType' }
    & LaboratoryFragment
  ) }
);

export type LaboratoryFragment = (
  { __typename?: 'LaboratoryType' }
  & Pick<LaboratoryType, 'id' | 'name'>
);

export type FormulationFragment = (
  { __typename?: 'FormulationType' }
  & Pick<FormulationType, 'id' | 'name'>
  & { activeIngredient: (
    { __typename?: 'ActiveIngredientType' }
    & ActiveIngredientFragment
  ) }
);

export type FormulationOptionFragment = (
  { __typename?: 'FormulationType' }
  & Pick<FormulationType, 'id' | 'name'>
);

export type ActiveIngredientFragment = (
  { __typename?: 'ActiveIngredientType' }
  & Pick<ActiveIngredientType, 'id' | 'name'>
);

export type ReasonApprovalFragment = (
  { __typename?: 'ReasonType' }
  & Pick<ReasonType, 'id' | 'name'>
);

export type SalesTargetFragment = (
  { __typename?: 'SalesTargetType' }
  & Pick<SalesTargetType, 'id' | 'amount' | 'saleTotal' | 'updated'>
  & { season: (
    { __typename?: 'SeasonType' }
    & SeasonFragment
  ), seller?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type SalesTargetDetailFragment = (
  { __typename?: 'SalesTargetType' }
  & Pick<SalesTargetType, 'amount' | 'created' | 'updated'>
  & { seller?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & SalesTargetFragment
);

export type SuccessChanceFragment = (
  { __typename?: 'SuccessChanceType' }
  & Pick<SuccessChanceType, 'id' | 'name'>
);

export type SeasonFragment = (
  { __typename?: 'SeasonType' }
  & Pick<SeasonType, 'id' | 'name' | 'startDate' | 'endDate'>
);

export type SummaryClientFragment = (
  { __typename?: 'SummaryClientType' }
  & Pick<SummaryClientType, 'id' | 'trade' | 'commission' | 'echeqdif' | 'colecction' | 'rural' | 'transfer' | 'echeqcurrent' | 'nc' | 'cash' | 'negative' | 'grain'>
  & { account: (
    { __typename?: 'AccountType' }
    & MinimalAccountFragment
  ) }
);

export type WeekSummaryFragment = (
  { __typename?: 'WeekSummaryType' }
  & Pick<WeekSummaryType, 'id' | 'created'>
  & { createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, summaryClient: Array<(
    { __typename?: 'SummaryClientType' }
    & SummaryClientFragment
  )>, seller?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type WeekSummaryDetailFragment = (
  { __typename?: 'WeekSummaryType' }
  & Pick<WeekSummaryType, 'created'>
  & { summaryClient: Array<(
    { __typename?: 'SummaryClientType' }
    & SummaryClientFragment
  )>, seller?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & WeekSummaryFragment
);

export type SalesTargetsPeriodsFragment = (
  { __typename?: 'SalesTargetsPeriodsType' }
  & { byPeriod: Array<(
    { __typename?: 'PeriodDatum' }
    & Pick<PeriodDatum, 'startDate' | 'endDate' | 'amountSelled' | 'amountQuoted' | 'salesGap' | 'quotesGap' | 'monthlySalesTarget'>
  )> }
);

export type GetMaxCommissionMultiplierQueryVariables = {};


export type GetMaxCommissionMultiplierQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'maxCommissionMultiplier'>
);

export type GetQuotePdfDisclaimerQueryVariables = {};


export type GetQuotePdfDisclaimerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'quotePdfDisclaimer'>
);

export type GetSalesTargetsPeriodsQueryVariables = {
  filters: SalesTargetsPeriodsFilters;
};


export type GetSalesTargetsPeriodsQuery = (
  { __typename?: 'Query' }
  & { salesTargetsPeriods?: Maybe<(
    { __typename?: 'SalesTargetsPeriodsType' }
    & SalesTargetsPeriodsFragment
  )> }
);

export type GetFormulationsQueryVariables = {};


export type GetFormulationsQuery = (
  { __typename?: 'Query' }
  & { formulations: Array<Maybe<(
    { __typename?: 'FormulationType' }
    & FormulationFragment
  )>> }
);

export type GetLaboratoryOptionsQueryVariables = {};


export type GetLaboratoryOptionsQuery = (
  { __typename?: 'Query' }
  & { laboratories: Array<Maybe<(
    { __typename?: 'LaboratoryType' }
    & LaboratoryFragment
  )>> }
);

export type GetFormulationOptionsQueryVariables = {
  activeIngredientId?: Maybe<Scalars['UUID']>;
};


export type GetFormulationOptionsQuery = (
  { __typename?: 'Query' }
  & { formulations: Array<Maybe<(
    { __typename?: 'FormulationType' }
    & FormulationOptionFragment
  )>> }
);

export type GetActiveIngredientOptionsQueryVariables = {};


export type GetActiveIngredientOptionsQuery = (
  { __typename?: 'Query' }
  & { activeIngredients: Array<Maybe<(
    { __typename?: 'ActiveIngredientType' }
    & ActiveIngredientFragment
  )>> }
);

export type GetUserQueryVariables = {};


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & { preferences: Array<(
      { __typename?: 'UserPreferencesType' }
      & UserPreferencesFragment
    )> }
    & UserFragment
  )> }
);

export type GetUsersQueryVariables = {};


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type GetChildUsersQueryVariables = {};


export type GetChildUsersQuery = (
  { __typename?: 'Query' }
  & { childUsers: Array<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
);

export type UpdateUserMutationVariables = {
  input: UpdateUserMutationInput;
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserMutationPayload' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & UserFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type LoginMutationVariables = {
  input: LoginInput;
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & UserFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type LogoutMutationVariables = {};


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'Logout' }
    & Pick<Logout, 'success'>
  )> }
);

export type ResetPasswordMutationVariables = {
  input: ResetPasswordMutationInput;
};


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SetPasswordMutationVariables = {
  input: SetPasswordMutationInput;
};


export type SetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { setPassword?: Maybe<(
    { __typename?: 'SetPasswordMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdatePasswordMutationVariables = {
  input: UpdatePasswordMutationInput;
};


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword?: Maybe<(
    { __typename?: 'UpdatePasswordMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetAccountsQueryVariables = {
  options?: Maybe<AccountListOptionsInputType>;
};


export type GetAccountsQuery = (
  { __typename?: 'Query' }
  & { accounts: (
    { __typename?: 'AccountListType' }
    & Pick<AccountListType, 'count'>
    & { items: Array<(
      { __typename?: 'AccountType' }
      & AccountFragment
    )> }
  ) }
);

export type GetAccountOptionsQueryVariables = {};


export type GetAccountOptionsQuery = (
  { __typename?: 'Query' }
  & { accounts: (
    { __typename?: 'AccountListType' }
    & { items: Array<(
      { __typename?: 'AccountType' }
      & AccountOptionFragment
    )> }
  ) }
);

export type GetAccountQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'AccountType' }
    & AccountDetailFragment
  ) }
);

export type CreateAccountMutationVariables = {
  input: CreateAccountMutationInput;
};


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount?: Maybe<(
    { __typename?: 'CreateAccountMutationPayload' }
    & { account?: Maybe<(
      { __typename?: 'AccountType' }
      & AccountDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateAccountMutationVariables = {
  input: UpdateAccountMutationInput;
};


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount?: Maybe<(
    { __typename?: 'UpdateAccountMutationPayload' }
    & { account?: Maybe<(
      { __typename?: 'AccountType' }
      & AccountDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteAccountMutationVariables = {
  input: DeleteAccountMutationInput;
};


export type DeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccount?: Maybe<(
    { __typename?: 'DeleteAccountMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetAccountTypesQueryVariables = {};


export type GetAccountTypesQuery = (
  { __typename?: 'Query' }
  & { accountTypes: Array<Maybe<(
    { __typename?: 'AccountTypeType' }
    & AccountTypeOptionFragment
  )>> }
);

export type GetAccountRegionsQueryVariables = {};


export type GetAccountRegionsQuery = (
  { __typename?: 'Query' }
  & { accountRegions: Array<Maybe<(
    { __typename?: 'AccountRegionType' }
    & AccountRegionOptionFragment
  )>> }
);

export type GetAccountCountriesQueryVariables = {};


export type GetAccountCountriesQuery = (
  { __typename?: 'Query' }
  & { accountCountries: Array<Maybe<(
    { __typename?: 'AccountCountryType' }
    & AccountCountryOptionFragment
  )>> }
);

export type GetAccountRegionsByCountryQueryVariables = {
  countryId?: Maybe<Scalars['UUID']>;
};


export type GetAccountRegionsByCountryQuery = (
  { __typename?: 'Query' }
  & { accountRegionsByCountry: Array<Maybe<(
    { __typename?: 'AccountRegionType' }
    & AccountRegionOptionFragment
  )>> }
);

export type GetAccountCountryQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type GetAccountCountryQuery = (
  { __typename?: 'Query' }
  & { accountCountry: (
    { __typename?: 'AccountCountryType' }
    & Pick<AccountCountryType, 'id' | 'name' | 'isoCode'>
  ) }
);

export type GetProductsSalesQueryVariables = {
  user?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
};


export type GetProductsSalesQuery = (
  { __typename?: 'Query' }
  & { productsSales: Array<Maybe<(
    { __typename?: 'ProductFiveRanking' }
    & ProductFiveRankingFragment
  )>> }
);

export type GetAmountByAccountQueryVariables = {
  user?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
};


export type GetAmountByAccountQuery = (
  { __typename?: 'Query' }
  & { amountByAccount: Array<Maybe<(
    { __typename?: 'AccountRanking' }
    & AccountRankingFragment
  )>> }
);

export type GetTotalSalesPeriodQueryVariables = {
  user: Scalars['String'];
  period?: Maybe<Scalars['String']>;
};


export type GetTotalSalesPeriodQuery = (
  { __typename?: 'Query' }
  & { totalSalesPeriod: (
    { __typename?: 'TotalSales' }
    & TotalSalesFragment
  ) }
);

export type GetContactsQueryVariables = {
  options?: Maybe<ContactListOptionsInputType>;
};


export type GetContactsQuery = (
  { __typename?: 'Query' }
  & { contacts: (
    { __typename?: 'ContactListType' }
    & Pick<ContactListType, 'count'>
    & { items: Array<(
      { __typename?: 'ContactType' }
      & ContactFragment
    )> }
  ) }
);

export type GetContactOptionsQueryVariables = {
  options?: Maybe<ContactListOptionsInputType>;
};


export type GetContactOptionsQuery = (
  { __typename?: 'Query' }
  & { contacts: (
    { __typename?: 'ContactListType' }
    & Pick<ContactListType, 'count'>
    & { items: Array<(
      { __typename?: 'ContactType' }
      & ContactOptionFragment
    )> }
  ) }
);

export type GetIntermediariesQueryVariables = {};


export type GetIntermediariesQuery = (
  { __typename?: 'Query' }
  & { intermediaries: Array<Maybe<(
    { __typename?: 'ContactType' }
    & Pick<ContactType, 'id' | 'displayName' | 'email'>
  )>> }
);

export type GetContactAccountQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type GetContactAccountQuery = (
  { __typename?: 'Query' }
  & { contact: (
    { __typename?: 'ContactType' }
    & { account: (
      { __typename?: 'AccountType' }
      & Pick<AccountType, 'id'>
    ) }
  ) }
);

export type GetContactQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { contact: (
    { __typename?: 'ContactType' }
    & ContactDetailFragment
  ) }
);

export type CreateContactMutationVariables = {
  input: CreateContactMutationInput;
};


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact?: Maybe<(
    { __typename?: 'CreateContactMutationPayload' }
    & { contact?: Maybe<(
      { __typename?: 'ContactType' }
      & ContactDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateContactMutationVariables = {
  input: UpdateContactMutationInput;
};


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'UpdateContactMutationPayload' }
    & { contact?: Maybe<(
      { __typename?: 'ContactType' }
      & ContactDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteContactMutationVariables = {
  input: DeleteContactMutationInput;
};


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact?: Maybe<(
    { __typename?: 'DeleteContactMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetContactTypesQueryVariables = {};


export type GetContactTypesQuery = (
  { __typename?: 'Query' }
  & { contactTypes: Array<Maybe<(
    { __typename?: 'ContactTypeType' }
    & ContactTypeOptionFragment
  )>> }
);

export type CreateNotificationMutationVariables = {
  input: CreateNotificationMutationInput;
};


export type CreateNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createNotification?: Maybe<(
    { __typename?: 'CreateNotificationMutationPayload' }
    & { notification?: Maybe<(
      { __typename?: 'NotificationType' }
      & NotificationFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateNotificationUserMutationVariables = {
  input: UpdateNotificationUserMutationInput;
};


export type UpdateNotificationUserMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationUser?: Maybe<(
    { __typename?: 'UpdateNotificationUserMutationPayload' }
    & { notificationUser?: Maybe<(
      { __typename?: 'NotificationUserType' }
      & NotificationUserFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateNotificationAllMutationVariables = {
  input: UpdateNotificationAllMutationInput;
};


export type UpdateNotificationAllMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationAll?: Maybe<(
    { __typename?: 'UpdateNotificationAllMutationPayload' }
    & { notificationUser?: Maybe<(
      { __typename?: 'NotificationUserType' }
      & NotificationUserFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetNotificationsQueryVariables = {};


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<Maybe<(
    { __typename?: 'NotificationType' }
    & NotificationFragment
  )>> }
);

export type GetNotificationsUserQueryVariables = {};


export type GetNotificationsUserQuery = (
  { __typename?: 'Query' }
  & { notificationsUser?: Maybe<Array<Maybe<(
    { __typename?: 'NotificationUserType' }
    & NotificationUserFragment
  )>>> }
);

export type CreateUserPreferencesMutationVariables = {
  input: CreateUserPreferencesMutationInput;
};


export type CreateUserPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { createUserPreferences?: Maybe<(
    { __typename?: 'CreateUserPreferencesMutationPayload' }
    & { userPreferences?: Maybe<(
      { __typename?: 'UserPreferencesType' }
      & { user?: Maybe<(
        { __typename?: 'UserType' }
        & MinimalUserFragment
      )> }
      & UserPreferencesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateUserPreferencesMutationVariables = {
  input: UpdateUserPreferencesMutationInput;
};


export type UpdateUserPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPreferences?: Maybe<(
    { __typename?: 'UpdateUserPreferencesMutationPayload' }
    & { userPreferences?: Maybe<(
      { __typename?: 'UserPreferencesType' }
      & { user?: Maybe<(
        { __typename?: 'UserType' }
        & MinimalUserFragment
      )> }
      & UserPreferencesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetOpportunitiesQueryVariables = {
  options?: Maybe<OpportunityListOptionsInputType>;
};


export type GetOpportunitiesQuery = (
  { __typename?: 'Query' }
  & { opportunities: (
    { __typename?: 'OpportunityListType' }
    & Pick<OpportunityListType, 'count'>
    & { items: Array<(
      { __typename?: 'OpportunityType' }
      & OpportunityFragment
    )> }
  ) }
);

export type GetOpportunityQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type GetOpportunityQuery = (
  { __typename?: 'Query' }
  & { opportunity: (
    { __typename?: 'OpportunityType' }
    & OpportunityDetailFragment
  ) }
);

export type CreateOpportunityMutationVariables = {
  input: CreateOpportunityMutationInput;
};


export type CreateOpportunityMutation = (
  { __typename?: 'Mutation' }
  & { createOpportunity?: Maybe<(
    { __typename?: 'CreateOpportunityMutationPayload' }
    & { opportunity?: Maybe<(
      { __typename?: 'OpportunityType' }
      & OpportunityDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateOpportunityMutationVariables = {
  input: UpdateOpportunityMutationInput;
};


export type UpdateOpportunityMutation = (
  { __typename?: 'Mutation' }
  & { updateOpportunity?: Maybe<(
    { __typename?: 'UpdateOpportunityMutationPayload' }
    & { opportunity?: Maybe<(
      { __typename?: 'OpportunityType' }
      & OpportunityDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteOpportunityMutationVariables = {
  input: DeleteOpportunityMutationInput;
};


export type DeleteOpportunityMutation = (
  { __typename?: 'Mutation' }
  & { deleteOpportunity?: Maybe<(
    { __typename?: 'DeleteOpportunityMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SetOpportunityReadMutationVariables = {
  input: SetOpportunityReadMutationInput;
};


export type SetOpportunityReadMutation = (
  { __typename?: 'Mutation' }
  & { setOpportunityRead?: Maybe<(
    { __typename?: 'SetOpportunityReadMutationPayload' }
    & { opportunity?: Maybe<(
      { __typename?: 'OpportunityType' }
      & Pick<OpportunityType, 'read'>
      & { readBy?: Maybe<(
        { __typename?: 'UserType' }
        & MinimalUserFragment
      )> }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetOpportunityChannelsQueryVariables = {};


export type GetOpportunityChannelsQuery = (
  { __typename?: 'Query' }
  & { opportunityChannels: Array<Maybe<(
    { __typename?: 'OpportunityChannelType' }
    & OpportunityChannelOptionFragment
  )>> }
);

export type GetOpportunityReasonsQueryVariables = {};


export type GetOpportunityReasonsQuery = (
  { __typename?: 'Query' }
  & { opportunityReasons: Array<Maybe<(
    { __typename?: 'OpportunityReasonType' }
    & OpportunityReasonOptionFragment
  )>> }
);

export type GetOpportunitySituationsQueryVariables = {};


export type GetOpportunitySituationsQuery = (
  { __typename?: 'Query' }
  & { opportunitySituations: Array<Maybe<(
    { __typename?: 'OpportunitySituationType' }
    & OpportunitySituationOptionFragment
  )>> }
);

export type GetProductQueryVariables = {
  productId?: Maybe<Scalars['UUID']>;
};


export type GetProductQuery = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'ProductType' }
    & ProductFragment
  ) }
);

export type GetProductByExternalIdQueryVariables = {
  externalId?: Maybe<Scalars['String']>;
};


export type GetProductByExternalIdQuery = (
  { __typename?: 'Query' }
  & { productByExternalId: (
    { __typename?: 'ProductType' }
    & ProductFragment
  ) }
);

export type GetProductsByExternalIdQueryVariables = {
  externalIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetProductsByExternalIdQuery = (
  { __typename?: 'Query' }
  & { someProductsByExternalId: Array<Maybe<(
    { __typename?: 'ProductType' }
    & ProductFragment
  )>> }
);

export type GetProductsQueryVariables = {};


export type GetProductsQuery = (
  { __typename?: 'Query' }
  & { products: Array<Maybe<(
    { __typename?: 'ProductType' }
    & ProductFragment
  )>> }
);

export type GetProductsCurrentQueryVariables = {
  options?: Maybe<ProductCurrentOptionsInputType>;
};


export type GetProductsCurrentQuery = (
  { __typename?: 'Query' }
  & { productsCurrent: (
    { __typename?: 'ProductCurrentType' }
    & Pick<ProductCurrentType, 'count'>
    & { items: Array<(
      { __typename?: 'ProductType' }
      & ProductFragment
    )> }
  ) }
);

export type GetProductsByCountryQueryVariables = {
  countryId?: Maybe<Scalars['UUID']>;
};


export type GetProductsByCountryQuery = (
  { __typename?: 'Query' }
  & { productsByCountry: Array<Maybe<(
    { __typename?: 'ProductType' }
    & ProductOptionFragment
  )>> }
);

export type GetPromotionsQueryVariables = {};


export type GetPromotionsQuery = (
  { __typename?: 'Query' }
  & { promotions: Array<Maybe<(
    { __typename?: 'PromotionType' }
    & PromotionFragment
  )>> }
);

export type GetProductOptionsQueryVariables = {};


export type GetProductOptionsQuery = (
  { __typename?: 'Query' }
  & { products: Array<Maybe<(
    { __typename?: 'ProductType' }
    & ProductOptionFragment
  )>> }
);

export type GetProductListsQueryVariables = {};


export type GetProductListsQuery = (
  { __typename?: 'Query' }
  & { productLists: Array<Maybe<(
    { __typename?: 'ProductListType' }
    & ProductListFragment
  )>> }
);

export type GetUnitsQueryVariables = {};


export type GetUnitsQuery = (
  { __typename?: 'Query' }
  & { units: Array<Maybe<(
    { __typename?: 'UnitType' }
    & UnitFragment
  )>> }
);

export type GetQuotesQueryVariables = {
  options?: Maybe<QuoteListOptionsInputType>;
};


export type GetQuotesQuery = (
  { __typename?: 'Query' }
  & { quotes: (
    { __typename?: 'QuoteListType' }
    & Pick<QuoteListType, 'count'>
    & { items: Array<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )> }
  ) }
);

export type GetQuoteQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { quote: (
    { __typename?: 'QuoteType' }
    & QuoteDetailFragment
  ) }
);

export type GetQuotesUserQueryVariables = {
  user?: Maybe<Scalars['String']>;
};


export type GetQuotesUserQuery = (
  { __typename?: 'Query' }
  & { quotesUser: Array<Maybe<(
    { __typename?: 'QuoteUserListType' }
    & Pick<QuoteUserListType, 'count'>
    & { items: Array<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )> }
  )>> }
);

export type CreateQuoteMutationVariables = {
  input: CreateQuoteMutationInput;
};


export type CreateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { createQuote?: Maybe<(
    { __typename?: 'CreateQuoteMutationPayload' }
    & { quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateQuoteMutationVariables = {
  input: UpdateQuoteMutationInput;
};


export type UpdateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { updateQuote?: Maybe<(
    { __typename?: 'UpdateQuoteMutationPayload' }
    & { quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteQuoteMutationVariables = {
  input: DeleteQuoteMutationInput;
};


export type DeleteQuoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuote?: Maybe<(
    { __typename?: 'DeleteQuoteMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type CreateQuoteCommentMutationVariables = {
  input: CreateQuoteCommentMutationInput;
};


export type CreateQuoteCommentMutation = (
  { __typename?: 'Mutation' }
  & { createQuoteComment?: Maybe<(
    { __typename?: 'CreateQuoteCommentMutationPayload' }
    & { quoteComment?: Maybe<(
      { __typename?: 'QuoteCommentType' }
      & Pick<QuoteCommentType, 'id' | 'message' | 'created'>
      & { createdBy?: Maybe<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'firstName' | 'lastName' | 'id'>
      )> }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteQuoteCommentMutationVariables = {
  input: DeleteQuoteCommentMutationInput;
};


export type DeleteQuoteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuoteComment?: Maybe<(
    { __typename?: 'DeleteQuoteCommentMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type CreateQuotePdfMutationVariables = {
  input: CreateQuotePdfMutationInput;
};


export type CreateQuotePdfMutation = (
  { __typename?: 'Mutation' }
  & { createQuotePDF?: Maybe<(
    { __typename?: 'CreateQuotePDFMutationPayload' }
    & { quotePDF?: Maybe<(
      { __typename?: 'QuotePDFType' }
      & Pick<QuotePdfType, 'file' | 'slug'>
      & { quote: (
        { __typename?: 'QuoteType' }
        & QuoteDetailFragment
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetQuoteOrderTypesQueryVariables = {};


export type GetQuoteOrderTypesQuery = (
  { __typename?: 'Query' }
  & { quoteOrderTypes: Array<Maybe<(
    { __typename?: 'QuoteOrderTypeType' }
    & QuoteOrderTypeFragment
  )>> }
);

export type GetPaymentMethodsQueryVariables = {};


export type GetPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Array<Maybe<(
    { __typename?: 'PaymentMethodType' }
    & PaymentMethodFragment
  )>> }
);

export type GetPaymentMethodOptionsQueryVariables = {};


export type GetPaymentMethodOptionsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Array<Maybe<(
    { __typename?: 'PaymentMethodType' }
    & PaymentMethodOptionFragment
  )>> }
);

export type GetSalesTargetUserQueryVariables = {
  user: Scalars['String'];
};


export type GetSalesTargetUserQuery = (
  { __typename?: 'Query' }
  & { salesTargetUser: (
    { __typename?: 'SalesTargetType' }
    & SalesTargetDetailFragment
  ) }
);

export type GetSalesTargetsQueryVariables = {
  options?: Maybe<SalesTargetListOptionsInputType>;
};


export type GetSalesTargetsQuery = (
  { __typename?: 'Query' }
  & { salesTargets: (
    { __typename?: 'SalesTargetListType' }
    & Pick<SalesTargetListType, 'count'>
    & { items: Array<(
      { __typename?: 'SalesTargetType' }
      & SalesTargetFragment
    )> }
  ) }
);

export type CreateSalesTargetMutationVariables = {
  input: CreateSalesTargetMutationInput;
};


export type CreateSalesTargetMutation = (
  { __typename?: 'Mutation' }
  & { createSalesTarget?: Maybe<(
    { __typename?: 'CreateSalesTargetMutationPayload' }
    & { salesTarget?: Maybe<(
      { __typename?: 'SalesTargetType' }
      & SalesTargetDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateSalesTargetMutationVariables = {
  input: UpdateSalesTargetMutationInput;
};


export type UpdateSalesTargetMutation = (
  { __typename?: 'Mutation' }
  & { updateSalesTarget?: Maybe<(
    { __typename?: 'UpdateSalesTargetMutationPayload' }
    & { salesTarget?: Maybe<(
      { __typename?: 'SalesTargetType' }
      & SalesTargetDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteSalesTargetMutationVariables = {
  input: DeleteSalesTargetMutationInput;
};


export type DeleteSalesTargetMutation = (
  { __typename?: 'Mutation' }
  & { deleteSalesTarget?: Maybe<(
    { __typename?: 'DeleteSalesTargetMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type StartSaleReviewMutationVariables = {
  input: StartSaleReviewMutationInput;
};


export type StartSaleReviewMutation = (
  { __typename?: 'Mutation' }
  & { startSaleReview?: Maybe<(
    { __typename?: 'StartSaleReviewMutationPayload' }
    & { sale?: Maybe<(
      { __typename?: 'SaleType' }
      & SaleDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type ReviewSaleMutationVariables = {
  input: SaleReviewMutationInput;
};


export type ReviewSaleMutation = (
  { __typename?: 'Mutation' }
  & { reviewSale?: Maybe<(
    { __typename?: 'SaleReviewMutationPayload' }
    & { saleReview?: Maybe<(
      { __typename?: 'SaleReviewType' }
      & { sale: (
        { __typename?: 'SaleType' }
        & SaleDetailFragment
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type CreateSaleAdministrationMutationVariables = {
  input: CreateSaleAdministrationMutationInput;
};


export type CreateSaleAdministrationMutation = (
  { __typename?: 'Mutation' }
  & { createSaleAdministration?: Maybe<(
    { __typename?: 'CreateSaleAdministrationMutationPayload' }
    & { saleAdministration?: Maybe<(
      { __typename?: 'SaleAdministrationType' }
      & { sale: (
        { __typename?: 'SaleType' }
        & SaleDetailFragment
      ) }
      & SaleAdministrationFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type CreateCreditNoteMutationVariables = {
  input: CreateCreditNotesMutationInput;
};


export type CreateCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { createCreditNote?: Maybe<(
    { __typename?: 'CreateCreditNotesMutationPayload' }
    & { creditNote?: Maybe<(
      { __typename?: 'CreditNoteType' }
      & { sale: (
        { __typename?: 'SaleType' }
        & SaleDetailFragment
      ), orderType?: Maybe<(
        { __typename?: 'QuoteOrderTypeType' }
        & QuoteOrderTypeFragment
      )> }
      & CreditNoteFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteCreditNoteMutationVariables = {
  input: DeleteCreditNoteMutationInput;
};


export type DeleteCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteCreditNote?: Maybe<(
    { __typename?: 'DeleteCreditNoteMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateCreditNoteMutationVariables = {
  input: UpdateCreditNoteMutationInput;
};


export type UpdateCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditNote?: Maybe<(
    { __typename?: 'UpdateCreditNoteMutationPayload' }
    & { creditNote?: Maybe<(
      { __typename?: 'CreditNoteType' }
      & { sale: (
        { __typename?: 'SaleType' }
        & SaleDetailFragment
      ), orderType?: Maybe<(
        { __typename?: 'QuoteOrderTypeType' }
        & QuoteOrderTypeFragment
      )> }
      & CreditNoteFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type CreateCreditNoteReviewMutationVariables = {
  input: CreateCreditNoteReviewMutationInput;
};


export type CreateCreditNoteReviewMutation = (
  { __typename?: 'Mutation' }
  & { createCreditNoteReview?: Maybe<(
    { __typename?: 'CreateCreditNoteReviewMutationPayload' }
    & { creditNoteReview?: Maybe<(
      { __typename?: 'CreditNoteReviewType' }
      & { creditNotes: (
        { __typename?: 'CreditNoteType' }
        & CreditNoteFragment
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteSaleAdministrationMutationVariables = {
  input: DeleteSaleAdministrationMutationInput;
};


export type DeleteSaleAdministrationMutation = (
  { __typename?: 'Mutation' }
  & { deleteSaleAdministration?: Maybe<(
    { __typename?: 'DeleteSaleAdministrationMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateSaleAdministrationMutationVariables = {
  input: UpdateSaleAdministrationMutationInput;
};


export type UpdateSaleAdministrationMutation = (
  { __typename?: 'Mutation' }
  & { updateSaleAdministration?: Maybe<(
    { __typename?: 'UpdateSaleAdministrationMutationPayload' }
    & { saleAdministration?: Maybe<(
      { __typename?: 'SaleAdministrationType' }
      & { sale: (
        { __typename?: 'SaleType' }
        & SaleDetailFragment
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetSuccessChanceOptionsQueryVariables = {};


export type GetSuccessChanceOptionsQuery = (
  { __typename?: 'Query' }
  & { successChances?: Maybe<Array<Maybe<(
    { __typename?: 'SuccessChanceType' }
    & Pick<SuccessChanceType, 'id' | 'name'>
  )>>> }
);

export type GetSalesApprovedQueryVariables = {
  options?: Maybe<SalesApprovedListOptionsInputType>;
};


export type GetSalesApprovedQuery = (
  { __typename?: 'Query' }
  & { salesApproved: (
    { __typename?: 'SaleApprovedListType' }
    & Pick<SaleApprovedListType, 'count'>
    & { items: Array<(
      { __typename?: 'SaleType' }
      & SaleFragment
    )> }
  ) }
);
export type GetSalesApprovedAllQueryVariables = {
  options?: Maybe<SalesApprovedListOptionsInputType>;
};


export type GetSalesApprovedAllQuery = (
  { __typename?: 'Query' }
  & { salesApprovedAll: (
    { __typename?: 'SaleApprovedListType' }
    & Pick<SaleApprovedListType, 'count'>
    & { items: Array<(
      { __typename?: 'SaleType' }
      & SaleFragment
    )> }
  ) }
);

export type GetSalesCreditsAllQueryVariables = {
  options?: Maybe<SalesCreditsListOptionsInputType>;
};


export type GetSalesCreditsAllQuery = (
  { __typename?: 'Query' }
  & { salesCreditsAll: (
    { __typename?: 'SaleCreditsListType' }
    & Pick<SaleCreditsListType, 'count'>
    & { items: Array<(
      { __typename?: 'SaleType' }
      & SaleFragment
    )> }
  ) }
);

export type GetReasonApprovalQueryVariables = {};


export type GetReasonApprovalQuery = (
  { __typename?: 'Query' }
  & { reasonApproval: Array<Maybe<(
    { __typename?: 'ReasonType' }
    & ReasonApprovalFragment
  )>> }
);

export type GetSalesQueryVariables = {
  options?: Maybe<SaleListOptionsInputType>;
};


export type GetSalesQuery = (
  { __typename?: 'Query' }
  & { sales: (
    { __typename?: 'SaleListType' }
    & Pick<SaleListType, 'count'>
    & { items: Array<(
      { __typename?: 'SaleType' }
      & SaleFragment
    )> }
  ) }
);

export type GetSalesUserQueryVariables = {
  user?: Maybe<Scalars['String']>;
};


export type GetSalesUserQuery = (
  { __typename?: 'Query' }
  & { salesUser: (
    { __typename?: 'SaleUserListType' }
    & Pick<SaleUserListType, 'count'>
    & { items: Array<(
      { __typename?: 'SaleType' }
      & SaleFragment
    )> }
  ) }
);

export type GetSaleQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type GetSaleQuery = (
  { __typename?: 'Query' }
  & { sale: (
    { __typename?: 'SaleType' }
    & SaleDetailFragment
  ) }
);

export type CreateSaleMutationVariables = {
  input: CreateSaleMutationInput;
};


export type CreateSaleMutation = (
  { __typename?: 'Mutation' }
  & { createSale?: Maybe<(
    { __typename?: 'CreateSaleMutationPayload' }
    & { sale?: Maybe<(
      { __typename?: 'SaleType' }
      & SaleDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateSaleMutationVariables = {
  input: UpdateSaleMutationInput;
};


export type UpdateSaleMutation = (
  { __typename?: 'Mutation' }
  & { updateSale?: Maybe<(
    { __typename?: 'UpdateSaleMutationPayload' }
    & { sale?: Maybe<(
      { __typename?: 'SaleType' }
      & SaleDetailFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type DeleteSaleMutationVariables = {
  input: DeleteSaleMutationInput;
};


export type DeleteSaleMutation = (
  { __typename?: 'Mutation' }
  & { deleteSale?: Maybe<(
    { __typename?: 'DeleteSaleMutationPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type CreateSalePdfMutationVariables = {
  input: CreateSalePdfMutationInput;
};


export type CreateSalePdfMutation = (
  { __typename?: 'Mutation' }
  & { createSalePDF?: Maybe<(
    { __typename?: 'CreateSalePDFMutationPayload' }
    & { salePDF?: Maybe<(
      { __typename?: 'SalePDFType' }
      & Pick<SalePdfType, 'file' | 'slug'>
      & { sale: (
        { __typename?: 'SaleType' }
        & SaleDetailFragment
      ) }
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type GetSeasonsQueryVariables = {};


export type GetSeasonsQuery = (
  { __typename?: 'Query' }
  & { seasons: Array<Maybe<(
    { __typename?: 'SeasonType' }
    & SeasonFragment
  )>> }
);

export type GetWeekSummaryQueryVariables = {
  seller?: Maybe<Scalars['String']>;
};


export type GetWeekSummaryQuery = (
  { __typename?: 'Query' }
  & { weekSummary: (
    { __typename?: 'WeekSummaryType' }
    & WeekSummaryDetailFragment
  ) }
);

export const OpportunitiesAnalyticsFragmentDoc = gql`
    fragment OpportunitiesAnalytics on OpportunitiesAnalyticsType {
  count
  byChannel {
    label
    count
    percentage
  }
  byReason {
    label
    count
    percentage
  }
  bySituation {
    label
    count
    percentage
  }
  byDate {
    date
    count
  }
  productRanking {
    product {
      name
    }
    count
    ranking
  }
  marketSurveyAverages {
    activeIngredient {
      id
      name
    }
    count
    averagePrice
    averagePaymentDeadline
  }
}
    `;
export const QuotesAnalyticsFragmentDoc = gql`
    fragment QuotesAnalytics on QuotesAnalyticsType {
  count
  amount
  liters
  byDate {
    date
    count
    amount
  }
  byAccountType {
    label
    count
    percentage
    amount
  }
  byClient {
    label
    count
    percentage
    amount
  }
  byPaymentMethod {
    label
    count
    percentage
    amount
  }
  productRanking {
    product {
      name
    }
    count
    ranking
    liters
  }
  productList {
    label
    count
    percentage
    amount
  }
}
    `;
export const SalesAnalyticsFragmentDoc = gql`
    fragment SalesAnalytics on SalesAnalyticsType {
  count
  amount
  liters
  byDate {
    date
    count
    amount
  }
  byAccountType {
    label
    count
    percentage
    amount
  }
  byClient {
    label
    count
    percentage
    amount
  }
  productRanking {
    product {
      name
    }
    count
    ranking
    liters
  }
  byPaymentMethod {
    label
    count
    percentage
    amount
  }
  productList {
    label
    count
    percentage
    amount
  }
}
    `;
export const UserPreferencesFragmentDoc = gql`
    fragment UserPreferences on UserPreferencesType {
  id
  type
  frequency
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UserType {
  id
  email
  firstName
  lastName
  displayName
  isActive
  isStaff
  dateJoined
  onboarding
  lastLogin
  permissions {
    assignAccountOwner
    requestSaleReview
    submitCommercialSaleReview
    submitDirCommercialSaleReview
    submitCreditsSaleReview
    submitGCreditsSaleReview
    submitGAdminSaleReview
    submitDirManagingSaleReview
    submitCommitteeSaleReview
    viewAnalytics
    markOpportunitiesAsRead
    createSalesTargets
    viewSalesTargetsForChildUsers
    adminProcess
    createPaymentMethod
  }
  role {
    name
  }
  preferences {
    ...UserPreferences
  }
}
    ${UserPreferencesFragmentDoc}`;
export const AccountRankingFragmentDoc = gql`
    fragment AccountRanking on AccountRanking {
  account
  total
}
    `;
export const TotalSalesFragmentDoc = gql`
    fragment TotalSales on TotalSales {
  total
}
    `;
export const MinimalAccountFragmentDoc = gql`
    fragment MinimalAccount on AccountType {
  id
  name
  taxId
  externalId
}
    `;
export const MinimalUserFragmentDoc = gql`
    fragment MinimalUser on UserType {
  id
  displayName
  role {
    name
  }
}
    `;
export const AccountTypeOptionFragmentDoc = gql`
    fragment AccountTypeOption on AccountTypeType {
  id
  name
}
    `;
export const AccountRegionOptionFragmentDoc = gql`
    fragment AccountRegionOption on AccountRegionType {
  id
  name
}
    `;
export const AccountCountryOptionFragmentDoc = gql`
    fragment AccountCountryOption on AccountCountryType {
  id
  name
}
    `;
export const AccountFragmentDoc = gql`
    fragment Account on AccountType {
  ...MinimalAccount
  owner {
    ...MinimalUser
  }
  type {
    ...AccountTypeOption
  }
  region {
    ...AccountRegionOption
  }
  country {
    ...AccountCountryOption
  }
}
    ${MinimalAccountFragmentDoc}
${MinimalUserFragmentDoc}
${AccountTypeOptionFragmentDoc}
${AccountRegionOptionFragmentDoc}
${AccountCountryOptionFragmentDoc}`;
export const MinimalContactFragmentDoc = gql`
    fragment MinimalContact on ContactType {
  id
  email
  displayName
}
    `;
export const ContactTypeOptionFragmentDoc = gql`
    fragment ContactTypeOption on ContactTypeType {
  id
  name
}
    `;
export const AccountOptionFragmentDoc = gql`
    fragment AccountOption on AccountType {
  id
  name
}
    `;
export const OpportunityChannelOptionFragmentDoc = gql`
    fragment OpportunityChannelOption on OpportunityChannelType {
  id
  name
}
    `;
export const OpportunityReasonOptionFragmentDoc = gql`
    fragment OpportunityReasonOption on OpportunityReasonType {
  id
  name
}
    `;
export const OpportunitySituationOptionFragmentDoc = gql`
    fragment OpportunitySituationOption on OpportunitySituationType {
  id
  name
}
    `;
export const ProductListFragmentDoc = gql`
    fragment ProductList on ProductListType {
  id
  name
  order
}
    `;
export const UnitFragmentDoc = gql`
    fragment Unit on UnitType {
  id
  name
  symbol
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductType {
  id
  externalId
  name
  price
  composition
  presentation
  list {
    ...ProductList
  }
  unit {
    ...Unit
  }
  yieldField
  published
}
    ${ProductListFragmentDoc}
${UnitFragmentDoc}`;
export const PromotionFragmentDoc = gql`
    fragment Promotion on PromotionType {
  id
  name
}
    `;
export const ContactOptionFragmentDoc = gql`
    fragment ContactOption on ContactType {
  id
  email
  displayName
}
    `;
export const ActiveIngredientFragmentDoc = gql`
    fragment ActiveIngredient on ActiveIngredientType {
  id
  name
}
    `;
export const FormulationFragmentDoc = gql`
    fragment Formulation on FormulationType {
  id
  name
  activeIngredient {
    ...ActiveIngredient
  }
}
    ${ActiveIngredientFragmentDoc}`;
export const LaboratoryFragmentDoc = gql`
    fragment Laboratory on LaboratoryType {
  id
  name
}
    `;
export const MarketSurveyFragmentDoc = gql`
    fragment MarketSurvey on MarketSurveyType {
  id
  formulation {
    ...Formulation
  }
  laboratory {
    ...Laboratory
  }
  price
  paymentMethod
  paymentDeadline
}
    ${FormulationFragmentDoc}
${LaboratoryFragmentDoc}`;
export const OpportunityFragmentDoc = gql`
    fragment Opportunity on OpportunityType {
  id
  date
  displayName
  createdBy {
    ...MinimalUser
  }
  channel {
    ...OpportunityChannelOption
  }
  reason {
    ...OpportunityReasonOption
  }
  situation {
    ...OpportunitySituationOption
  }
  notes
  notesTracking
  productsLegacy
  products {
    ...Product
  }
  promotions {
    ...Promotion
  }
  marketingActions
  contact {
    ...ContactOption
    account {
      ...MinimalAccount
    }
  }
  marketSurveys {
    ...MarketSurvey
  }
  read
  readBy {
    ...MinimalUser
  }
}
    ${MinimalUserFragmentDoc}
${OpportunityChannelOptionFragmentDoc}
${OpportunityReasonOptionFragmentDoc}
${OpportunitySituationOptionFragmentDoc}
${ProductFragmentDoc}
${PromotionFragmentDoc}
${ContactOptionFragmentDoc}
${MinimalAccountFragmentDoc}
${MarketSurveyFragmentDoc}`;
export const MinimalQuotedProductFragmentDoc = gql`
    fragment MinimalQuotedProduct on QuotedProductType {
  id
  name
  composition
  presentation
  externalId
  price
  multiplier
  intermediaryCommission
  quantity
  unit {
    id
  }
  list {
    id
  }
  yieldField
  deliveryPeriod
}
    `;
export const QuotedPaymentMethodFragmentDoc = gql`
    fragment QuotedPaymentMethod on QuotedPaymentMethodType {
  id
  name
  multiplier
  order
  isCustom
}
    `;
export const QuotePdfRecipientFragmentDoc = gql`
    fragment QuotePDFRecipient on QuotePDFRecipientType {
  id
  email
  statusDisplay
}
    `;
export const QuotePdfFragmentDoc = gql`
    fragment QuotePDF on QuotePDFType {
  id
  file
  recipients {
    ...QuotePDFRecipient
  }
  created
  slug
  typeDisplay
  type
  createdBy {
    ...MinimalUser
  }
}
    ${QuotePdfRecipientFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SuccessChanceFragmentDoc = gql`
    fragment SuccessChance on SuccessChanceType {
  id
  name
}
    `;
export const QuoteFragmentDoc = gql`
    fragment Quote on QuoteType {
  id
  displayName
  createdBy {
    ...MinimalUser
  }
  products {
    ...MinimalQuotedProduct
  }
  paymentMethods {
    ...QuotedPaymentMethod
  }
  pdfs {
    ...QuotePDF
  }
  contact {
    ...MinimalContact
    account {
      ...MinimalAccount
    }
  }
  intermediary {
    ...MinimalContact
    account {
      ...MinimalAccount
    }
  }
  created
  notes
  successChance {
    ...SuccessChance
  }
}
    ${MinimalUserFragmentDoc}
${MinimalQuotedProductFragmentDoc}
${QuotedPaymentMethodFragmentDoc}
${QuotePdfFragmentDoc}
${MinimalContactFragmentDoc}
${MinimalAccountFragmentDoc}
${SuccessChanceFragmentDoc}`;
export const ContactFragmentDoc = gql`
    fragment Contact on ContactType {
  ...MinimalContact
  externalId
  firstName
  lastName
  phone
  mobilePhone
  intermediary
  type {
    ...ContactTypeOption
  }
  account {
    ...AccountOption
  }
  opportunities {
    ...Opportunity
  }
  quotes {
    ...Quote
  }
}
    ${MinimalContactFragmentDoc}
${ContactTypeOptionFragmentDoc}
${AccountOptionFragmentDoc}
${OpportunityFragmentDoc}
${QuoteFragmentDoc}`;
export const AccountDetailFragmentDoc = gql`
    fragment AccountDetail on AccountType {
  ...Account
  contacts {
    ...Contact
  }
  opportunities {
    ...Opportunity
  }
  quotes {
    ...Quote
  }
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
}
    ${AccountFragmentDoc}
${ContactFragmentDoc}
${OpportunityFragmentDoc}
${QuoteFragmentDoc}
${MinimalUserFragmentDoc}`;
export const ContactDetailFragmentDoc = gql`
    fragment ContactDetail on ContactType {
  ...Contact
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
}
    ${ContactFragmentDoc}
${MinimalUserFragmentDoc}`;
export const OpportunityDetailFragmentDoc = gql`
    fragment OpportunityDetail on OpportunityType {
  ...Opportunity
  productsLegacy
  products {
    ...Product
  }
  promotions {
    ...Promotion
  }
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
  marketSurveys {
    ...MarketSurvey
  }
  read
  readBy {
    ...MinimalUser
  }
}
    ${OpportunityFragmentDoc}
${ProductFragmentDoc}
${PromotionFragmentDoc}
${MinimalUserFragmentDoc}
${MarketSurveyFragmentDoc}`;
export const MinimalProductListFragmentDoc = gql`
    fragment MinimalProductList on ProductListType {
  id
}
    `;
export const ProductOptionFragmentDoc = gql`
    fragment ProductOption on ProductType {
  id
  name
}
    `;
export const ProductFiveRankingFragmentDoc = gql`
    fragment ProductFiveRanking on ProductFiveRanking {
  product
  total
}
    `;
export const MinimalSaleProductFragmentDoc = gql`
    fragment MinimalSaleProduct on SaleProductType {
  id
  name
  composition
  presentation
  externalId
  price
  multiplier
  intermediaryCommission
  quantity
  unit {
    id
  }
  list {
    id
  }
  yieldField
  deliveryPeriod
}
    `;
export const PaymentMethodFragmentDoc = gql`
    fragment PaymentMethod on PaymentMethodType {
  id
  name
  multiplier
  order
  published
}
    `;
export const PaymentMethodOptionFragmentDoc = gql`
    fragment PaymentMethodOption on PaymentMethodType {
  id
  name
}
    `;
export const MinimalQuotedPaymentMethodFragmentDoc = gql`
    fragment MinimalQuotedPaymentMethod on QuotedPaymentMethodType {
  id
  multiplier
}
    `;
export const MinimalQuoteFragmentDoc = gql`
    fragment MinimalQuote on QuoteType {
  id
  createdBy {
    ...MinimalUser
  }
  intermediary {
    ...MinimalContact
  }
  products {
    ...MinimalQuotedProduct
  }
  paymentMethods {
    ...QuotedPaymentMethod
  }
}
    ${MinimalUserFragmentDoc}
${MinimalContactFragmentDoc}
${MinimalQuotedProductFragmentDoc}
${QuotedPaymentMethodFragmentDoc}`;
export const QuoteOrderTypeFragmentDoc = gql`
    fragment QuoteOrderType on QuoteOrderTypeType {
  id
  name
  order
}
    `;
export const QuotedProductFragmentDoc = gql`
    fragment QuotedProduct on QuotedProductType {
  id
  externalId
  name
  price
  composition
  presentation
  list {
    ...ProductList
  }
  unit {
    ...Unit
  }
  quantity
  multiplier
  intermediaryCommission
  yieldField
  deliveryPeriod
}
    ${ProductListFragmentDoc}
${UnitFragmentDoc}`;
export const ReasonApprovalFragmentDoc = gql`
    fragment ReasonApproval on ReasonType {
  id
  name
}
    `;
export const SaleReviewFragmentDoc = gql`
    fragment SaleReview on SaleReviewType {
  id
  statusDisplay
  previousTypeDisplay
  typeDisplay
  reason {
    ...ReasonApproval
  }
  notes
  created
  createdBy {
    ...MinimalUser
  }
}
    ${ReasonApprovalFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SaleProductFragmentDoc = gql`
    fragment SaleProduct on SaleProductType {
  id
  externalId
  name
  price
  composition
  presentation
  list {
    ...ProductList
  }
  unit {
    ...Unit
  }
  quantity
  multiplier
  intermediaryCommission
  yieldField
  deliveryPeriod
}
    ${ProductListFragmentDoc}
${UnitFragmentDoc}`;
export const AdministrationProductFragmentDoc = gql`
    fragment AdministrationProduct on AdministrationProductType {
  id
  name
  created
  unit {
    ...Unit
  }
  quantity
  quantityAdmin
  number
}
    ${UnitFragmentDoc}`;
export const SaleAdministrationFragmentDoc = gql`
    fragment SaleAdministration on SaleAdministrationType {
  id
  created
  createdBy {
    ...MinimalUser
  }
  type
  typeDisplay
  percentage
  productsAdmin {
    ...AdministrationProduct
  }
}
    ${MinimalUserFragmentDoc}
${AdministrationProductFragmentDoc}`;
export const CreditNoteReviewFragmentDoc = gql`
    fragment CreditNoteReview on CreditNoteReviewType {
  id
  statusDisplay
  typeDisplay
  notes
  created
  createdBy {
    ...MinimalUser
  }
}
    ${MinimalUserFragmentDoc}`;
export const CreditProductFragmentDoc = gql`
    fragment CreditProduct on CreditProductType {
  id
  name
  quantity
}
    `;
export const CreditNoteFragmentDoc = gql`
    fragment CreditNote on CreditNoteType {
  id
  created
  createdBy {
    ...MinimalUser
  }
  orderType {
    ...QuoteOrderType
  }
  notes
  status
  statusDisplay
  reviews {
    ...CreditNoteReview
  }
  creditProducts {
    ...CreditProduct
  }
}
    ${MinimalUserFragmentDoc}
${QuoteOrderTypeFragmentDoc}
${CreditNoteReviewFragmentDoc}
${CreditProductFragmentDoc}`;
export const SaleFragmentDoc = gql`
    fragment Sale on SaleType {
  id
  displayName
  statusDisplay
  paymentMethod {
    ...QuotedPaymentMethod
  }
  reviews {
    ...SaleReview
  }
  orderType {
    ...QuoteOrderType
  }
  quote {
    ...Quote
    products {
      ...QuotedProduct
    }
    orderType {
      ...QuoteOrderType
    }
    contact {
      ...MinimalContact
      account {
        ...MinimalAccount
      }
    }
  }
  products {
    ...SaleProduct
  }
  created
  createdBy {
    ...MinimalUser
  }
  administration {
    ...SaleAdministration
  }
  credits {
    ...CreditNote
    orderType {
      ...QuoteOrderType
    }
  }
}
    ${QuotedPaymentMethodFragmentDoc}
${SaleReviewFragmentDoc}
${QuoteOrderTypeFragmentDoc}
${QuoteFragmentDoc}
${QuotedProductFragmentDoc}
${MinimalContactFragmentDoc}
${MinimalAccountFragmentDoc}
${SaleProductFragmentDoc}
${MinimalUserFragmentDoc}
${SaleAdministrationFragmentDoc}
${CreditNoteFragmentDoc}`;
export const SaleDetailFragmentDoc = gql`
    fragment SaleDetail on SaleType {
  ...Sale
  status
  updated
  updatedBy {
    ...MinimalUser
  }
  reviewStart
  creditsCanReview
  commercialCanReview
  dirCommercialCanReview
  gteCreditsCanReview
  gteAdminCanReview
  dirManagingCanReview
  committeeCanReview
  paymentMethod {
    ...QuotedPaymentMethod
  }
  quote {
    ...Quote
    products {
      ...QuotedProduct
    }
  }
  products {
    ...SaleProduct
  }
  administration {
    ...SaleAdministration
    productsAdmin {
      ...AdministrationProduct
    }
  }
}
    ${SaleFragmentDoc}
${MinimalUserFragmentDoc}
${QuotedPaymentMethodFragmentDoc}
${QuoteFragmentDoc}
${QuotedProductFragmentDoc}
${SaleProductFragmentDoc}
${SaleAdministrationFragmentDoc}
${AdministrationProductFragmentDoc}`;
export const QuoteDetailFragmentDoc = gql`
    fragment QuoteDetail on QuoteType {
  ...Quote
  notes
  successChance {
    ...SuccessChance
  }
  intermediary {
    ...MinimalContact
  }
  orderType {
    ...QuoteOrderType
  }
  products {
    ...QuotedProduct
  }
  paymentMethods {
    ...QuotedPaymentMethod
  }
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
  sale {
    ...SaleDetail
  }
  comments {
    id
    message
    createdBy {
      id
      email
      firstName
      lastName
    }
    created
  }
}
    ${QuoteFragmentDoc}
${SuccessChanceFragmentDoc}
${MinimalContactFragmentDoc}
${QuoteOrderTypeFragmentDoc}
${QuotedProductFragmentDoc}
${QuotedPaymentMethodFragmentDoc}
${MinimalUserFragmentDoc}
${SaleDetailFragmentDoc}`;
export const MinimalSaleFragmentDoc = gql`
    fragment MinimalSale on SaleType {
  id
  createdBy {
    ...MinimalUser
  }
  paymentMethod {
    ...QuotedPaymentMethod
  }
  quote {
    id
    products {
      id
      price
      quantity
      multiplier
      intermediaryCommission
    }
  }
}
    ${MinimalUserFragmentDoc}
${QuotedPaymentMethodFragmentDoc}`;
export const NotificationFragmentDoc = gql`
    fragment Notification on NotificationType {
  id
  title
  text
  createdBy {
    ...MinimalUser
  }
  created
  sale {
    ...MinimalSale
  }
}
    ${MinimalUserFragmentDoc}
${MinimalSaleFragmentDoc}`;
export const NotificationUserFragmentDoc = gql`
    fragment NotificationUser on NotificationUserType {
  id
  seen
  notificationId {
    ...Notification
  }
  userNotified {
    ...MinimalUser
  }
}
    ${NotificationFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SalePdfRecipientFragmentDoc = gql`
    fragment SalePDFRecipient on SalePDFRecipientType {
  id
  email
  statusDisplay
}
    `;
export const SalePdfFragmentDoc = gql`
    fragment SalePDF on SalePDFType {
  id
  file
  slug
  recipients {
    ...SalePDFRecipient
  }
  created
  typeDisplay
  type
  createdBy {
    ...MinimalUser
  }
}
    ${SalePdfRecipientFragmentDoc}
${MinimalUserFragmentDoc}`;
export const FormulationOptionFragmentDoc = gql`
    fragment FormulationOption on FormulationType {
  id
  name
}
    `;
export const SeasonFragmentDoc = gql`
    fragment Season on SeasonType {
  id
  name
  startDate
  endDate
}
    `;
export const SalesTargetFragmentDoc = gql`
    fragment SalesTarget on SalesTargetType {
  id
  amount
  season {
    ...Season
  }
  seller {
    ...MinimalUser
  }
  saleTotal
  updated
}
    ${SeasonFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SalesTargetDetailFragmentDoc = gql`
    fragment SalesTargetDetail on SalesTargetType {
  ...SalesTarget
  amount
  seller {
    ...MinimalUser
  }
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
}
    ${SalesTargetFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SummaryClientFragmentDoc = gql`
    fragment SummaryClient on SummaryClientType {
  id
  account {
    ...MinimalAccount
  }
  trade
  commission
  echeqdif
  colecction
  rural
  transfer
  echeqcurrent
  nc
  cash
  negative
  grain
}
    ${MinimalAccountFragmentDoc}`;
export const WeekSummaryFragmentDoc = gql`
    fragment WeekSummary on WeekSummaryType {
  id
  created
  createdBy {
    ...MinimalUser
  }
  summaryClient {
    ...SummaryClient
  }
  seller {
    ...MinimalUser
  }
}
    ${MinimalUserFragmentDoc}
${SummaryClientFragmentDoc}`;
export const WeekSummaryDetailFragmentDoc = gql`
    fragment WeekSummaryDetail on WeekSummaryType {
  ...WeekSummary
  created
  summaryClient {
    ...SummaryClient
  }
  seller {
    ...MinimalUser
  }
}
    ${WeekSummaryFragmentDoc}
${SummaryClientFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SalesTargetsPeriodsFragmentDoc = gql`
    fragment SalesTargetsPeriods on SalesTargetsPeriodsType {
  byPeriod {
    startDate
    endDate
    amountSelled
    amountQuoted
    salesGap
    quotesGap
    monthlySalesTarget
  }
}
    `;
export const GetOpportunitiesAnalyticsDocument = gql`
    query getOpportunitiesAnalytics($filters: AnalyticsFilters!) {
  opportunitiesAnalytics(filters: $filters) {
    ...OpportunitiesAnalytics
  }
}
    ${OpportunitiesAnalyticsFragmentDoc}`;

/**
 * __useGetOpportunitiesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOpportunitiesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunitiesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunitiesAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetOpportunitiesAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpportunitiesAnalyticsQuery, GetOpportunitiesAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpportunitiesAnalyticsQuery, GetOpportunitiesAnalyticsQueryVariables>(GetOpportunitiesAnalyticsDocument, baseOptions);
      }
export function useGetOpportunitiesAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpportunitiesAnalyticsQuery, GetOpportunitiesAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpportunitiesAnalyticsQuery, GetOpportunitiesAnalyticsQueryVariables>(GetOpportunitiesAnalyticsDocument, baseOptions);
        }
export type GetOpportunitiesAnalyticsQueryHookResult = ReturnType<typeof useGetOpportunitiesAnalyticsQuery>;
export type GetOpportunitiesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetOpportunitiesAnalyticsLazyQuery>;
export type GetOpportunitiesAnalyticsQueryResult = ApolloReactCommon.QueryResult<GetOpportunitiesAnalyticsQuery, GetOpportunitiesAnalyticsQueryVariables>;
export function refetchGetOpportunitiesAnalyticsQuery(variables?: GetOpportunitiesAnalyticsQueryVariables) {
      return { query: GetOpportunitiesAnalyticsDocument, variables: variables }
    }
export const GetQuotesAnalyticsDocument = gql`
    query getQuotesAnalytics($filters: AnalyticsFilters!) {
  quotesAnalytics(filters: $filters) {
    ...QuotesAnalytics
  }
}
    ${QuotesAnalyticsFragmentDoc}`;

/**
 * __useGetQuotesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetQuotesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetQuotesAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>(GetQuotesAnalyticsDocument, baseOptions);
      }
export function useGetQuotesAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>(GetQuotesAnalyticsDocument, baseOptions);
        }
export type GetQuotesAnalyticsQueryHookResult = ReturnType<typeof useGetQuotesAnalyticsQuery>;
export type GetQuotesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetQuotesAnalyticsLazyQuery>;
export type GetQuotesAnalyticsQueryResult = ApolloReactCommon.QueryResult<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>;
export function refetchGetQuotesAnalyticsQuery(variables?: GetQuotesAnalyticsQueryVariables) {
      return { query: GetQuotesAnalyticsDocument, variables: variables }
    }
export const GetSalesAnalyticsDocument = gql`
    query getSalesAnalytics($filters: AnalyticsFilters!) {
  salesAnalytics(filters: $filters) {
    ...SalesAnalytics
  }
}
    ${SalesAnalyticsFragmentDoc}`;

/**
 * __useGetSalesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetSalesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSalesAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>(GetSalesAnalyticsDocument, baseOptions);
      }
export function useGetSalesAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>(GetSalesAnalyticsDocument, baseOptions);
        }
export type GetSalesAnalyticsQueryHookResult = ReturnType<typeof useGetSalesAnalyticsQuery>;
export type GetSalesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetSalesAnalyticsLazyQuery>;
export type GetSalesAnalyticsQueryResult = ApolloReactCommon.QueryResult<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>;
export function refetchGetSalesAnalyticsQuery(variables?: GetSalesAnalyticsQueryVariables) {
      return { query: GetSalesAnalyticsDocument, variables: variables }
    }
export const GetMaxCommissionMultiplierDocument = gql`
    query getMaxCommissionMultiplier {
  maxCommissionMultiplier
}
    `;

/**
 * __useGetMaxCommissionMultiplierQuery__
 *
 * To run a query within a React component, call `useGetMaxCommissionMultiplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxCommissionMultiplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxCommissionMultiplierQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxCommissionMultiplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaxCommissionMultiplierQuery, GetMaxCommissionMultiplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMaxCommissionMultiplierQuery, GetMaxCommissionMultiplierQueryVariables>(GetMaxCommissionMultiplierDocument, baseOptions);
      }
export function useGetMaxCommissionMultiplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaxCommissionMultiplierQuery, GetMaxCommissionMultiplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMaxCommissionMultiplierQuery, GetMaxCommissionMultiplierQueryVariables>(GetMaxCommissionMultiplierDocument, baseOptions);
        }
export type GetMaxCommissionMultiplierQueryHookResult = ReturnType<typeof useGetMaxCommissionMultiplierQuery>;
export type GetMaxCommissionMultiplierLazyQueryHookResult = ReturnType<typeof useGetMaxCommissionMultiplierLazyQuery>;
export type GetMaxCommissionMultiplierQueryResult = ApolloReactCommon.QueryResult<GetMaxCommissionMultiplierQuery, GetMaxCommissionMultiplierQueryVariables>;
export function refetchGetMaxCommissionMultiplierQuery(variables?: GetMaxCommissionMultiplierQueryVariables) {
      return { query: GetMaxCommissionMultiplierDocument, variables: variables }
    }
export const GetQuotePdfDisclaimerDocument = gql`
    query getQuotePdfDisclaimer {
  quotePdfDisclaimer
}
    `;

/**
 * __useGetQuotePdfDisclaimerQuery__
 *
 * To run a query within a React component, call `useGetQuotePdfDisclaimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotePdfDisclaimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotePdfDisclaimerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuotePdfDisclaimerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQuotePdfDisclaimerQuery, GetQuotePdfDisclaimerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQuotePdfDisclaimerQuery, GetQuotePdfDisclaimerQueryVariables>(GetQuotePdfDisclaimerDocument, baseOptions);
      }
export function useGetQuotePdfDisclaimerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuotePdfDisclaimerQuery, GetQuotePdfDisclaimerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQuotePdfDisclaimerQuery, GetQuotePdfDisclaimerQueryVariables>(GetQuotePdfDisclaimerDocument, baseOptions);
        }
export type GetQuotePdfDisclaimerQueryHookResult = ReturnType<typeof useGetQuotePdfDisclaimerQuery>;
export type GetQuotePdfDisclaimerLazyQueryHookResult = ReturnType<typeof useGetQuotePdfDisclaimerLazyQuery>;
export type GetQuotePdfDisclaimerQueryResult = ApolloReactCommon.QueryResult<GetQuotePdfDisclaimerQuery, GetQuotePdfDisclaimerQueryVariables>;
export function refetchGetQuotePdfDisclaimerQuery(variables?: GetQuotePdfDisclaimerQueryVariables) {
      return { query: GetQuotePdfDisclaimerDocument, variables: variables }
    }
export const GetSalesTargetsPeriodsDocument = gql`
    query getSalesTargetsPeriods($filters: SalesTargetsPeriodsFilters!) {
  salesTargetsPeriods(filters: $filters) {
    ...SalesTargetsPeriods
  }
}
    ${SalesTargetsPeriodsFragmentDoc}`;

/**
 * __useGetSalesTargetsPeriodsQuery__
 *
 * To run a query within a React component, call `useGetSalesTargetsPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesTargetsPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesTargetsPeriodsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSalesTargetsPeriodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesTargetsPeriodsQuery, GetSalesTargetsPeriodsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesTargetsPeriodsQuery, GetSalesTargetsPeriodsQueryVariables>(GetSalesTargetsPeriodsDocument, baseOptions);
      }
export function useGetSalesTargetsPeriodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesTargetsPeriodsQuery, GetSalesTargetsPeriodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesTargetsPeriodsQuery, GetSalesTargetsPeriodsQueryVariables>(GetSalesTargetsPeriodsDocument, baseOptions);
        }
export type GetSalesTargetsPeriodsQueryHookResult = ReturnType<typeof useGetSalesTargetsPeriodsQuery>;
export type GetSalesTargetsPeriodsLazyQueryHookResult = ReturnType<typeof useGetSalesTargetsPeriodsLazyQuery>;
export type GetSalesTargetsPeriodsQueryResult = ApolloReactCommon.QueryResult<GetSalesTargetsPeriodsQuery, GetSalesTargetsPeriodsQueryVariables>;
export function refetchGetSalesTargetsPeriodsQuery(variables?: GetSalesTargetsPeriodsQueryVariables) {
      return { query: GetSalesTargetsPeriodsDocument, variables: variables }
    }
export const GetFormulationsDocument = gql`
    query getFormulations {
  formulations {
    ...Formulation
  }
}
    ${FormulationFragmentDoc}`;

/**
 * __useGetFormulationsQuery__
 *
 * To run a query within a React component, call `useGetFormulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormulationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormulationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFormulationsQuery, GetFormulationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFormulationsQuery, GetFormulationsQueryVariables>(GetFormulationsDocument, baseOptions);
      }
export function useGetFormulationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormulationsQuery, GetFormulationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFormulationsQuery, GetFormulationsQueryVariables>(GetFormulationsDocument, baseOptions);
        }
export type GetFormulationsQueryHookResult = ReturnType<typeof useGetFormulationsQuery>;
export type GetFormulationsLazyQueryHookResult = ReturnType<typeof useGetFormulationsLazyQuery>;
export type GetFormulationsQueryResult = ApolloReactCommon.QueryResult<GetFormulationsQuery, GetFormulationsQueryVariables>;
export function refetchGetFormulationsQuery(variables?: GetFormulationsQueryVariables) {
      return { query: GetFormulationsDocument, variables: variables }
    }
export const GetLaboratoryOptionsDocument = gql`
    query getLaboratoryOptions {
  laboratories {
    ...Laboratory
  }
}
    ${LaboratoryFragmentDoc}`;

/**
 * __useGetLaboratoryOptionsQuery__
 *
 * To run a query within a React component, call `useGetLaboratoryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLaboratoryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLaboratoryOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLaboratoryOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLaboratoryOptionsQuery, GetLaboratoryOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLaboratoryOptionsQuery, GetLaboratoryOptionsQueryVariables>(GetLaboratoryOptionsDocument, baseOptions);
      }
export function useGetLaboratoryOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLaboratoryOptionsQuery, GetLaboratoryOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLaboratoryOptionsQuery, GetLaboratoryOptionsQueryVariables>(GetLaboratoryOptionsDocument, baseOptions);
        }
export type GetLaboratoryOptionsQueryHookResult = ReturnType<typeof useGetLaboratoryOptionsQuery>;
export type GetLaboratoryOptionsLazyQueryHookResult = ReturnType<typeof useGetLaboratoryOptionsLazyQuery>;
export type GetLaboratoryOptionsQueryResult = ApolloReactCommon.QueryResult<GetLaboratoryOptionsQuery, GetLaboratoryOptionsQueryVariables>;
export function refetchGetLaboratoryOptionsQuery(variables?: GetLaboratoryOptionsQueryVariables) {
      return { query: GetLaboratoryOptionsDocument, variables: variables }
    }
export const GetFormulationOptionsDocument = gql`
    query getFormulationOptions($activeIngredientId: UUID) {
  formulations(activeIngredientId: $activeIngredientId) {
    ...FormulationOption
  }
}
    ${FormulationOptionFragmentDoc}`;

/**
 * __useGetFormulationOptionsQuery__
 *
 * To run a query within a React component, call `useGetFormulationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormulationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormulationOptionsQuery({
 *   variables: {
 *      activeIngredientId: // value for 'activeIngredientId'
 *   },
 * });
 */
export function useGetFormulationOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFormulationOptionsQuery, GetFormulationOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFormulationOptionsQuery, GetFormulationOptionsQueryVariables>(GetFormulationOptionsDocument, baseOptions);
      }
export function useGetFormulationOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormulationOptionsQuery, GetFormulationOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFormulationOptionsQuery, GetFormulationOptionsQueryVariables>(GetFormulationOptionsDocument, baseOptions);
        }
export type GetFormulationOptionsQueryHookResult = ReturnType<typeof useGetFormulationOptionsQuery>;
export type GetFormulationOptionsLazyQueryHookResult = ReturnType<typeof useGetFormulationOptionsLazyQuery>;
export type GetFormulationOptionsQueryResult = ApolloReactCommon.QueryResult<GetFormulationOptionsQuery, GetFormulationOptionsQueryVariables>;
export function refetchGetFormulationOptionsQuery(variables?: GetFormulationOptionsQueryVariables) {
      return { query: GetFormulationOptionsDocument, variables: variables }
    }
export const GetActiveIngredientOptionsDocument = gql`
    query getActiveIngredientOptions {
  activeIngredients {
    ...ActiveIngredient
  }
}
    ${ActiveIngredientFragmentDoc}`;

/**
 * __useGetActiveIngredientOptionsQuery__
 *
 * To run a query within a React component, call `useGetActiveIngredientOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveIngredientOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveIngredientOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveIngredientOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveIngredientOptionsQuery, GetActiveIngredientOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveIngredientOptionsQuery, GetActiveIngredientOptionsQueryVariables>(GetActiveIngredientOptionsDocument, baseOptions);
      }
export function useGetActiveIngredientOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveIngredientOptionsQuery, GetActiveIngredientOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveIngredientOptionsQuery, GetActiveIngredientOptionsQueryVariables>(GetActiveIngredientOptionsDocument, baseOptions);
        }
export type GetActiveIngredientOptionsQueryHookResult = ReturnType<typeof useGetActiveIngredientOptionsQuery>;
export type GetActiveIngredientOptionsLazyQueryHookResult = ReturnType<typeof useGetActiveIngredientOptionsLazyQuery>;
export type GetActiveIngredientOptionsQueryResult = ApolloReactCommon.QueryResult<GetActiveIngredientOptionsQuery, GetActiveIngredientOptionsQueryVariables>;
export function refetchGetActiveIngredientOptionsQuery(variables?: GetActiveIngredientOptionsQueryVariables) {
      return { query: GetActiveIngredientOptionsDocument, variables: variables }
    }
export const GetUserDocument = gql`
    query getUser {
  user {
    ...User
    preferences {
      ...UserPreferences
    }
  }
}
    ${UserFragmentDoc}
${UserPreferencesFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const GetUsersDocument = gql`
    query getUsers {
  users {
    ...MinimalUser
  }
}
    ${MinimalUserFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const GetChildUsersDocument = gql`
    query getChildUsers {
  childUsers {
    ...MinimalUser
  }
}
    ${MinimalUserFragmentDoc}`;

/**
 * __useGetChildUsersQuery__
 *
 * To run a query within a React component, call `useGetChildUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChildUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChildUsersQuery, GetChildUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChildUsersQuery, GetChildUsersQueryVariables>(GetChildUsersDocument, baseOptions);
      }
export function useGetChildUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChildUsersQuery, GetChildUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChildUsersQuery, GetChildUsersQueryVariables>(GetChildUsersDocument, baseOptions);
        }
export type GetChildUsersQueryHookResult = ReturnType<typeof useGetChildUsersQuery>;
export type GetChildUsersLazyQueryHookResult = ReturnType<typeof useGetChildUsersLazyQuery>;
export type GetChildUsersQueryResult = ApolloReactCommon.QueryResult<GetChildUsersQuery, GetChildUsersQueryVariables>;
export function refetchGetChildUsersQuery(variables?: GetChildUsersQueryVariables) {
      return { query: GetChildUsersDocument, variables: variables }
    }
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserMutationInput!) {
  updateUser(input: $input) {
    user {
      ...User
    }
    errors {
      field
      messages
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    user {
      ...User
    }
    errors {
      field
      messages
    }
  }
}
    ${UserFragmentDoc}`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordMutationInput!) {
  resetPassword(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SetPasswordDocument = gql`
    mutation setPassword($input: SetPasswordMutationInput!) {
  setPassword(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type SetPasswordMutationFn = ApolloReactCommon.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, baseOptions);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = ApolloReactCommon.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordMutationInput!) {
  updatePassword(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdatePasswordMutationFn = ApolloReactCommon.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = ApolloReactCommon.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const GetAccountsDocument = gql`
    query getAccounts($options: AccountListOptionsInputType) {
  accounts(options: $options) {
    items {
      ...Account
    }
    count
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
      }
export function useGetAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = ApolloReactCommon.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export function refetchGetAccountsQuery(variables?: GetAccountsQueryVariables) {
      return { query: GetAccountsDocument, variables: variables }
    }
export const GetAccountOptionsDocument = gql`
    query getAccountOptions {
  accounts {
    items {
      ...AccountOption
    }
  }
}
    ${AccountOptionFragmentDoc}`;

/**
 * __useGetAccountOptionsQuery__
 *
 * To run a query within a React component, call `useGetAccountOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>(GetAccountOptionsDocument, baseOptions);
      }
export function useGetAccountOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>(GetAccountOptionsDocument, baseOptions);
        }
export type GetAccountOptionsQueryHookResult = ReturnType<typeof useGetAccountOptionsQuery>;
export type GetAccountOptionsLazyQueryHookResult = ReturnType<typeof useGetAccountOptionsLazyQuery>;
export type GetAccountOptionsQueryResult = ApolloReactCommon.QueryResult<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>;
export function refetchGetAccountOptionsQuery(variables?: GetAccountOptionsQueryVariables) {
      return { query: GetAccountOptionsDocument, variables: variables }
    }
export const GetAccountDocument = gql`
    query getAccount($id: UUID) {
  account(id: $id) {
    ...AccountDetail
  }
}
    ${AccountDetailFragmentDoc}`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
      }
export function useGetAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = ApolloReactCommon.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export function refetchGetAccountQuery(variables?: GetAccountQueryVariables) {
      return { query: GetAccountDocument, variables: variables }
    }
export const CreateAccountDocument = gql`
    mutation createAccount($input: CreateAccountMutationInput!) {
  createAccount(input: $input) {
    account {
      ...AccountDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountDetailFragmentDoc}`;
export type CreateAccountMutationFn = ApolloReactCommon.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = ApolloReactCommon.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($input: UpdateAccountMutationInput!) {
  updateAccount(input: $input) {
    account {
      ...AccountDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountDetailFragmentDoc}`;
export type UpdateAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = ApolloReactCommon.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($input: DeleteAccountMutationInput!) {
  deleteAccount(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = ApolloReactCommon.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const GetAccountTypesDocument = gql`
    query getAccountTypes {
  accountTypes {
    ...AccountTypeOption
  }
}
    ${AccountTypeOptionFragmentDoc}`;

/**
 * __useGetAccountTypesQuery__
 *
 * To run a query within a React component, call `useGetAccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountTypesQuery, GetAccountTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountTypesQuery, GetAccountTypesQueryVariables>(GetAccountTypesDocument, baseOptions);
      }
export function useGetAccountTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountTypesQuery, GetAccountTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountTypesQuery, GetAccountTypesQueryVariables>(GetAccountTypesDocument, baseOptions);
        }
export type GetAccountTypesQueryHookResult = ReturnType<typeof useGetAccountTypesQuery>;
export type GetAccountTypesLazyQueryHookResult = ReturnType<typeof useGetAccountTypesLazyQuery>;
export type GetAccountTypesQueryResult = ApolloReactCommon.QueryResult<GetAccountTypesQuery, GetAccountTypesQueryVariables>;
export function refetchGetAccountTypesQuery(variables?: GetAccountTypesQueryVariables) {
      return { query: GetAccountTypesDocument, variables: variables }
    }
export const GetAccountRegionsDocument = gql`
    query getAccountRegions {
  accountRegions {
    ...AccountRegionOption
  }
}
    ${AccountRegionOptionFragmentDoc}`;

/**
 * __useGetAccountRegionsQuery__
 *
 * To run a query within a React component, call `useGetAccountRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>(GetAccountRegionsDocument, baseOptions);
      }
export function useGetAccountRegionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>(GetAccountRegionsDocument, baseOptions);
        }
export type GetAccountRegionsQueryHookResult = ReturnType<typeof useGetAccountRegionsQuery>;
export type GetAccountRegionsLazyQueryHookResult = ReturnType<typeof useGetAccountRegionsLazyQuery>;
export type GetAccountRegionsQueryResult = ApolloReactCommon.QueryResult<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>;
export function refetchGetAccountRegionsQuery(variables?: GetAccountRegionsQueryVariables) {
      return { query: GetAccountRegionsDocument, variables: variables }
    }
export const GetAccountCountriesDocument = gql`
    query getAccountCountries {
  accountCountries {
    ...AccountCountryOption
  }
}
    ${AccountCountryOptionFragmentDoc}`;

/**
 * __useGetAccountCountriesQuery__
 *
 * To run a query within a React component, call `useGetAccountCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountCountriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountCountriesQuery, GetAccountCountriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountCountriesQuery, GetAccountCountriesQueryVariables>(GetAccountCountriesDocument, baseOptions);
      }
export function useGetAccountCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountCountriesQuery, GetAccountCountriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountCountriesQuery, GetAccountCountriesQueryVariables>(GetAccountCountriesDocument, baseOptions);
        }
export type GetAccountCountriesQueryHookResult = ReturnType<typeof useGetAccountCountriesQuery>;
export type GetAccountCountriesLazyQueryHookResult = ReturnType<typeof useGetAccountCountriesLazyQuery>;
export type GetAccountCountriesQueryResult = ApolloReactCommon.QueryResult<GetAccountCountriesQuery, GetAccountCountriesQueryVariables>;
export function refetchGetAccountCountriesQuery(variables?: GetAccountCountriesQueryVariables) {
      return { query: GetAccountCountriesDocument, variables: variables }
    }
export const GetAccountRegionsByCountryDocument = gql`
    query getAccountRegionsByCountry($countryId: UUID) {
  accountRegionsByCountry(id: $countryId) {
    ...AccountRegionOption
  }
}
    ${AccountRegionOptionFragmentDoc}`;

/**
 * __useGetAccountRegionsByCountryQuery__
 *
 * To run a query within a React component, call `useGetAccountRegionsByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRegionsByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRegionsByCountryQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetAccountRegionsByCountryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountRegionsByCountryQuery, GetAccountRegionsByCountryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountRegionsByCountryQuery, GetAccountRegionsByCountryQueryVariables>(GetAccountRegionsByCountryDocument, baseOptions);
      }
export function useGetAccountRegionsByCountryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountRegionsByCountryQuery, GetAccountRegionsByCountryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountRegionsByCountryQuery, GetAccountRegionsByCountryQueryVariables>(GetAccountRegionsByCountryDocument, baseOptions);
        }
export type GetAccountRegionsByCountryQueryHookResult = ReturnType<typeof useGetAccountRegionsByCountryQuery>;
export type GetAccountRegionsByCountryLazyQueryHookResult = ReturnType<typeof useGetAccountRegionsByCountryLazyQuery>;
export type GetAccountRegionsByCountryQueryResult = ApolloReactCommon.QueryResult<GetAccountRegionsByCountryQuery, GetAccountRegionsByCountryQueryVariables>;
export function refetchGetAccountRegionsByCountryQuery(variables?: GetAccountRegionsByCountryQueryVariables) {
      return { query: GetAccountRegionsByCountryDocument, variables: variables }
    }
export const GetAccountCountryDocument = gql`
    query getAccountCountry($id: UUID) {
  accountCountry(id: $id) {
    id
    name
    isoCode
  }
}
    `;

/**
 * __useGetAccountCountryQuery__
 *
 * To run a query within a React component, call `useGetAccountCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCountryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountCountryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountCountryQuery, GetAccountCountryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountCountryQuery, GetAccountCountryQueryVariables>(GetAccountCountryDocument, baseOptions);
      }
export function useGetAccountCountryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountCountryQuery, GetAccountCountryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountCountryQuery, GetAccountCountryQueryVariables>(GetAccountCountryDocument, baseOptions);
        }
export type GetAccountCountryQueryHookResult = ReturnType<typeof useGetAccountCountryQuery>;
export type GetAccountCountryLazyQueryHookResult = ReturnType<typeof useGetAccountCountryLazyQuery>;
export type GetAccountCountryQueryResult = ApolloReactCommon.QueryResult<GetAccountCountryQuery, GetAccountCountryQueryVariables>;
export function refetchGetAccountCountryQuery(variables?: GetAccountCountryQueryVariables) {
      return { query: GetAccountCountryDocument, variables: variables }
    }
export const GetProductsSalesDocument = gql`
    query getProductsSales($user: String, $period: String) {
  productsSales(user: $user, period: $period) {
    ...ProductFiveRanking
  }
}
    ${ProductFiveRankingFragmentDoc}`;

/**
 * __useGetProductsSalesQuery__
 *
 * To run a query within a React component, call `useGetProductsSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsSalesQuery({
 *   variables: {
 *      user: // value for 'user'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetProductsSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsSalesQuery, GetProductsSalesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsSalesQuery, GetProductsSalesQueryVariables>(GetProductsSalesDocument, baseOptions);
      }
export function useGetProductsSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsSalesQuery, GetProductsSalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsSalesQuery, GetProductsSalesQueryVariables>(GetProductsSalesDocument, baseOptions);
        }
export type GetProductsSalesQueryHookResult = ReturnType<typeof useGetProductsSalesQuery>;
export type GetProductsSalesLazyQueryHookResult = ReturnType<typeof useGetProductsSalesLazyQuery>;
export type GetProductsSalesQueryResult = ApolloReactCommon.QueryResult<GetProductsSalesQuery, GetProductsSalesQueryVariables>;
export function refetchGetProductsSalesQuery(variables?: GetProductsSalesQueryVariables) {
      return { query: GetProductsSalesDocument, variables: variables }
    }
export const GetAmountByAccountDocument = gql`
    query getAmountByAccount($user: String, $period: String) {
  amountByAccount(user: $user, period: $period) {
    ...AccountRanking
  }
}
    ${AccountRankingFragmentDoc}`;

/**
 * __useGetAmountByAccountQuery__
 *
 * To run a query within a React component, call `useGetAmountByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmountByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmountByAccountQuery({
 *   variables: {
 *      user: // value for 'user'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetAmountByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAmountByAccountQuery, GetAmountByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAmountByAccountQuery, GetAmountByAccountQueryVariables>(GetAmountByAccountDocument, baseOptions);
      }
export function useGetAmountByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAmountByAccountQuery, GetAmountByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAmountByAccountQuery, GetAmountByAccountQueryVariables>(GetAmountByAccountDocument, baseOptions);
        }
export type GetAmountByAccountQueryHookResult = ReturnType<typeof useGetAmountByAccountQuery>;
export type GetAmountByAccountLazyQueryHookResult = ReturnType<typeof useGetAmountByAccountLazyQuery>;
export type GetAmountByAccountQueryResult = ApolloReactCommon.QueryResult<GetAmountByAccountQuery, GetAmountByAccountQueryVariables>;
export function refetchGetAmountByAccountQuery(variables?: GetAmountByAccountQueryVariables) {
      return { query: GetAmountByAccountDocument, variables: variables }
    }
export const GetTotalSalesPeriodDocument = gql`
    query getTotalSalesPeriod($user: String!, $period: String) {
  totalSalesPeriod(user: $user, period: $period) {
    ...TotalSales
  }
}
    ${TotalSalesFragmentDoc}`;

/**
 * __useGetTotalSalesPeriodQuery__
 *
 * To run a query within a React component, call `useGetTotalSalesPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalSalesPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalSalesPeriodQuery({
 *   variables: {
 *      user: // value for 'user'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetTotalSalesPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTotalSalesPeriodQuery, GetTotalSalesPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTotalSalesPeriodQuery, GetTotalSalesPeriodQueryVariables>(GetTotalSalesPeriodDocument, baseOptions);
      }
export function useGetTotalSalesPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTotalSalesPeriodQuery, GetTotalSalesPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTotalSalesPeriodQuery, GetTotalSalesPeriodQueryVariables>(GetTotalSalesPeriodDocument, baseOptions);
        }
export type GetTotalSalesPeriodQueryHookResult = ReturnType<typeof useGetTotalSalesPeriodQuery>;
export type GetTotalSalesPeriodLazyQueryHookResult = ReturnType<typeof useGetTotalSalesPeriodLazyQuery>;
export type GetTotalSalesPeriodQueryResult = ApolloReactCommon.QueryResult<GetTotalSalesPeriodQuery, GetTotalSalesPeriodQueryVariables>;
export function refetchGetTotalSalesPeriodQuery(variables?: GetTotalSalesPeriodQueryVariables) {
      return { query: GetTotalSalesPeriodDocument, variables: variables }
    }
export const GetContactsDocument = gql`
    query getContacts($options: ContactListOptionsInputType) {
  contacts(options: $options) {
    items {
      ...Contact
    }
    count
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
      }
export function useGetContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = ApolloReactCommon.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export function refetchGetContactsQuery(variables?: GetContactsQueryVariables) {
      return { query: GetContactsDocument, variables: variables }
    }
export const GetContactOptionsDocument = gql`
    query getContactOptions($options: ContactListOptionsInputType) {
  contacts(options: $options) {
    items {
      ...ContactOption
    }
    count
  }
}
    ${ContactOptionFragmentDoc}`;

/**
 * __useGetContactOptionsQuery__
 *
 * To run a query within a React component, call `useGetContactOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetContactOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactOptionsQuery, GetContactOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactOptionsQuery, GetContactOptionsQueryVariables>(GetContactOptionsDocument, baseOptions);
      }
export function useGetContactOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactOptionsQuery, GetContactOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactOptionsQuery, GetContactOptionsQueryVariables>(GetContactOptionsDocument, baseOptions);
        }
export type GetContactOptionsQueryHookResult = ReturnType<typeof useGetContactOptionsQuery>;
export type GetContactOptionsLazyQueryHookResult = ReturnType<typeof useGetContactOptionsLazyQuery>;
export type GetContactOptionsQueryResult = ApolloReactCommon.QueryResult<GetContactOptionsQuery, GetContactOptionsQueryVariables>;
export function refetchGetContactOptionsQuery(variables?: GetContactOptionsQueryVariables) {
      return { query: GetContactOptionsDocument, variables: variables }
    }
export const GetIntermediariesDocument = gql`
    query getIntermediaries {
  intermediaries {
    id
    displayName
    email
  }
}
    `;

/**
 * __useGetIntermediariesQuery__
 *
 * To run a query within a React component, call `useGetIntermediariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntermediariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntermediariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntermediariesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIntermediariesQuery, GetIntermediariesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIntermediariesQuery, GetIntermediariesQueryVariables>(GetIntermediariesDocument, baseOptions);
      }
export function useGetIntermediariesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIntermediariesQuery, GetIntermediariesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIntermediariesQuery, GetIntermediariesQueryVariables>(GetIntermediariesDocument, baseOptions);
        }
export type GetIntermediariesQueryHookResult = ReturnType<typeof useGetIntermediariesQuery>;
export type GetIntermediariesLazyQueryHookResult = ReturnType<typeof useGetIntermediariesLazyQuery>;
export type GetIntermediariesQueryResult = ApolloReactCommon.QueryResult<GetIntermediariesQuery, GetIntermediariesQueryVariables>;
export function refetchGetIntermediariesQuery(variables?: GetIntermediariesQueryVariables) {
      return { query: GetIntermediariesDocument, variables: variables }
    }
export const GetContactAccountDocument = gql`
    query getContactAccount($id: UUID) {
  contact(id: $id) {
    account {
      id
    }
  }
}
    `;

/**
 * __useGetContactAccountQuery__
 *
 * To run a query within a React component, call `useGetContactAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactAccountQuery, GetContactAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactAccountQuery, GetContactAccountQueryVariables>(GetContactAccountDocument, baseOptions);
      }
export function useGetContactAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactAccountQuery, GetContactAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactAccountQuery, GetContactAccountQueryVariables>(GetContactAccountDocument, baseOptions);
        }
export type GetContactAccountQueryHookResult = ReturnType<typeof useGetContactAccountQuery>;
export type GetContactAccountLazyQueryHookResult = ReturnType<typeof useGetContactAccountLazyQuery>;
export type GetContactAccountQueryResult = ApolloReactCommon.QueryResult<GetContactAccountQuery, GetContactAccountQueryVariables>;
export function refetchGetContactAccountQuery(variables?: GetContactAccountQueryVariables) {
      return { query: GetContactAccountDocument, variables: variables }
    }
export const GetContactDocument = gql`
    query getContact($id: UUID) {
  contact(id: $id) {
    ...ContactDetail
  }
}
    ${ContactDetailFragmentDoc}`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
      }
export function useGetContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = ApolloReactCommon.QueryResult<GetContactQuery, GetContactQueryVariables>;
export function refetchGetContactQuery(variables?: GetContactQueryVariables) {
      return { query: GetContactDocument, variables: variables }
    }
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactMutationInput!) {
  createContact(input: $input) {
    contact {
      ...ContactDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${ContactDetailFragmentDoc}`;
export type CreateContactMutationFn = ApolloReactCommon.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = ApolloReactCommon.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($input: UpdateContactMutationInput!) {
  updateContact(input: $input) {
    contact {
      ...ContactDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${ContactDetailFragmentDoc}`;
export type UpdateContactMutationFn = ApolloReactCommon.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = ApolloReactCommon.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation deleteContact($input: DeleteContactMutationInput!) {
  deleteContact(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteContactMutationFn = ApolloReactCommon.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = ApolloReactCommon.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const GetContactTypesDocument = gql`
    query getContactTypes {
  contactTypes {
    ...ContactTypeOption
  }
}
    ${ContactTypeOptionFragmentDoc}`;

/**
 * __useGetContactTypesQuery__
 *
 * To run a query within a React component, call `useGetContactTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactTypesQuery, GetContactTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactTypesQuery, GetContactTypesQueryVariables>(GetContactTypesDocument, baseOptions);
      }
export function useGetContactTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactTypesQuery, GetContactTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactTypesQuery, GetContactTypesQueryVariables>(GetContactTypesDocument, baseOptions);
        }
export type GetContactTypesQueryHookResult = ReturnType<typeof useGetContactTypesQuery>;
export type GetContactTypesLazyQueryHookResult = ReturnType<typeof useGetContactTypesLazyQuery>;
export type GetContactTypesQueryResult = ApolloReactCommon.QueryResult<GetContactTypesQuery, GetContactTypesQueryVariables>;
export function refetchGetContactTypesQuery(variables?: GetContactTypesQueryVariables) {
      return { query: GetContactTypesDocument, variables: variables }
    }
export const CreateNotificationDocument = gql`
    mutation createNotification($input: CreateNotificationMutationInput!) {
  createNotification(input: $input) {
    notification {
      ...Notification
    }
    errors {
      field
      messages
    }
  }
}
    ${NotificationFragmentDoc}`;
export type CreateNotificationMutationFn = ApolloReactCommon.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, baseOptions);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = ApolloReactCommon.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const UpdateNotificationUserDocument = gql`
    mutation updateNotificationUser($input: UpdateNotificationUserMutationInput!) {
  updateNotificationUser(input: $input) {
    notificationUser {
      ...NotificationUser
    }
    errors {
      field
      messages
    }
  }
}
    ${NotificationUserFragmentDoc}`;
export type UpdateNotificationUserMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationUserMutation, UpdateNotificationUserMutationVariables>;

/**
 * __useUpdateNotificationUserMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationUserMutation, { data, loading, error }] = useUpdateNotificationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationUserMutation, UpdateNotificationUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationUserMutation, UpdateNotificationUserMutationVariables>(UpdateNotificationUserDocument, baseOptions);
      }
export type UpdateNotificationUserMutationHookResult = ReturnType<typeof useUpdateNotificationUserMutation>;
export type UpdateNotificationUserMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationUserMutation>;
export type UpdateNotificationUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationUserMutation, UpdateNotificationUserMutationVariables>;
export const UpdateNotificationAllDocument = gql`
    mutation updateNotificationAll($input: UpdateNotificationAllMutationInput!) {
  updateNotificationAll(input: $input) {
    notificationUser {
      ...NotificationUser
    }
    errors {
      field
      messages
    }
  }
}
    ${NotificationUserFragmentDoc}`;
export type UpdateNotificationAllMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>;

/**
 * __useUpdateNotificationAllMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationAllMutation, { data, loading, error }] = useUpdateNotificationAllMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationAllMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>(UpdateNotificationAllDocument, baseOptions);
      }
export type UpdateNotificationAllMutationHookResult = ReturnType<typeof useUpdateNotificationAllMutation>;
export type UpdateNotificationAllMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationAllMutation>;
export type UpdateNotificationAllMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationAllMutation, UpdateNotificationAllMutationVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications {
  notifications {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = ApolloReactCommon.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export function refetchGetNotificationsQuery(variables?: GetNotificationsQueryVariables) {
      return { query: GetNotificationsDocument, variables: variables }
    }
export const GetNotificationsUserDocument = gql`
    query getNotificationsUser {
  notificationsUser {
    ...NotificationUser
  }
}
    ${NotificationUserFragmentDoc}`;

/**
 * __useGetNotificationsUserQuery__
 *
 * To run a query within a React component, call `useGetNotificationsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationsUserQuery, GetNotificationsUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationsUserQuery, GetNotificationsUserQueryVariables>(GetNotificationsUserDocument, baseOptions);
      }
export function useGetNotificationsUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationsUserQuery, GetNotificationsUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationsUserQuery, GetNotificationsUserQueryVariables>(GetNotificationsUserDocument, baseOptions);
        }
export type GetNotificationsUserQueryHookResult = ReturnType<typeof useGetNotificationsUserQuery>;
export type GetNotificationsUserLazyQueryHookResult = ReturnType<typeof useGetNotificationsUserLazyQuery>;
export type GetNotificationsUserQueryResult = ApolloReactCommon.QueryResult<GetNotificationsUserQuery, GetNotificationsUserQueryVariables>;
export function refetchGetNotificationsUserQuery(variables?: GetNotificationsUserQueryVariables) {
      return { query: GetNotificationsUserDocument, variables: variables }
    }
export const CreateUserPreferencesDocument = gql`
    mutation createUserPreferences($input: CreateUserPreferencesMutationInput!) {
  createUserPreferences(input: $input) {
    userPreferences {
      ...UserPreferences
      user {
        ...MinimalUser
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${UserPreferencesFragmentDoc}
${MinimalUserFragmentDoc}`;
export type CreateUserPreferencesMutationFn = ApolloReactCommon.MutationFunction<CreateUserPreferencesMutation, CreateUserPreferencesMutationVariables>;

/**
 * __useCreateUserPreferencesMutation__
 *
 * To run a mutation, you first call `useCreateUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPreferencesMutation, { data, loading, error }] = useCreateUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserPreferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserPreferencesMutation, CreateUserPreferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserPreferencesMutation, CreateUserPreferencesMutationVariables>(CreateUserPreferencesDocument, baseOptions);
      }
export type CreateUserPreferencesMutationHookResult = ReturnType<typeof useCreateUserPreferencesMutation>;
export type CreateUserPreferencesMutationResult = ApolloReactCommon.MutationResult<CreateUserPreferencesMutation>;
export type CreateUserPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserPreferencesMutation, CreateUserPreferencesMutationVariables>;
export const UpdateUserPreferencesDocument = gql`
    mutation updateUserPreferences($input: UpdateUserPreferencesMutationInput!) {
  updateUserPreferences(input: $input) {
    userPreferences {
      ...UserPreferences
      user {
        ...MinimalUser
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${UserPreferencesFragmentDoc}
${MinimalUserFragmentDoc}`;
export type UpdateUserPreferencesMutationFn = ApolloReactCommon.MutationFunction<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;

/**
 * __useUpdateUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferencesMutation, { data, loading, error }] = useUpdateUserPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPreferencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>(UpdateUserPreferencesDocument, baseOptions);
      }
export type UpdateUserPreferencesMutationHookResult = ReturnType<typeof useUpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationResult = ApolloReactCommon.MutationResult<UpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;
export const GetOpportunitiesDocument = gql`
    query getOpportunities($options: OpportunityListOptionsInputType) {
  opportunities(options: $options) {
    items {
      ...Opportunity
    }
    count
  }
}
    ${OpportunityFragmentDoc}`;

/**
 * __useGetOpportunitiesQuery__
 *
 * To run a query within a React component, call `useGetOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunitiesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetOpportunitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>(GetOpportunitiesDocument, baseOptions);
      }
export function useGetOpportunitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>(GetOpportunitiesDocument, baseOptions);
        }
export type GetOpportunitiesQueryHookResult = ReturnType<typeof useGetOpportunitiesQuery>;
export type GetOpportunitiesLazyQueryHookResult = ReturnType<typeof useGetOpportunitiesLazyQuery>;
export type GetOpportunitiesQueryResult = ApolloReactCommon.QueryResult<GetOpportunitiesQuery, GetOpportunitiesQueryVariables>;
export function refetchGetOpportunitiesQuery(variables?: GetOpportunitiesQueryVariables) {
      return { query: GetOpportunitiesDocument, variables: variables }
    }
export const GetOpportunityDocument = gql`
    query getOpportunity($id: UUID) {
  opportunity(id: $id) {
    ...OpportunityDetail
  }
}
    ${OpportunityDetailFragmentDoc}`;

/**
 * __useGetOpportunityQuery__
 *
 * To run a query within a React component, call `useGetOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOpportunityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpportunityQuery, GetOpportunityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpportunityQuery, GetOpportunityQueryVariables>(GetOpportunityDocument, baseOptions);
      }
export function useGetOpportunityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpportunityQuery, GetOpportunityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpportunityQuery, GetOpportunityQueryVariables>(GetOpportunityDocument, baseOptions);
        }
export type GetOpportunityQueryHookResult = ReturnType<typeof useGetOpportunityQuery>;
export type GetOpportunityLazyQueryHookResult = ReturnType<typeof useGetOpportunityLazyQuery>;
export type GetOpportunityQueryResult = ApolloReactCommon.QueryResult<GetOpportunityQuery, GetOpportunityQueryVariables>;
export function refetchGetOpportunityQuery(variables?: GetOpportunityQueryVariables) {
      return { query: GetOpportunityDocument, variables: variables }
    }
export const CreateOpportunityDocument = gql`
    mutation createOpportunity($input: CreateOpportunityMutationInput!) {
  createOpportunity(input: $input) {
    opportunity {
      ...OpportunityDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${OpportunityDetailFragmentDoc}`;
export type CreateOpportunityMutationFn = ApolloReactCommon.MutationFunction<CreateOpportunityMutation, CreateOpportunityMutationVariables>;

/**
 * __useCreateOpportunityMutation__
 *
 * To run a mutation, you first call `useCreateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpportunityMutation, { data, loading, error }] = useCreateOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpportunityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOpportunityMutation, CreateOpportunityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOpportunityMutation, CreateOpportunityMutationVariables>(CreateOpportunityDocument, baseOptions);
      }
export type CreateOpportunityMutationHookResult = ReturnType<typeof useCreateOpportunityMutation>;
export type CreateOpportunityMutationResult = ApolloReactCommon.MutationResult<CreateOpportunityMutation>;
export type CreateOpportunityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOpportunityMutation, CreateOpportunityMutationVariables>;
export const UpdateOpportunityDocument = gql`
    mutation updateOpportunity($input: UpdateOpportunityMutationInput!) {
  updateOpportunity(input: $input) {
    opportunity {
      ...OpportunityDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${OpportunityDetailFragmentDoc}`;
export type UpdateOpportunityMutationFn = ApolloReactCommon.MutationFunction<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>;

/**
 * __useUpdateOpportunityMutation__
 *
 * To run a mutation, you first call `useUpdateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpportunityMutation, { data, loading, error }] = useUpdateOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpportunityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>(UpdateOpportunityDocument, baseOptions);
      }
export type UpdateOpportunityMutationHookResult = ReturnType<typeof useUpdateOpportunityMutation>;
export type UpdateOpportunityMutationResult = ApolloReactCommon.MutationResult<UpdateOpportunityMutation>;
export type UpdateOpportunityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>;
export const DeleteOpportunityDocument = gql`
    mutation deleteOpportunity($input: DeleteOpportunityMutationInput!) {
  deleteOpportunity(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteOpportunityMutationFn = ApolloReactCommon.MutationFunction<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>;

/**
 * __useDeleteOpportunityMutation__
 *
 * To run a mutation, you first call `useDeleteOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpportunityMutation, { data, loading, error }] = useDeleteOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOpportunityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>(DeleteOpportunityDocument, baseOptions);
      }
export type DeleteOpportunityMutationHookResult = ReturnType<typeof useDeleteOpportunityMutation>;
export type DeleteOpportunityMutationResult = ApolloReactCommon.MutationResult<DeleteOpportunityMutation>;
export type DeleteOpportunityMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>;
export const SetOpportunityReadDocument = gql`
    mutation SetOpportunityRead($input: SetOpportunityReadMutationInput!) {
  setOpportunityRead(input: $input) {
    opportunity {
      read
      readBy {
        ...MinimalUser
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${MinimalUserFragmentDoc}`;
export type SetOpportunityReadMutationFn = ApolloReactCommon.MutationFunction<SetOpportunityReadMutation, SetOpportunityReadMutationVariables>;

/**
 * __useSetOpportunityReadMutation__
 *
 * To run a mutation, you first call `useSetOpportunityReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOpportunityReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOpportunityReadMutation, { data, loading, error }] = useSetOpportunityReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOpportunityReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetOpportunityReadMutation, SetOpportunityReadMutationVariables>) {
        return ApolloReactHooks.useMutation<SetOpportunityReadMutation, SetOpportunityReadMutationVariables>(SetOpportunityReadDocument, baseOptions);
      }
export type SetOpportunityReadMutationHookResult = ReturnType<typeof useSetOpportunityReadMutation>;
export type SetOpportunityReadMutationResult = ApolloReactCommon.MutationResult<SetOpportunityReadMutation>;
export type SetOpportunityReadMutationOptions = ApolloReactCommon.BaseMutationOptions<SetOpportunityReadMutation, SetOpportunityReadMutationVariables>;
export const GetOpportunityChannelsDocument = gql`
    query getOpportunityChannels {
  opportunityChannels {
    ...OpportunityChannelOption
  }
}
    ${OpportunityChannelOptionFragmentDoc}`;

/**
 * __useGetOpportunityChannelsQuery__
 *
 * To run a query within a React component, call `useGetOpportunityChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpportunityChannelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpportunityChannelsQuery, GetOpportunityChannelsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpportunityChannelsQuery, GetOpportunityChannelsQueryVariables>(GetOpportunityChannelsDocument, baseOptions);
      }
export function useGetOpportunityChannelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpportunityChannelsQuery, GetOpportunityChannelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpportunityChannelsQuery, GetOpportunityChannelsQueryVariables>(GetOpportunityChannelsDocument, baseOptions);
        }
export type GetOpportunityChannelsQueryHookResult = ReturnType<typeof useGetOpportunityChannelsQuery>;
export type GetOpportunityChannelsLazyQueryHookResult = ReturnType<typeof useGetOpportunityChannelsLazyQuery>;
export type GetOpportunityChannelsQueryResult = ApolloReactCommon.QueryResult<GetOpportunityChannelsQuery, GetOpportunityChannelsQueryVariables>;
export function refetchGetOpportunityChannelsQuery(variables?: GetOpportunityChannelsQueryVariables) {
      return { query: GetOpportunityChannelsDocument, variables: variables }
    }
export const GetOpportunityReasonsDocument = gql`
    query getOpportunityReasons {
  opportunityReasons {
    ...OpportunityReasonOption
  }
}
    ${OpportunityReasonOptionFragmentDoc}`;

/**
 * __useGetOpportunityReasonsQuery__
 *
 * To run a query within a React component, call `useGetOpportunityReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunityReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunityReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpportunityReasonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpportunityReasonsQuery, GetOpportunityReasonsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpportunityReasonsQuery, GetOpportunityReasonsQueryVariables>(GetOpportunityReasonsDocument, baseOptions);
      }
export function useGetOpportunityReasonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpportunityReasonsQuery, GetOpportunityReasonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpportunityReasonsQuery, GetOpportunityReasonsQueryVariables>(GetOpportunityReasonsDocument, baseOptions);
        }
export type GetOpportunityReasonsQueryHookResult = ReturnType<typeof useGetOpportunityReasonsQuery>;
export type GetOpportunityReasonsLazyQueryHookResult = ReturnType<typeof useGetOpportunityReasonsLazyQuery>;
export type GetOpportunityReasonsQueryResult = ApolloReactCommon.QueryResult<GetOpportunityReasonsQuery, GetOpportunityReasonsQueryVariables>;
export function refetchGetOpportunityReasonsQuery(variables?: GetOpportunityReasonsQueryVariables) {
      return { query: GetOpportunityReasonsDocument, variables: variables }
    }
export const GetOpportunitySituationsDocument = gql`
    query getOpportunitySituations {
  opportunitySituations {
    ...OpportunitySituationOption
  }
}
    ${OpportunitySituationOptionFragmentDoc}`;

/**
 * __useGetOpportunitySituationsQuery__
 *
 * To run a query within a React component, call `useGetOpportunitySituationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpportunitySituationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpportunitySituationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpportunitySituationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpportunitySituationsQuery, GetOpportunitySituationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpportunitySituationsQuery, GetOpportunitySituationsQueryVariables>(GetOpportunitySituationsDocument, baseOptions);
      }
export function useGetOpportunitySituationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpportunitySituationsQuery, GetOpportunitySituationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpportunitySituationsQuery, GetOpportunitySituationsQueryVariables>(GetOpportunitySituationsDocument, baseOptions);
        }
export type GetOpportunitySituationsQueryHookResult = ReturnType<typeof useGetOpportunitySituationsQuery>;
export type GetOpportunitySituationsLazyQueryHookResult = ReturnType<typeof useGetOpportunitySituationsLazyQuery>;
export type GetOpportunitySituationsQueryResult = ApolloReactCommon.QueryResult<GetOpportunitySituationsQuery, GetOpportunitySituationsQueryVariables>;
export function refetchGetOpportunitySituationsQuery(variables?: GetOpportunitySituationsQueryVariables) {
      return { query: GetOpportunitySituationsDocument, variables: variables }
    }
export const GetProductDocument = gql`
    query getProduct($productId: UUID) {
  product(id: $productId) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
      }
export function useGetProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = ApolloReactCommon.QueryResult<GetProductQuery, GetProductQueryVariables>;
export function refetchGetProductQuery(variables?: GetProductQueryVariables) {
      return { query: GetProductDocument, variables: variables }
    }
export const GetProductByExternalIdDocument = gql`
    query getProductByExternalId($externalId: String) {
  productByExternalId(id: $externalId) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductByExternalIdQuery__
 *
 * To run a query within a React component, call `useGetProductByExternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByExternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByExternalIdQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetProductByExternalIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductByExternalIdQuery, GetProductByExternalIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductByExternalIdQuery, GetProductByExternalIdQueryVariables>(GetProductByExternalIdDocument, baseOptions);
      }
export function useGetProductByExternalIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductByExternalIdQuery, GetProductByExternalIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductByExternalIdQuery, GetProductByExternalIdQueryVariables>(GetProductByExternalIdDocument, baseOptions);
        }
export type GetProductByExternalIdQueryHookResult = ReturnType<typeof useGetProductByExternalIdQuery>;
export type GetProductByExternalIdLazyQueryHookResult = ReturnType<typeof useGetProductByExternalIdLazyQuery>;
export type GetProductByExternalIdQueryResult = ApolloReactCommon.QueryResult<GetProductByExternalIdQuery, GetProductByExternalIdQueryVariables>;
export function refetchGetProductByExternalIdQuery(variables?: GetProductByExternalIdQueryVariables) {
      return { query: GetProductByExternalIdDocument, variables: variables }
    }
export const GetProductsByExternalIdDocument = gql`
    query getProductsByExternalId($externalIds: [String]) {
  someProductsByExternalId(exIds: $externalIds) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductsByExternalIdQuery__
 *
 * To run a query within a React component, call `useGetProductsByExternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByExternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByExternalIdQuery({
 *   variables: {
 *      externalIds: // value for 'externalIds'
 *   },
 * });
 */
export function useGetProductsByExternalIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsByExternalIdQuery, GetProductsByExternalIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsByExternalIdQuery, GetProductsByExternalIdQueryVariables>(GetProductsByExternalIdDocument, baseOptions);
      }
export function useGetProductsByExternalIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsByExternalIdQuery, GetProductsByExternalIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsByExternalIdQuery, GetProductsByExternalIdQueryVariables>(GetProductsByExternalIdDocument, baseOptions);
        }
export type GetProductsByExternalIdQueryHookResult = ReturnType<typeof useGetProductsByExternalIdQuery>;
export type GetProductsByExternalIdLazyQueryHookResult = ReturnType<typeof useGetProductsByExternalIdLazyQuery>;
export type GetProductsByExternalIdQueryResult = ApolloReactCommon.QueryResult<GetProductsByExternalIdQuery, GetProductsByExternalIdQueryVariables>;
export function refetchGetProductsByExternalIdQuery(variables?: GetProductsByExternalIdQueryVariables) {
      return { query: GetProductsByExternalIdDocument, variables: variables }
    }
export const GetProductsDocument = gql`
    query getProducts {
  products {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
      }
export function useGetProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = ApolloReactCommon.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export function refetchGetProductsQuery(variables?: GetProductsQueryVariables) {
      return { query: GetProductsDocument, variables: variables }
    }
export const GetProductsCurrentDocument = gql`
    query getProductsCurrent($options: ProductCurrentOptionsInputType) {
  productsCurrent(options: $options) {
    items {
      ...Product
    }
    count
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductsCurrentQuery__
 *
 * To run a query within a React component, call `useGetProductsCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsCurrentQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetProductsCurrentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsCurrentQuery, GetProductsCurrentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsCurrentQuery, GetProductsCurrentQueryVariables>(GetProductsCurrentDocument, baseOptions);
      }
export function useGetProductsCurrentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsCurrentQuery, GetProductsCurrentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsCurrentQuery, GetProductsCurrentQueryVariables>(GetProductsCurrentDocument, baseOptions);
        }
export type GetProductsCurrentQueryHookResult = ReturnType<typeof useGetProductsCurrentQuery>;
export type GetProductsCurrentLazyQueryHookResult = ReturnType<typeof useGetProductsCurrentLazyQuery>;
export type GetProductsCurrentQueryResult = ApolloReactCommon.QueryResult<GetProductsCurrentQuery, GetProductsCurrentQueryVariables>;
export function refetchGetProductsCurrentQuery(variables?: GetProductsCurrentQueryVariables) {
      return { query: GetProductsCurrentDocument, variables: variables }
    }
export const GetProductsByCountryDocument = gql`
    query getProductsByCountry($countryId: UUID) {
  productsByCountry(id: $countryId) {
    ...ProductOption
  }
}
    ${ProductOptionFragmentDoc}`;

/**
 * __useGetProductsByCountryQuery__
 *
 * To run a query within a React component, call `useGetProductsByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByCountryQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetProductsByCountryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductsByCountryQuery, GetProductsByCountryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductsByCountryQuery, GetProductsByCountryQueryVariables>(GetProductsByCountryDocument, baseOptions);
      }
export function useGetProductsByCountryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductsByCountryQuery, GetProductsByCountryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductsByCountryQuery, GetProductsByCountryQueryVariables>(GetProductsByCountryDocument, baseOptions);
        }
export type GetProductsByCountryQueryHookResult = ReturnType<typeof useGetProductsByCountryQuery>;
export type GetProductsByCountryLazyQueryHookResult = ReturnType<typeof useGetProductsByCountryLazyQuery>;
export type GetProductsByCountryQueryResult = ApolloReactCommon.QueryResult<GetProductsByCountryQuery, GetProductsByCountryQueryVariables>;
export function refetchGetProductsByCountryQuery(variables?: GetProductsByCountryQueryVariables) {
      return { query: GetProductsByCountryDocument, variables: variables }
    }
export const GetPromotionsDocument = gql`
    query getPromotions {
  promotions {
    ...Promotion
  }
}
    ${PromotionFragmentDoc}`;

/**
 * __useGetPromotionsQuery__
 *
 * To run a query within a React component, call `useGetPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPromotionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPromotionsQuery, GetPromotionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(GetPromotionsDocument, baseOptions);
      }
export function useGetPromotionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPromotionsQuery, GetPromotionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(GetPromotionsDocument, baseOptions);
        }
export type GetPromotionsQueryHookResult = ReturnType<typeof useGetPromotionsQuery>;
export type GetPromotionsLazyQueryHookResult = ReturnType<typeof useGetPromotionsLazyQuery>;
export type GetPromotionsQueryResult = ApolloReactCommon.QueryResult<GetPromotionsQuery, GetPromotionsQueryVariables>;
export function refetchGetPromotionsQuery(variables?: GetPromotionsQueryVariables) {
      return { query: GetPromotionsDocument, variables: variables }
    }
export const GetProductOptionsDocument = gql`
    query getProductOptions {
  products {
    ...ProductOption
  }
}
    ${ProductOptionFragmentDoc}`;

/**
 * __useGetProductOptionsQuery__
 *
 * To run a query within a React component, call `useGetProductOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductOptionsQuery, GetProductOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductOptionsQuery, GetProductOptionsQueryVariables>(GetProductOptionsDocument, baseOptions);
      }
export function useGetProductOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductOptionsQuery, GetProductOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductOptionsQuery, GetProductOptionsQueryVariables>(GetProductOptionsDocument, baseOptions);
        }
export type GetProductOptionsQueryHookResult = ReturnType<typeof useGetProductOptionsQuery>;
export type GetProductOptionsLazyQueryHookResult = ReturnType<typeof useGetProductOptionsLazyQuery>;
export type GetProductOptionsQueryResult = ApolloReactCommon.QueryResult<GetProductOptionsQuery, GetProductOptionsQueryVariables>;
export function refetchGetProductOptionsQuery(variables?: GetProductOptionsQueryVariables) {
      return { query: GetProductOptionsDocument, variables: variables }
    }
export const GetProductListsDocument = gql`
    query getProductLists {
  productLists {
    ...ProductList
  }
}
    ${ProductListFragmentDoc}`;

/**
 * __useGetProductListsQuery__
 *
 * To run a query within a React component, call `useGetProductListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductListsQuery, GetProductListsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductListsQuery, GetProductListsQueryVariables>(GetProductListsDocument, baseOptions);
      }
export function useGetProductListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductListsQuery, GetProductListsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductListsQuery, GetProductListsQueryVariables>(GetProductListsDocument, baseOptions);
        }
export type GetProductListsQueryHookResult = ReturnType<typeof useGetProductListsQuery>;
export type GetProductListsLazyQueryHookResult = ReturnType<typeof useGetProductListsLazyQuery>;
export type GetProductListsQueryResult = ApolloReactCommon.QueryResult<GetProductListsQuery, GetProductListsQueryVariables>;
export function refetchGetProductListsQuery(variables?: GetProductListsQueryVariables) {
      return { query: GetProductListsDocument, variables: variables }
    }
export const GetUnitsDocument = gql`
    query getUnits {
  units {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, baseOptions);
      }
export function useGetUnitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, baseOptions);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsQueryResult = ApolloReactCommon.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export function refetchGetUnitsQuery(variables?: GetUnitsQueryVariables) {
      return { query: GetUnitsDocument, variables: variables }
    }
export const GetQuotesDocument = gql`
    query getQuotes($options: QuoteListOptionsInputType) {
  quotes(options: $options) {
    items {
      ...Quote
    }
    count
  }
}
    ${QuoteFragmentDoc}`;

/**
 * __useGetQuotesQuery__
 *
 * To run a query within a React component, call `useGetQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetQuotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, baseOptions);
      }
export function useGetQuotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, baseOptions);
        }
export type GetQuotesQueryHookResult = ReturnType<typeof useGetQuotesQuery>;
export type GetQuotesLazyQueryHookResult = ReturnType<typeof useGetQuotesLazyQuery>;
export type GetQuotesQueryResult = ApolloReactCommon.QueryResult<GetQuotesQuery, GetQuotesQueryVariables>;
export function refetchGetQuotesQuery(variables?: GetQuotesQueryVariables) {
      return { query: GetQuotesDocument, variables: variables }
    }
export const GetQuoteDocument = gql`
    query getQuote($id: UUID) {
  quote(id: $id) {
    ...QuoteDetail
  }
}
    ${QuoteDetailFragmentDoc}`;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, baseOptions);
      }
export function useGetQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, baseOptions);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = ApolloReactCommon.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export function refetchGetQuoteQuery(variables?: GetQuoteQueryVariables) {
      return { query: GetQuoteDocument, variables: variables }
    }
export const GetQuotesUserDocument = gql`
    query getQuotesUser($user: String) {
  quotesUser(user: $user) {
    items {
      ...Quote
    }
    count
  }
}
    ${QuoteFragmentDoc}`;

/**
 * __useGetQuotesUserQuery__
 *
 * To run a query within a React component, call `useGetQuotesUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesUserQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetQuotesUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQuotesUserQuery, GetQuotesUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQuotesUserQuery, GetQuotesUserQueryVariables>(GetQuotesUserDocument, baseOptions);
      }
export function useGetQuotesUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuotesUserQuery, GetQuotesUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQuotesUserQuery, GetQuotesUserQueryVariables>(GetQuotesUserDocument, baseOptions);
        }
export type GetQuotesUserQueryHookResult = ReturnType<typeof useGetQuotesUserQuery>;
export type GetQuotesUserLazyQueryHookResult = ReturnType<typeof useGetQuotesUserLazyQuery>;
export type GetQuotesUserQueryResult = ApolloReactCommon.QueryResult<GetQuotesUserQuery, GetQuotesUserQueryVariables>;
export function refetchGetQuotesUserQuery(variables?: GetQuotesUserQueryVariables) {
      return { query: GetQuotesUserDocument, variables: variables }
    }
export const CreateQuoteDocument = gql`
    mutation createQuote($input: CreateQuoteMutationInput!) {
  createQuote(input: $input) {
    quote {
      ...QuoteDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteDetailFragmentDoc}`;
export type CreateQuoteMutationFn = ApolloReactCommon.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, baseOptions);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = ApolloReactCommon.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const UpdateQuoteDocument = gql`
    mutation updateQuote($input: UpdateQuoteMutationInput!) {
  updateQuote(input: $input) {
    quote {
      ...QuoteDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteDetailFragmentDoc}`;
export type UpdateQuoteMutationFn = ApolloReactCommon.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>;

/**
 * __useUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteMutation, { data, loading, error }] = useUpdateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument, baseOptions);
      }
export type UpdateQuoteMutationHookResult = ReturnType<typeof useUpdateQuoteMutation>;
export type UpdateQuoteMutationResult = ApolloReactCommon.MutationResult<UpdateQuoteMutation>;
export type UpdateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>;
export const DeleteQuoteDocument = gql`
    mutation deleteQuote($input: DeleteQuoteMutationInput!) {
  deleteQuote(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteQuoteMutationFn = ApolloReactCommon.MutationFunction<DeleteQuoteMutation, DeleteQuoteMutationVariables>;

/**
 * __useDeleteQuoteMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteMutation, { data, loading, error }] = useDeleteQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQuoteMutation, DeleteQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteQuoteMutation, DeleteQuoteMutationVariables>(DeleteQuoteDocument, baseOptions);
      }
export type DeleteQuoteMutationHookResult = ReturnType<typeof useDeleteQuoteMutation>;
export type DeleteQuoteMutationResult = ApolloReactCommon.MutationResult<DeleteQuoteMutation>;
export type DeleteQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQuoteMutation, DeleteQuoteMutationVariables>;
export const CreateQuoteCommentDocument = gql`
    mutation createQuoteComment($input: CreateQuoteCommentMutationInput!) {
  createQuoteComment(input: $input) {
    quoteComment {
      id
      message
      created
      createdBy {
        firstName
        lastName
        id
      }
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateQuoteCommentMutationFn = ApolloReactCommon.MutationFunction<CreateQuoteCommentMutation, CreateQuoteCommentMutationVariables>;

/**
 * __useCreateQuoteCommentMutation__
 *
 * To run a mutation, you first call `useCreateQuoteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteCommentMutation, { data, loading, error }] = useCreateQuoteCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuoteCommentMutation, CreateQuoteCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQuoteCommentMutation, CreateQuoteCommentMutationVariables>(CreateQuoteCommentDocument, baseOptions);
      }
export type CreateQuoteCommentMutationHookResult = ReturnType<typeof useCreateQuoteCommentMutation>;
export type CreateQuoteCommentMutationResult = ApolloReactCommon.MutationResult<CreateQuoteCommentMutation>;
export type CreateQuoteCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuoteCommentMutation, CreateQuoteCommentMutationVariables>;
export const DeleteQuoteCommentDocument = gql`
    mutation deleteQuoteComment($input: DeleteQuoteCommentMutationInput!) {
  deleteQuoteComment(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteQuoteCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteQuoteCommentMutation, DeleteQuoteCommentMutationVariables>;

/**
 * __useDeleteQuoteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteCommentMutation, { data, loading, error }] = useDeleteQuoteCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuoteCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQuoteCommentMutation, DeleteQuoteCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteQuoteCommentMutation, DeleteQuoteCommentMutationVariables>(DeleteQuoteCommentDocument, baseOptions);
      }
export type DeleteQuoteCommentMutationHookResult = ReturnType<typeof useDeleteQuoteCommentMutation>;
export type DeleteQuoteCommentMutationResult = ApolloReactCommon.MutationResult<DeleteQuoteCommentMutation>;
export type DeleteQuoteCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQuoteCommentMutation, DeleteQuoteCommentMutationVariables>;
export const CreateQuotePdfDocument = gql`
    mutation createQuotePDF($input: CreateQuotePDFMutationInput!) {
  createQuotePDF(input: $input) {
    quotePDF {
      file
      slug
      quote {
        ...QuoteDetail
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteDetailFragmentDoc}`;
export type CreateQuotePdfMutationFn = ApolloReactCommon.MutationFunction<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>;

/**
 * __useCreateQuotePdfMutation__
 *
 * To run a mutation, you first call `useCreateQuotePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuotePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuotePdfMutation, { data, loading, error }] = useCreateQuotePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuotePdfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>(CreateQuotePdfDocument, baseOptions);
      }
export type CreateQuotePdfMutationHookResult = ReturnType<typeof useCreateQuotePdfMutation>;
export type CreateQuotePdfMutationResult = ApolloReactCommon.MutationResult<CreateQuotePdfMutation>;
export type CreateQuotePdfMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>;
export const GetQuoteOrderTypesDocument = gql`
    query getQuoteOrderTypes {
  quoteOrderTypes {
    ...QuoteOrderType
  }
}
    ${QuoteOrderTypeFragmentDoc}`;

/**
 * __useGetQuoteOrderTypesQuery__
 *
 * To run a query within a React component, call `useGetQuoteOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuoteOrderTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQuoteOrderTypesQuery, GetQuoteOrderTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQuoteOrderTypesQuery, GetQuoteOrderTypesQueryVariables>(GetQuoteOrderTypesDocument, baseOptions);
      }
export function useGetQuoteOrderTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuoteOrderTypesQuery, GetQuoteOrderTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQuoteOrderTypesQuery, GetQuoteOrderTypesQueryVariables>(GetQuoteOrderTypesDocument, baseOptions);
        }
export type GetQuoteOrderTypesQueryHookResult = ReturnType<typeof useGetQuoteOrderTypesQuery>;
export type GetQuoteOrderTypesLazyQueryHookResult = ReturnType<typeof useGetQuoteOrderTypesLazyQuery>;
export type GetQuoteOrderTypesQueryResult = ApolloReactCommon.QueryResult<GetQuoteOrderTypesQuery, GetQuoteOrderTypesQueryVariables>;
export function refetchGetQuoteOrderTypesQuery(variables?: GetQuoteOrderTypesQueryVariables) {
      return { query: GetQuoteOrderTypesDocument, variables: variables }
    }
export const GetPaymentMethodsDocument = gql`
    query getPaymentMethods {
  paymentMethods {
    ...PaymentMethod
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export function refetchGetPaymentMethodsQuery(variables?: GetPaymentMethodsQueryVariables) {
      return { query: GetPaymentMethodsDocument, variables: variables }
    }
export const GetPaymentMethodOptionsDocument = gql`
    query getPaymentMethodOptions {
  paymentMethods {
    ...PaymentMethodOption
  }
}
    ${PaymentMethodOptionFragmentDoc}`;

/**
 * __useGetPaymentMethodOptionsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodOptionsQuery, GetPaymentMethodOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodOptionsQuery, GetPaymentMethodOptionsQueryVariables>(GetPaymentMethodOptionsDocument, baseOptions);
      }
export function useGetPaymentMethodOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodOptionsQuery, GetPaymentMethodOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodOptionsQuery, GetPaymentMethodOptionsQueryVariables>(GetPaymentMethodOptionsDocument, baseOptions);
        }
export type GetPaymentMethodOptionsQueryHookResult = ReturnType<typeof useGetPaymentMethodOptionsQuery>;
export type GetPaymentMethodOptionsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodOptionsLazyQuery>;
export type GetPaymentMethodOptionsQueryResult = ApolloReactCommon.QueryResult<GetPaymentMethodOptionsQuery, GetPaymentMethodOptionsQueryVariables>;
export function refetchGetPaymentMethodOptionsQuery(variables?: GetPaymentMethodOptionsQueryVariables) {
      return { query: GetPaymentMethodOptionsDocument, variables: variables }
    }
export const GetSalesTargetUserDocument = gql`
    query getSalesTargetUser($user: String!) {
  salesTargetUser(user: $user) {
    ...SalesTargetDetail
  }
}
    ${SalesTargetDetailFragmentDoc}`;

/**
 * __useGetSalesTargetUserQuery__
 *
 * To run a query within a React component, call `useGetSalesTargetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesTargetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesTargetUserQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetSalesTargetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesTargetUserQuery, GetSalesTargetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesTargetUserQuery, GetSalesTargetUserQueryVariables>(GetSalesTargetUserDocument, baseOptions);
      }
export function useGetSalesTargetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesTargetUserQuery, GetSalesTargetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesTargetUserQuery, GetSalesTargetUserQueryVariables>(GetSalesTargetUserDocument, baseOptions);
        }
export type GetSalesTargetUserQueryHookResult = ReturnType<typeof useGetSalesTargetUserQuery>;
export type GetSalesTargetUserLazyQueryHookResult = ReturnType<typeof useGetSalesTargetUserLazyQuery>;
export type GetSalesTargetUserQueryResult = ApolloReactCommon.QueryResult<GetSalesTargetUserQuery, GetSalesTargetUserQueryVariables>;
export function refetchGetSalesTargetUserQuery(variables?: GetSalesTargetUserQueryVariables) {
      return { query: GetSalesTargetUserDocument, variables: variables }
    }
export const GetSalesTargetsDocument = gql`
    query getSalesTargets($options: SalesTargetListOptionsInputType) {
  salesTargets(options: $options) {
    items {
      ...SalesTarget
    }
    count
  }
}
    ${SalesTargetFragmentDoc}`;

/**
 * __useGetSalesTargetsQuery__
 *
 * To run a query within a React component, call `useGetSalesTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesTargetsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesTargetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>(GetSalesTargetsDocument, baseOptions);
      }
export function useGetSalesTargetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>(GetSalesTargetsDocument, baseOptions);
        }
export type GetSalesTargetsQueryHookResult = ReturnType<typeof useGetSalesTargetsQuery>;
export type GetSalesTargetsLazyQueryHookResult = ReturnType<typeof useGetSalesTargetsLazyQuery>;
export type GetSalesTargetsQueryResult = ApolloReactCommon.QueryResult<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>;
export function refetchGetSalesTargetsQuery(variables?: GetSalesTargetsQueryVariables) {
      return { query: GetSalesTargetsDocument, variables: variables }
    }
export const CreateSalesTargetDocument = gql`
    mutation createSalesTarget($input: CreateSalesTargetMutationInput!) {
  createSalesTarget(input: $input) {
    salesTarget {
      ...SalesTargetDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${SalesTargetDetailFragmentDoc}`;
export type CreateSalesTargetMutationFn = ApolloReactCommon.MutationFunction<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>;

/**
 * __useCreateSalesTargetMutation__
 *
 * To run a mutation, you first call `useCreateSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesTargetMutation, { data, loading, error }] = useCreateSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesTargetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>(CreateSalesTargetDocument, baseOptions);
      }
export type CreateSalesTargetMutationHookResult = ReturnType<typeof useCreateSalesTargetMutation>;
export type CreateSalesTargetMutationResult = ApolloReactCommon.MutationResult<CreateSalesTargetMutation>;
export type CreateSalesTargetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>;
export const UpdateSalesTargetDocument = gql`
    mutation updateSalesTarget($input: UpdateSalesTargetMutationInput!) {
  updateSalesTarget(input: $input) {
    salesTarget {
      ...SalesTargetDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${SalesTargetDetailFragmentDoc}`;
export type UpdateSalesTargetMutationFn = ApolloReactCommon.MutationFunction<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>;

/**
 * __useUpdateSalesTargetMutation__
 *
 * To run a mutation, you first call `useUpdateSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesTargetMutation, { data, loading, error }] = useUpdateSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesTargetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>(UpdateSalesTargetDocument, baseOptions);
      }
export type UpdateSalesTargetMutationHookResult = ReturnType<typeof useUpdateSalesTargetMutation>;
export type UpdateSalesTargetMutationResult = ApolloReactCommon.MutationResult<UpdateSalesTargetMutation>;
export type UpdateSalesTargetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>;
export const DeleteSalesTargetDocument = gql`
    mutation deleteSalesTarget($input: DeleteSalesTargetMutationInput!) {
  deleteSalesTarget(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteSalesTargetMutationFn = ApolloReactCommon.MutationFunction<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>;

/**
 * __useDeleteSalesTargetMutation__
 *
 * To run a mutation, you first call `useDeleteSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesTargetMutation, { data, loading, error }] = useDeleteSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSalesTargetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>(DeleteSalesTargetDocument, baseOptions);
      }
export type DeleteSalesTargetMutationHookResult = ReturnType<typeof useDeleteSalesTargetMutation>;
export type DeleteSalesTargetMutationResult = ApolloReactCommon.MutationResult<DeleteSalesTargetMutation>;
export type DeleteSalesTargetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>;
export const StartSaleReviewDocument = gql`
    mutation startSaleReview($input: StartSaleReviewMutationInput!) {
  startSaleReview(input: $input) {
    sale {
      ...SaleDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type StartSaleReviewMutationFn = ApolloReactCommon.MutationFunction<StartSaleReviewMutation, StartSaleReviewMutationVariables>;

/**
 * __useStartSaleReviewMutation__
 *
 * To run a mutation, you first call `useStartSaleReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSaleReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSaleReviewMutation, { data, loading, error }] = useStartSaleReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSaleReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartSaleReviewMutation, StartSaleReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<StartSaleReviewMutation, StartSaleReviewMutationVariables>(StartSaleReviewDocument, baseOptions);
      }
export type StartSaleReviewMutationHookResult = ReturnType<typeof useStartSaleReviewMutation>;
export type StartSaleReviewMutationResult = ApolloReactCommon.MutationResult<StartSaleReviewMutation>;
export type StartSaleReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<StartSaleReviewMutation, StartSaleReviewMutationVariables>;
export const ReviewSaleDocument = gql`
    mutation reviewSale($input: SaleReviewMutationInput!) {
  reviewSale(input: $input) {
    saleReview {
      sale {
        ...SaleDetail
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type ReviewSaleMutationFn = ApolloReactCommon.MutationFunction<ReviewSaleMutation, ReviewSaleMutationVariables>;

/**
 * __useReviewSaleMutation__
 *
 * To run a mutation, you first call `useReviewSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewSaleMutation, { data, loading, error }] = useReviewSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewSaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewSaleMutation, ReviewSaleMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewSaleMutation, ReviewSaleMutationVariables>(ReviewSaleDocument, baseOptions);
      }
export type ReviewSaleMutationHookResult = ReturnType<typeof useReviewSaleMutation>;
export type ReviewSaleMutationResult = ApolloReactCommon.MutationResult<ReviewSaleMutation>;
export type ReviewSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewSaleMutation, ReviewSaleMutationVariables>;
export const CreateSaleAdministrationDocument = gql`
    mutation createSaleAdministration($input: CreateSaleAdministrationMutationInput!) {
  createSaleAdministration(input: $input) {
    saleAdministration {
      ...SaleAdministration
      sale {
        ...SaleDetail
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleAdministrationFragmentDoc}
${SaleDetailFragmentDoc}`;
export type CreateSaleAdministrationMutationFn = ApolloReactCommon.MutationFunction<CreateSaleAdministrationMutation, CreateSaleAdministrationMutationVariables>;

/**
 * __useCreateSaleAdministrationMutation__
 *
 * To run a mutation, you first call `useCreateSaleAdministrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleAdministrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleAdministrationMutation, { data, loading, error }] = useCreateSaleAdministrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSaleAdministrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSaleAdministrationMutation, CreateSaleAdministrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSaleAdministrationMutation, CreateSaleAdministrationMutationVariables>(CreateSaleAdministrationDocument, baseOptions);
      }
export type CreateSaleAdministrationMutationHookResult = ReturnType<typeof useCreateSaleAdministrationMutation>;
export type CreateSaleAdministrationMutationResult = ApolloReactCommon.MutationResult<CreateSaleAdministrationMutation>;
export type CreateSaleAdministrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSaleAdministrationMutation, CreateSaleAdministrationMutationVariables>;
export const CreateCreditNoteDocument = gql`
    mutation createCreditNote($input: CreateCreditNotesMutationInput!) {
  createCreditNote(input: $input) {
    creditNote {
      ...CreditNote
      sale {
        ...SaleDetail
      }
      orderType {
        ...QuoteOrderType
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${CreditNoteFragmentDoc}
${SaleDetailFragmentDoc}
${QuoteOrderTypeFragmentDoc}`;
export type CreateCreditNoteMutationFn = ApolloReactCommon.MutationFunction<CreateCreditNoteMutation, CreateCreditNoteMutationVariables>;

/**
 * __useCreateCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditNoteMutation, { data, loading, error }] = useCreateCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditNoteMutation, CreateCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditNoteMutation, CreateCreditNoteMutationVariables>(CreateCreditNoteDocument, baseOptions);
      }
export type CreateCreditNoteMutationHookResult = ReturnType<typeof useCreateCreditNoteMutation>;
export type CreateCreditNoteMutationResult = ApolloReactCommon.MutationResult<CreateCreditNoteMutation>;
export type CreateCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditNoteMutation, CreateCreditNoteMutationVariables>;
export const DeleteCreditNoteDocument = gql`
    mutation deleteCreditNote($input: DeleteCreditNoteMutationInput!) {
  deleteCreditNote(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteCreditNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteCreditNoteMutation, DeleteCreditNoteMutationVariables>;

/**
 * __useDeleteCreditNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditNoteMutation, { data, loading, error }] = useDeleteCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCreditNoteMutation, DeleteCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCreditNoteMutation, DeleteCreditNoteMutationVariables>(DeleteCreditNoteDocument, baseOptions);
      }
export type DeleteCreditNoteMutationHookResult = ReturnType<typeof useDeleteCreditNoteMutation>;
export type DeleteCreditNoteMutationResult = ApolloReactCommon.MutationResult<DeleteCreditNoteMutation>;
export type DeleteCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCreditNoteMutation, DeleteCreditNoteMutationVariables>;
export const UpdateCreditNoteDocument = gql`
    mutation updateCreditNote($input: UpdateCreditNoteMutationInput!) {
  updateCreditNote(input: $input) {
    creditNote {
      ...CreditNote
      sale {
        ...SaleDetail
      }
      orderType {
        ...QuoteOrderType
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${CreditNoteFragmentDoc}
${SaleDetailFragmentDoc}
${QuoteOrderTypeFragmentDoc}`;
export type UpdateCreditNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditNoteMutation, UpdateCreditNoteMutationVariables>;

/**
 * __useUpdateCreditNoteMutation__
 *
 * To run a mutation, you first call `useUpdateCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditNoteMutation, { data, loading, error }] = useUpdateCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditNoteMutation, UpdateCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditNoteMutation, UpdateCreditNoteMutationVariables>(UpdateCreditNoteDocument, baseOptions);
      }
export type UpdateCreditNoteMutationHookResult = ReturnType<typeof useUpdateCreditNoteMutation>;
export type UpdateCreditNoteMutationResult = ApolloReactCommon.MutationResult<UpdateCreditNoteMutation>;
export type UpdateCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditNoteMutation, UpdateCreditNoteMutationVariables>;
export const CreateCreditNoteReviewDocument = gql`
    mutation createCreditNoteReview($input: CreateCreditNoteReviewMutationInput!) {
  createCreditNoteReview(input: $input) {
    creditNoteReview {
      creditNotes {
        ...CreditNote
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${CreditNoteFragmentDoc}`;
export type CreateCreditNoteReviewMutationFn = ApolloReactCommon.MutationFunction<CreateCreditNoteReviewMutation, CreateCreditNoteReviewMutationVariables>;

/**
 * __useCreateCreditNoteReviewMutation__
 *
 * To run a mutation, you first call `useCreateCreditNoteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditNoteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditNoteReviewMutation, { data, loading, error }] = useCreateCreditNoteReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditNoteReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditNoteReviewMutation, CreateCreditNoteReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditNoteReviewMutation, CreateCreditNoteReviewMutationVariables>(CreateCreditNoteReviewDocument, baseOptions);
      }
export type CreateCreditNoteReviewMutationHookResult = ReturnType<typeof useCreateCreditNoteReviewMutation>;
export type CreateCreditNoteReviewMutationResult = ApolloReactCommon.MutationResult<CreateCreditNoteReviewMutation>;
export type CreateCreditNoteReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditNoteReviewMutation, CreateCreditNoteReviewMutationVariables>;
export const DeleteSaleAdministrationDocument = gql`
    mutation deleteSaleAdministration($input: DeleteSaleAdministrationMutationInput!) {
  deleteSaleAdministration(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteSaleAdministrationMutationFn = ApolloReactCommon.MutationFunction<DeleteSaleAdministrationMutation, DeleteSaleAdministrationMutationVariables>;

/**
 * __useDeleteSaleAdministrationMutation__
 *
 * To run a mutation, you first call `useDeleteSaleAdministrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSaleAdministrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSaleAdministrationMutation, { data, loading, error }] = useDeleteSaleAdministrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSaleAdministrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSaleAdministrationMutation, DeleteSaleAdministrationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSaleAdministrationMutation, DeleteSaleAdministrationMutationVariables>(DeleteSaleAdministrationDocument, baseOptions);
      }
export type DeleteSaleAdministrationMutationHookResult = ReturnType<typeof useDeleteSaleAdministrationMutation>;
export type DeleteSaleAdministrationMutationResult = ApolloReactCommon.MutationResult<DeleteSaleAdministrationMutation>;
export type DeleteSaleAdministrationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSaleAdministrationMutation, DeleteSaleAdministrationMutationVariables>;
export const UpdateSaleAdministrationDocument = gql`
    mutation updateSaleAdministration($input: UpdateSaleAdministrationMutationInput!) {
  updateSaleAdministration(input: $input) {
    saleAdministration {
      sale {
        ...SaleDetail
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type UpdateSaleAdministrationMutationFn = ApolloReactCommon.MutationFunction<UpdateSaleAdministrationMutation, UpdateSaleAdministrationMutationVariables>;

/**
 * __useUpdateSaleAdministrationMutation__
 *
 * To run a mutation, you first call `useUpdateSaleAdministrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleAdministrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleAdministrationMutation, { data, loading, error }] = useUpdateSaleAdministrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleAdministrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSaleAdministrationMutation, UpdateSaleAdministrationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSaleAdministrationMutation, UpdateSaleAdministrationMutationVariables>(UpdateSaleAdministrationDocument, baseOptions);
      }
export type UpdateSaleAdministrationMutationHookResult = ReturnType<typeof useUpdateSaleAdministrationMutation>;
export type UpdateSaleAdministrationMutationResult = ApolloReactCommon.MutationResult<UpdateSaleAdministrationMutation>;
export type UpdateSaleAdministrationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSaleAdministrationMutation, UpdateSaleAdministrationMutationVariables>;
export const GetSuccessChanceOptionsDocument = gql`
    query getSuccessChanceOptions {
  successChances {
    id
    name
  }
}
    `;

/**
 * __useGetSuccessChanceOptionsQuery__
 *
 * To run a query within a React component, call `useGetSuccessChanceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuccessChanceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuccessChanceOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuccessChanceOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSuccessChanceOptionsQuery, GetSuccessChanceOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuccessChanceOptionsQuery, GetSuccessChanceOptionsQueryVariables>(GetSuccessChanceOptionsDocument, baseOptions);
      }
export function useGetSuccessChanceOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuccessChanceOptionsQuery, GetSuccessChanceOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuccessChanceOptionsQuery, GetSuccessChanceOptionsQueryVariables>(GetSuccessChanceOptionsDocument, baseOptions);
        }
export type GetSuccessChanceOptionsQueryHookResult = ReturnType<typeof useGetSuccessChanceOptionsQuery>;
export type GetSuccessChanceOptionsLazyQueryHookResult = ReturnType<typeof useGetSuccessChanceOptionsLazyQuery>;
export type GetSuccessChanceOptionsQueryResult = ApolloReactCommon.QueryResult<GetSuccessChanceOptionsQuery, GetSuccessChanceOptionsQueryVariables>;
export function refetchGetSuccessChanceOptionsQuery(variables?: GetSuccessChanceOptionsQueryVariables) {
      return { query: GetSuccessChanceOptionsDocument, variables: variables }
    }
export const GetSalesApprovedDocument = gql`
    query getSalesApproved($options: SalesApprovedListOptionsInputType) {
  salesApproved(options: $options) {
    items {
      ...Sale
    }
    count
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSalesApprovedQuery__
 *
 * To run a query within a React component, call `useGetSalesApprovedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesApprovedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesApprovedQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesApprovedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesApprovedQuery, GetSalesApprovedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesApprovedQuery, GetSalesApprovedQueryVariables>(GetSalesApprovedDocument, baseOptions);
      }
export function useGetSalesApprovedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesApprovedQuery, GetSalesApprovedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesApprovedQuery, GetSalesApprovedQueryVariables>(GetSalesApprovedDocument, baseOptions);
        }
export type GetSalesApprovedQueryHookResult = ReturnType<typeof useGetSalesApprovedQuery>;
export type GetSalesApprovedLazyQueryHookResult = ReturnType<typeof useGetSalesApprovedLazyQuery>;
export type GetSalesApprovedQueryResult = ApolloReactCommon.QueryResult<GetSalesApprovedQuery, GetSalesApprovedQueryVariables>;
export function refetchGetSalesApprovedQuery(variables?: GetSalesApprovedQueryVariables) {
      return { query: GetSalesApprovedDocument, variables: variables }
    }
export const GetSalesApprovedAllDocument = gql`
    query getSalesApprovedAll($options: SalesApprovedListOptionsInputType) {
  salesApprovedAll(options: $options) {
    items {
      ...Sale
    }
    count
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSalesApprovedAllQuery__
 *
 * To run a query within a React component, call `useGetSalesApprovedAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesApprovedAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesApprovedAllQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesApprovedAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesApprovedAllQuery, GetSalesApprovedAllQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesApprovedAllQuery, GetSalesApprovedAllQueryVariables>(GetSalesApprovedAllDocument, baseOptions);
      }
export function useGetSalesApprovedAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesApprovedAllQuery, GetSalesApprovedAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesApprovedAllQuery, GetSalesApprovedAllQueryVariables>(GetSalesApprovedAllDocument, baseOptions);
        }
export type GetSalesApprovedAllQueryHookResult = ReturnType<typeof useGetSalesApprovedAllQuery>;
export type GetSalesApprovedAllLazyQueryHookResult = ReturnType<typeof useGetSalesApprovedAllLazyQuery>;
export type GetSalesApprovedAllQueryResult = ApolloReactCommon.QueryResult<GetSalesApprovedAllQuery, GetSalesApprovedAllQueryVariables>;
export function refetchGetSalesApprovedAllQuery(variables?: GetSalesApprovedAllQueryVariables) {
      return { query: GetSalesApprovedAllDocument, variables: variables }
    }
export const GetSalesCreditsAllDocument = gql`
    query getSalesCreditsAll($options: SalesCreditsListOptionsInputType) {
  salesCreditsAll(options: $options) {
    items {
      ...Sale
    }
    count
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSalesCreditsAllQuery__
 *
 * To run a query within a React component, call `useGetSalesCreditsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesCreditsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesCreditsAllQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesCreditsAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesCreditsAllQuery, GetSalesCreditsAllQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesCreditsAllQuery, GetSalesCreditsAllQueryVariables>(GetSalesCreditsAllDocument, baseOptions);
      }
export function useGetSalesCreditsAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesCreditsAllQuery, GetSalesCreditsAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesCreditsAllQuery, GetSalesCreditsAllQueryVariables>(GetSalesCreditsAllDocument, baseOptions);
        }
export type GetSalesCreditsAllQueryHookResult = ReturnType<typeof useGetSalesCreditsAllQuery>;
export type GetSalesCreditsAllLazyQueryHookResult = ReturnType<typeof useGetSalesCreditsAllLazyQuery>;
export type GetSalesCreditsAllQueryResult = ApolloReactCommon.QueryResult<GetSalesCreditsAllQuery, GetSalesCreditsAllQueryVariables>;
export function refetchGetSalesCreditsAllQuery(variables?: GetSalesCreditsAllQueryVariables) {
      return { query: GetSalesCreditsAllDocument, variables: variables }
    }
export const GetReasonApprovalDocument = gql`
    query getReasonApproval {
  reasonApproval {
    ...ReasonApproval
  }
}
    ${ReasonApprovalFragmentDoc}`;

/**
 * __useGetReasonApprovalQuery__
 *
 * To run a query within a React component, call `useGetReasonApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonApprovalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReasonApprovalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReasonApprovalQuery, GetReasonApprovalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReasonApprovalQuery, GetReasonApprovalQueryVariables>(GetReasonApprovalDocument, baseOptions);
      }
export function useGetReasonApprovalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReasonApprovalQuery, GetReasonApprovalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReasonApprovalQuery, GetReasonApprovalQueryVariables>(GetReasonApprovalDocument, baseOptions);
        }
export type GetReasonApprovalQueryHookResult = ReturnType<typeof useGetReasonApprovalQuery>;
export type GetReasonApprovalLazyQueryHookResult = ReturnType<typeof useGetReasonApprovalLazyQuery>;
export type GetReasonApprovalQueryResult = ApolloReactCommon.QueryResult<GetReasonApprovalQuery, GetReasonApprovalQueryVariables>;
export function refetchGetReasonApprovalQuery(variables?: GetReasonApprovalQueryVariables) {
      return { query: GetReasonApprovalDocument, variables: variables }
    }
export const GetSalesDocument = gql`
    query getSales($options: SaleListOptionsInputType) {
  sales(options: $options) {
    items {
      ...Sale
    }
    count
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSalesQuery__
 *
 * To run a query within a React component, call `useGetSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesQuery, GetSalesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, baseOptions);
      }
export function useGetSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesQuery, GetSalesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, baseOptions);
        }
export type GetSalesQueryHookResult = ReturnType<typeof useGetSalesQuery>;
export type GetSalesLazyQueryHookResult = ReturnType<typeof useGetSalesLazyQuery>;
export type GetSalesQueryResult = ApolloReactCommon.QueryResult<GetSalesQuery, GetSalesQueryVariables>;
export function refetchGetSalesQuery(variables?: GetSalesQueryVariables) {
      return { query: GetSalesDocument, variables: variables }
    }
export const GetSalesUserDocument = gql`
    query getSalesUser($user: String) {
  salesUser(user: $user) {
    items {
      ...Sale
    }
    count
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSalesUserQuery__
 *
 * To run a query within a React component, call `useGetSalesUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesUserQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetSalesUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSalesUserQuery, GetSalesUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSalesUserQuery, GetSalesUserQueryVariables>(GetSalesUserDocument, baseOptions);
      }
export function useGetSalesUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSalesUserQuery, GetSalesUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSalesUserQuery, GetSalesUserQueryVariables>(GetSalesUserDocument, baseOptions);
        }
export type GetSalesUserQueryHookResult = ReturnType<typeof useGetSalesUserQuery>;
export type GetSalesUserLazyQueryHookResult = ReturnType<typeof useGetSalesUserLazyQuery>;
export type GetSalesUserQueryResult = ApolloReactCommon.QueryResult<GetSalesUserQuery, GetSalesUserQueryVariables>;
export function refetchGetSalesUserQuery(variables?: GetSalesUserQueryVariables) {
      return { query: GetSalesUserDocument, variables: variables }
    }
export const GetSaleDocument = gql`
    query getSale($id: UUID) {
  sale(id: $id) {
    ...SaleDetail
  }
}
    ${SaleDetailFragmentDoc}`;

/**
 * __useGetSaleQuery__
 *
 * To run a query within a React component, call `useGetSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSaleQuery, GetSaleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSaleQuery, GetSaleQueryVariables>(GetSaleDocument, baseOptions);
      }
export function useGetSaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSaleQuery, GetSaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSaleQuery, GetSaleQueryVariables>(GetSaleDocument, baseOptions);
        }
export type GetSaleQueryHookResult = ReturnType<typeof useGetSaleQuery>;
export type GetSaleLazyQueryHookResult = ReturnType<typeof useGetSaleLazyQuery>;
export type GetSaleQueryResult = ApolloReactCommon.QueryResult<GetSaleQuery, GetSaleQueryVariables>;
export function refetchGetSaleQuery(variables?: GetSaleQueryVariables) {
      return { query: GetSaleDocument, variables: variables }
    }
export const CreateSaleDocument = gql`
    mutation createSale($input: CreateSaleMutationInput!) {
  createSale(input: $input) {
    sale {
      ...SaleDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type CreateSaleMutationFn = ApolloReactCommon.MutationFunction<CreateSaleMutation, CreateSaleMutationVariables>;

/**
 * __useCreateSaleMutation__
 *
 * To run a mutation, you first call `useCreateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleMutation, { data, loading, error }] = useCreateSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSaleMutation, CreateSaleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(CreateSaleDocument, baseOptions);
      }
export type CreateSaleMutationHookResult = ReturnType<typeof useCreateSaleMutation>;
export type CreateSaleMutationResult = ApolloReactCommon.MutationResult<CreateSaleMutation>;
export type CreateSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSaleMutation, CreateSaleMutationVariables>;
export const UpdateSaleDocument = gql`
    mutation updateSale($input: UpdateSaleMutationInput!) {
  updateSale(input: $input) {
    sale {
      ...SaleDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type UpdateSaleMutationFn = ApolloReactCommon.MutationFunction<UpdateSaleMutation, UpdateSaleMutationVariables>;

/**
 * __useUpdateSaleMutation__
 *
 * To run a mutation, you first call `useUpdateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleMutation, { data, loading, error }] = useUpdateSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSaleMutation, UpdateSaleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSaleMutation, UpdateSaleMutationVariables>(UpdateSaleDocument, baseOptions);
      }
export type UpdateSaleMutationHookResult = ReturnType<typeof useUpdateSaleMutation>;
export type UpdateSaleMutationResult = ApolloReactCommon.MutationResult<UpdateSaleMutation>;
export type UpdateSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSaleMutation, UpdateSaleMutationVariables>;
export const DeleteSaleDocument = gql`
    mutation deleteSale($input: DeleteSaleMutationInput!) {
  deleteSale(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteSaleMutationFn = ApolloReactCommon.MutationFunction<DeleteSaleMutation, DeleteSaleMutationVariables>;

/**
 * __useDeleteSaleMutation__
 *
 * To run a mutation, you first call `useDeleteSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSaleMutation, { data, loading, error }] = useDeleteSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSaleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSaleMutation, DeleteSaleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSaleMutation, DeleteSaleMutationVariables>(DeleteSaleDocument, baseOptions);
      }
export type DeleteSaleMutationHookResult = ReturnType<typeof useDeleteSaleMutation>;
export type DeleteSaleMutationResult = ApolloReactCommon.MutationResult<DeleteSaleMutation>;
export type DeleteSaleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSaleMutation, DeleteSaleMutationVariables>;
export const CreateSalePdfDocument = gql`
    mutation createSalePDF($input: CreateSalePDFMutationInput!) {
  createSalePDF(input: $input) {
    salePDF {
      file
      slug
      sale {
        ...SaleDetail
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type CreateSalePdfMutationFn = ApolloReactCommon.MutationFunction<CreateSalePdfMutation, CreateSalePdfMutationVariables>;

/**
 * __useCreateSalePdfMutation__
 *
 * To run a mutation, you first call `useCreateSalePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalePdfMutation, { data, loading, error }] = useCreateSalePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalePdfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSalePdfMutation, CreateSalePdfMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSalePdfMutation, CreateSalePdfMutationVariables>(CreateSalePdfDocument, baseOptions);
      }
export type CreateSalePdfMutationHookResult = ReturnType<typeof useCreateSalePdfMutation>;
export type CreateSalePdfMutationResult = ApolloReactCommon.MutationResult<CreateSalePdfMutation>;
export type CreateSalePdfMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSalePdfMutation, CreateSalePdfMutationVariables>;
export const GetSeasonsDocument = gql`
    query getSeasons {
  seasons {
    ...Season
  }
}
    ${SeasonFragmentDoc}`;

/**
 * __useGetSeasonsQuery__
 *
 * To run a query within a React component, call `useGetSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSeasonsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
      }
export function useGetSeasonsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
        }
export type GetSeasonsQueryHookResult = ReturnType<typeof useGetSeasonsQuery>;
export type GetSeasonsLazyQueryHookResult = ReturnType<typeof useGetSeasonsLazyQuery>;
export type GetSeasonsQueryResult = ApolloReactCommon.QueryResult<GetSeasonsQuery, GetSeasonsQueryVariables>;
export function refetchGetSeasonsQuery(variables?: GetSeasonsQueryVariables) {
      return { query: GetSeasonsDocument, variables: variables }
    }
export const GetWeekSummaryDocument = gql`
    query getWeekSummary($seller: String) {
  weekSummary(seller: $seller) {
    ...WeekSummaryDetail
  }
}
    ${WeekSummaryDetailFragmentDoc}`;

/**
 * __useGetWeekSummaryQuery__
 *
 * To run a query within a React component, call `useGetWeekSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeekSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeekSummaryQuery({
 *   variables: {
 *      seller: // value for 'seller'
 *   },
 * });
 */
export function useGetWeekSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWeekSummaryQuery, GetWeekSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWeekSummaryQuery, GetWeekSummaryQueryVariables>(GetWeekSummaryDocument, baseOptions);
      }
export function useGetWeekSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWeekSummaryQuery, GetWeekSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWeekSummaryQuery, GetWeekSummaryQueryVariables>(GetWeekSummaryDocument, baseOptions);
        }
export type GetWeekSummaryQueryHookResult = ReturnType<typeof useGetWeekSummaryQuery>;
export type GetWeekSummaryLazyQueryHookResult = ReturnType<typeof useGetWeekSummaryLazyQuery>;
export type GetWeekSummaryQueryResult = ApolloReactCommon.QueryResult<GetWeekSummaryQuery, GetWeekSummaryQueryVariables>;
export function refetchGetWeekSummaryQuery(variables?: GetWeekSummaryQueryVariables) {
      return { query: GetWeekSummaryDocument, variables: variables }
    }